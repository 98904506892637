import { Component, OnInit } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { Product } from '../_&models/Product';
import { ProductSubtype } from '../_&models/ProductSubtype';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mostrar-equip-quente-frio-outros',
  templateUrl: './mostrar-equip-quente-frio-outros.component.html',
  styleUrls: ['./mostrar-equip-quente-frio-outros.component.css']
})
export class MostrarEquipQuenteFrioOutrosComponent implements OnInit {

  constructor(private _prod: ProductService, private route: ActivatedRoute) { }

  list: ProductSubtype[] = [];

  selected:"cold_equipment" | "hot_equipment" | "other_equipment";

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.selected = params['type'];
      this.selectCat(this.selected);
    });
  }

  selectCat(cat: "cold_equipment" | "hot_equipment" | "other_equipment") {
    this.list = [];
    this.selected = cat;
    switch(cat) {
      case "cold_equipment":
        this._prod.getProductsSubTypeCompleteList('cold_equipment', 2).subscribe((data) => {
          data.forEach(element => {
            console.log(element)
            this.list.push(new ProductSubtype(element));
          });
        });
      break;
      case "hot_equipment":
        this._prod.getProductsSubTypeCompleteList('hot_equipment', 2).subscribe((data) => {
          data.forEach(element => {
            this.list.push(new ProductSubtype(element));
          });
        })
      break;
      case  "other_equipment":
        this._prod.getProductsSubTypeCompleteList('other_equipment', 2).subscribe((data) => {
          data.forEach(element => {
            this.list.push(new ProductSubtype(element));
          });
        })
      break;
    }
  }

  translate: any = {
    "cold_equipment": "Frio",
    "hot_equipment": "Quente",
    "other_equipment": "Outros"
  };
}
