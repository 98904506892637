import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  baseUrl: string = "https://"+environment.apiUrl+"/api/orders/";

  getSelfOrdersListPageCount(pageSize: number = 5) {
    return this.http.post(this.baseUrl+"get/self/"+pageSize, {
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    )
  }
  getSelfOrdersListPage(pageSize: number = 5, page: number = 1) {
    return this.http.post(this.baseUrl+"get/self/"+pageSize+"/"+page, {
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    )
  }
  getOrderAddress(billing, shipping) {
    return this.http.post(this.baseUrl+"get/morada", {
      data: localStorage.getItem("userId"),
      model: {
        billing,
        shipping
      }
    }).pipe(
      map((data) => {
        return data;
      })
    )
  }

}
