
<!-- Pseudo Jumbotron -->
<div class="p-5 mt-5 mb-5 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
  <div class="d-flex align-items-center justify-content-center">
    <div class="col-auto col-lg-2 hidden-sm">
      <hr>
    </div>
    <div class="col-12 col-lg-2 d-flex justify-content-center">
      <h5> A minha Conta</h5>
    </div>
    <div class="col-auto col-lg-8 hidden-sm">
      <hr>
    </div>
  </div>
  <div class="pt-3 ps-3 pb-5 border-sm-bottom border-md-bottom border-0">
    <h5>Informações</h5>
    <div class="mt-3" *ngIf="user">
      <p>{{user.name}}</p>
      <p>{{user.email}}</p>
      <p *ngIf="user.telephone != ''">T: {{user.telephone}}</p>
      <p *ngIf="user.cellphone != ''">T: {{user.cellphone}}</p>
      <p>NIF: {{user.nif}}</p>
      <button class="btn btn-outline-lc-secundary px-5 me-3" (click)="openAccountModal(account)">Editar Informações de Conta</button>
      <button class="btn btn-outline-lc-secundary px-5" (click)="openPasswordModal(password)">Alterar Password</button>
    </div>
  </div>
  <!--Moradas-->
  <div class="row pt-10">
    <div class="col-12 pe-5" *ngIf="addrs.length <= 0">
      <h2>Não tem nenhuma morada associada a sua conta</h2>
    </div>
  </div>
  <div class="row pt-10" *ngIf="addrs.length > 0">
    <div class="col-12 col-lg-6 pe-5">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center">
          <h5 class="text-center">Morada de Faturação por Defeito</h5>
        </div>
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
      </div>
      <div class="pt-3 ps-3 pb-5 border-sm-bottom border-md-bottom border-0">
        <div class="mt-3" *ngIf="favBill">
          <p>{{user.name}}</p>
          <p>{{getAddr(favBill).address}}</p>
          <p>{{getAddr(favBill).postal}}, {{getAddr(favBill).locality}}</p>
          <p *ngIf="user.telephone != ''">T: {{user.telephone}}</p>
          <p *ngIf="user.cellphone != ''">T: {{user.cellphone}}</p>
          <button class="btn btn-outline-lc-secundary px-3" (click)="alterDefault(alterDefaultModal,'bill')">Alterar Morada por defeito</button>
          <button class="btn btn-outline-lc-secundary px-5" (click)="openAddrModal(newAddr, favBill)">Editar</button>
        </div>
        <div class="mt-3" *ngIf="!favBill">
          <h3>Não morada de Faturação definida</h3>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 ps-0 pt-md-4 pt-lg-0 ps-lg-5">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center">
          <h5 class="text-center mt-sm-10">Morada de Envio por Defeito</h5>
        </div>
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
      </div>
      <div class="pt-3 ps-3">
        <div class="mt-3" *ngIf="favShip">
          <p>{{user.name}}</p>
          <p>{{getAddr(favShip).address}}</p>
          <p>{{getAddr(favShip).postal}}, {{getAddr(favShip).locality}}</p>
          <p *ngIf="user.telephone != ''">T: {{user.telephone}}</p>
          <p *ngIf="user.cellphone != ''">T: {{user.cellphone}}</p>
          <button class="btn btn-outline-lc-secundary px-3" (click)="alterDefault(alterDefaultModal,'ship')">Alterar Morada por defeito</button>
          <button class="btn btn-outline-lc-secundary px-5" (click)="openAddrModal(newAddr, favShip)">Editar</button>
        </div>
      </div>
      <div class="mt-3" *ngIf="!favShip">
        <h3>Não morada de Faturação definida</h3>
      </div>
    </div>
  </div>
  <div class="row pt-10" *ngIf="addrLeft.length > 0">
    <div class="col-12 col-lg-6 pe-5">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center">
          <h5 class="text-center">Moradas Guardadas</h5>
        </div>
        <div class="col-auto col-lg-3 hidden-sm">
          <hr>
        </div>
      </div>
      <div class="pt-3 ps-3 pb-5 border-sm-bottom border-md-bottom border-0" *ngFor="let item of addrLeft">
        <div class="mt-3">
          <p>{{user.name ? user.name : ""}}</p>
          <p>{{item.address}}</p>
          <p>{{item.postal}}, {{item.locality}}</p>
          <p *ngIf="user.telephone != ''">T: {{user.telephone}}</p>
          <p *ngIf="user.cellphone != ''">T: {{user.cellphone}}</p>
          <p *ngIf="favBill && item.id == favBill">Morada de Faturação por Defeito</p>
          <p *ngIf="favShip && item.id == favShip">Morada de Envio por Defeito</p>
          <button class="btn btn-outline-lc-secundary px-5" (click)="openAddrModal(newAddr, item.id)">Editar</button>
          <button class="btn btn-outline-danger px-5" (click)="deleteAddr(item.id)">Eliminar</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 ps-0 pt-md-4 pt-lg-0 ps-lg-5">
      <div class="d-flex align-items-center justify-content-between">
        <div class="col-auto col-lg-5 hidden-sm">
          <hr>
        </div>
        <div class="col-auto col-lg-5 hidden-sm">
          <hr>
        </div>
      </div>
      <div class="pt-3 ps-3 pb-5 border-sm-bottom border-md-bottom border-0" *ngFor="let item of addrRight">
        <div class="mt-3">
          <p>{{user.name}}</p>
          <p>{{item.address}}</p>
          <p>{{item.postal}}, {{item.locality}}</p>
          <p *ngIf="user.telephone != ''">T: {{user.telephone}}</p>
          <p *ngIf="user.cellphone != ''">T: {{user.cellphone}}</p>
          <p *ngIf="favBill && item.id == favBill">Morada de Faturação por Defeito</p>
          <p *ngIf="favShip && item.id == favShip">Morada de Envio por Defeito</p>
          <button class="btn btn-outline-lc-secundary px-5" (click)="openAddrModal(newAddr, item.id)">Editar</button>
          <button class="btn btn-outline-danger px-5" (click)="deleteAddr(item.id)">Eliminar</button>
        </div>
      </div>
      <div class="mt-3" *ngIf="!favShip">
        <h3>Não morada de Faturação definida</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6 ps-3 pt-md-4 pt-lg-0 ps-lg-4">
      <div class="mt-3">
        <button class="btn btn-outline-lc-secundary px-4" (click)="openAddrModal(newAddr)">Adicionar nova Morada</button>
      </div>
    </div>
  </div>
</div>

<ng-template #account let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="accountModalLabel">Alteração de Informações</h5>
    <button type="button" class="btn-close" (click)="closeAccountModal()"></button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-6">
        <label class="form-label">Nome</label>
        <input type="text" class="form-control" [class.border-danger]="accError.name" [(ngModel)]="user.name">
      </div>
      <div class="col-md-6">
        <label class="form-label">NIF</label>
        <input type="text" class="form-control" [class.border-danger]="accError.nif" [(ngModel)]="user.nif">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <label class="form-label">Telefone</label>
        <input type="text" class="form-control" [class.border-danger]="accError.telephone" placeholder="911 111 111" [inputMask]="phoneMask" [(ngModel)]="user.telephone">
      </div>
      <div class="col-md-6">
        <label class="form-label">Telemovel</label>
        <input type="text" class="form-control" [class.border-danger]="accError.cellphone" placeholder="911 111 111" [inputMask]="phoneMask" [(ngModel)]="user.cellphone">
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">Email</label>
      <input type="email" class="form-control" [class.border-danger]="accError.email" [(ngModel)]="user.email">
    </div>
  </div>
  <div class="modal-footer">
    <span class="text-danger" *ngIf="accError.user || accError.email || accError.nif || accError.telephone || accError.cellphone">O formulario contem erros</span>
    <button type="button" class="btn btn-secondary" (click)="closeAccountModal()">Cancelar</button>
    <button type="button" class="btn btn-outline-lc-secundary" (click)="updateUserData()">Guardar</button>
  </div>
</ng-template>

<ng-template #password let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="passwordModalLabel">Alteração de Password</h5>
    <button type="button" class="btn-close" (click)="closePasswordModal()"></button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <label class="form-label">Password Atual</label>
      <div class="input-group mb-3">
        <input [type]="showPass.current ? 'text' : 'password'" class="form-control" [(ngModel)]="pass.current">
        <button class="btn btn-outline-lc-secundary duration-500" (click)="switchState('current')"><i class="fal fa-eye"></i></button>
      </div>
    </div>
    <div>
      <label class="form-label">Nova Password</label>
      <div class="input-group mb-3">
        <input [type]="showPass.new ? 'text' : 'password'" class="form-control" [class.border-danger]="passError" [(ngModel)]="pass.new">
        <button class="btn btn-outline-lc-secundary duration-500" (click)="switchState('new')"><i class="fal fa-eye"></i></button>
      </div>
    </div>
    <div>
      <label class="form-label">Confirme a Nova Password</label>
      <div class="input-group mb-3">
        <input [type]="showPass.confirm ? 'text' : 'password'" class="form-control" [class.border-danger]="passError" [(ngModel)]="pass.confirm">
        <button class="btn btn-outline-lc-secundary duration-500" (click)="switchState('confirm')"><i class="fal fa-eye"></i></button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <span class="text-danger" *ngIf="passError">As passwords não são iguais</span>
    <button type="button" class="btn btn-secondary" (click)="closePasswordModal()">Cancelar</button>
    <button type="button" class="btn btn-outline-lc-secundary" (click)="updatePassData()">Guardar</button>
  </div>
</ng-template>

<ng-template #newAddr let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Nova Morada</h5>
    <button type="button" class="btn-close" (click)="closeAddrModal()"></button>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <label class="form-label">Morada</label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Praça Marechal Humberto Delgado" [(ngModel)]="addAddr.address">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8 col-xl-3">
        <label class="form-label">Código Postal</label>
        <div class="input-group mb-3">
          <input type="number" class="form-control" placeholder="1549" [(ngModel)]="codFirst" (change)="updateCodPos()">
          <span class="input-group-text justify-content-center"><i class="fal fa-minus w-50"></i></span>
          <input type="number" class="form-control" placeholder="004" [(ngModel)]="codSecond"(change)="updateCodPos()">
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label class="form-label">Localidade</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Lisboa" [(ngModel)]="addAddr.locality">
        </div>
      </div>
      <div class="col-12 col-xl-4">
        <label class="form-label">Pais</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Lisboa" [(ngModel)]="addAddr.pais">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeAddrModal()">Cancelar</button>
    <button type="button" class="btn btn-outline-lc-secundary" (click)="updateAddr()">Guardar</button>
  </div>
</ng-template>

<ng-template #alterDefaultModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{titleAlterDefault}}</h5>
    <button type="button" class="btn-close" (click)="closeAlterDefault()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label class="form-label"></label>
        <div class="mt-3 input-group">
          <select class="form-select" [(ngModel)]="choice">
            <option selected disabled>Escolha uma das moradas</option>
            <ng-container *ngFor="let item of addrs">
              <option [ngValue]="item.id">{{item.address}}, {{item.postal}}, {{item.locality}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeAlterDefault()">Cancelar</button>
    <button type="button" class="btn btn-outline-lc-secundary" (click)="updateAlter()">Guardar</button>
  </div>
</ng-template>