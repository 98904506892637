import { Component, OnInit } from '@angular/core';
import { EmailService } from '../_&services/email.service';
import { ErrorService } from '../_&services/Error.service';
import { VerifyEmailPipe } from '../_&pipe/verify.pipe';

@Component({
  selector: 'app-recuperacao',
  templateUrl: './recuperacao.component.html',
  styleUrls: ['./recuperacao.component.less']
})
export class RecuperacaoComponent implements OnInit {

  constructor(private _recovery: EmailService) { }

  ngOnInit() {
  }

  email: string = "";
  error: boolean = false;

  sendRecoveryRequest() {
    if(!(this.error = !VerifyEmailPipe.transform(this.email))) {
      this._recovery.askForRecovery(this.email).subscribe(() => {
        location.href = "/";
      }, (err) => {
        ErrorService.signalError.emit({
          title: err.error,
          showBody: false
        });
      });
    }
  }

}
