<section class="container-fluid back-login gx-0">
  <div class="row m-0 align-middle justify-content-center align-items-center pt-80 pt-sm-44 opacity-90">
    <!-- First -->
    <div class="col-12 col-md-7 col-lg-6 col-xl-4 col-xxl-4">
      <div class="card rounded shadow">
        <div class="p-5">
          <div class="d-flex align-items-center justify-content-center mb-4 -mt-4">
            <div class="col-auto col-lg-1 hidden-sm">
              <hr>
            </div>
            <div class="col d-flex justify-content-center">
              <h5 class="fst-italic">Recupere a Palavra Passe</h5>
            </div>
            <div class="col hidden-sm">
              <hr>
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email</label>
            <input type="email" class="form-control" [class.border-danger]="error" [(ngModel)]="email" required (keyup.enter)="sendRecoveryRequest()">
            <div class="invalid-feedback" [class.d-block]="error">
              O email não é valido
            </div>
          </div>
          <div class="row col-12">
            <div class="col-12 col-md-3 d-flex align-items-end">
              <button type="button" class="btn btn-outline-lc-secundary duration-500" (click)="sendRecoveryRequest()">Enviar</button>
            </div>
            <div class="col-12 col-md-9 col-xxl-8">
              <small class="text-body-secondary">Introduza o email para o qual pretende fazer a recuperação da palavra passe, caso este se encontre registado na nossa base de dados receberá um email com as instruções para a recuperação da Palavra Passe</small>
            </div>
            <div class="col-12 mt-3 text-end">
              <a href="/">Ir para a pagina inicial</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>