<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <!-- Descritivo -->
        <section>
          <div class="card rounded-0 border-0">
            <div class="row g-0">
              <div class="col-md-6">
                <div class="carousel">
                  <ng-container *ngFor="let file of media; let i = index;">
                    <input type="radio" name="slides" [checked]="i == 0" id="prod-{{file.name}}">
                  </ng-container>
                  <ul class="carousel__slides pt-4">
                    <li class="carousel__slide" *ngFor="let file of media">
                      <figure>
                        <div>
                          <img src="{{file.file}}" alt="prod-{{file.name}}">
                        </div>
                      </figure>
                    </li>
                  </ul>
                  <ul class="carousel__thumbnails justify-content-center">
                    <li *ngFor="let file of media">
                      <label for="prod-{{file.name}}"><img src="{{file.file}}" alt="prod-{{file.name}}" class="object-contain"></label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card-body" *ngIf="product != undefined">
                  <h5 class="card-title" >{{getValue('brand')}} {{getValue('series')}}</h5>
                  <div class="mb-10">
                    <p class="card-text">{{product.description}}</p>
                    <h5 class="card-title">{{getValue('model')}}</h5>
                    <p class="card-text" *ngIf="getValue('modelDesc') != ''">{{getValue('modelDesc')}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3" *ngIf="propertiesMiddleSplit.length > 0">
              <!-- tabs -->
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <div class="nav-link active" id="nav-espec-tab" data-bs-toggle="tab" data-bs-target="#nav-espec" role="tab" aria-controls="nav-espec" aria-selected="true">Especificações</div>
                </div>
              </nav>
              <div class="tab-content mt-3" id="nav-tabContent">
                <div class="tab-pane show active fade" id="nav-espec" role="tabpanel" aria-labelledby="nav-espec-tab">
                  <div class="row">
                    <div class="col-12 pb-3">
                      <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row" *ngFor="let item of propertiesMiddleSplit">
                        <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                          <div>{{item[0] ? item[0].description : ''}}</div>
                          <div>{{item[0] ? item[0].value : ''}}</div>
                        </li>
                        <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                          <div>{{item[1] ? item[1].description : ''}}</div>
                          <div>{{item[1] ? item[1].value : ''}}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</main>