import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NIF'
})
export class VerifyNIFPipe implements PipeTransform {

  static transform(input: string): boolean {
    var nif: number=parseInt(input.replace(/ /gm,""));
    if(Number.isNaN(nif))
      return false;
    var nif_a = [];
    var n;
    var resto;
    var dig_ctrl;
    var verify;
    var result = 0;
    var tmp = nif;
    for (n = 9; n>=1; n--) {
        nif_a[n]=nif%10;
        nif = Math.floor(nif/10);
    }
    nif = tmp;
    verify = nif_a[9];
    if((nif<=999999999||nif>99999999)&&
      ((nif_a[1]==1||nif_a[1]==2||nif_a[1]==3)||
      (nif_a[1]==4&&nif_a[2]==5)||(nif_a[1]==5)||
      (nif_a[1]==6)||(nif_a[1]==7&&nif_a[2]==0)||
      (nif_a[1]==7&&nif_a[2]==4)||(nif_a[1]==7&&nif_a[2]==5)||
      (nif_a[1]==7&&nif_a[2]==1)||(nif_a[1]==7&&nif_a[2]==2)||
      (nif_a[1]==7&&nif_a[2]==7)||(nif_a[1]==7&&nif_a[2]==8)||
      (nif_a[1]==7&&nif_a[2]==9)||(nif_a[1]==8)||
      (nif_a[1]==9&&nif_a[2]==0)||(nif_a[1]==9&&nif_a[2]==1)||
      (nif_a[1]==9&&nif_a[2]==8)||(nif_a[1]==9&&nif_a[2]==9))){
      for(n=9; n>=2; n--) {
          result+=nif_a[10-n]*n;
      }
      resto = result%11;
      if(resto<2) {
        dig_ctrl = 0;
      }else{
        dig_ctrl = 11-resto;
      }
      if(dig_ctrl==verify) {
        return true;
      }
    }
    return false;
  }

  transform(value: string): boolean {
    return VerifyNIFPipe.transform(value);
  }

}

@Pipe({
  name: 'Email'
})
export class VerifyEmailPipe implements PipeTransform {

  static transform(email: string): boolean {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  transform(value: string): boolean {
    return VerifyEmailPipe.transform(value);
  }
}
