import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() size: number = 9;
  @Input() pages: number = 1;
  @Input() current: number = 1;
  @Input() color: string = "fuchsia-blue";

  half: number = Math.floor(this.size / 2) + (this.size % 2 != 0 ? 1 : 0);

  @Output() sizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pagesChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() currentChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.size) {
      this.size = changes.size.currentValue;
    }
    if(changes?.pages) {
      this.pages = changes.pages.currentValue;
    }
    if(changes?.current) {
      this.current = changes.current.currentValue;
    }
    var size = this.size > this.pages ? this.pages : this.size;
    this.half = Math.floor(size / 2) + (size % 2 != 0 ? 1 : 0);
    this.setPag(this.current > this.pages ? this.pages : this.current);
  }

  setPag(goTo: number) {
    this.current = goTo;
    setTimeout(() => {
      this.currentChange.emit(this.current);
    }, 15);
  }

}
