<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/politicas/Politica_de_Privacidade.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/politicas/Politica_de_Privacidade.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Política de Privacidade</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">Importante</h5>
              <p>A LUIS CARRILHO UNIPESSOAL, Lda recomenda a leitura atenta da presente Política de Privacidade, a qual fornece informações importantes sobre a forma como a LUIS CARRILHO UNIPESSOAL, Lda, usa os Dados Pessoais recolhidos. A Presente Política de Privacidade pode ser periodicamente alterada, por decisão unilateral da LUIS CARRILHO UNIPESSOAL, Lda, pelo que se recomenda a sua consulta regular.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Quem somos</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda, é uma sociedade comercial por quotas com sede social na Rua Pedro de Santarém, nº 33, 4º Direito - 2000-223 Santarém, registada na Conservatória do Registo Comercial de Santarém sob o número único de matrícula e de pessoa coletiva 514267852, com o capital social de 2.500,00 Euros, de ora em diante abreviadamente designada por “LUIS CARRILHO UNIPESSOAL, Lda”.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Website</h5>
                <p>O Website da LUIS CARRILHO UNIPESSOAL, Lda é um sítio web que disponibiliza informação sobre a atividade da LUIS CARRILHO UNIPESSOAL, Lda, acessível no domínio <a href="www.koffeum.pt">www.koffeum.pt</a>, propriedade da LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <p>O Website “Koffeum” é um sítio na Internet por meio do qual a LUIS CARRILHO UNIPESSOAL, Lda procede à venda de Café e de Equipamentos de Quente, Frio e Mobiliário e que disponibiliza informação sobre a sua atividade.</p>
                <p>Através do Website, o Utilizador poderá analisar os produtos, equipamentos e/ou serviços aí disponibilizados para venda pela LUIS CARRILHO UNIPESSOAL, Lda (os “Produtos”), adquirindo-os mediante a celebração de contrato de compra e venda.</p>
                <p>O Website contém textos, fotografias, gráficos, logotipos, imagens, obras sujeitas a direitos de autor e outros tipos de informação (o "Conteúdo").</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Utilizadores</h5>
                <p>Considera-se “Utilizador” todo o indivíduo ou instituição que, em geral, através do Website, visite o conteúdo no mesmo disponibilizado.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Menores</h5>
                <p>O Website não se destina a ser utilizado por menores de 18 (dezoito) anos, pelo que não são recolhidos, de forma intencional, Dados Pessoais de menores. Caso suspeitemos ou tenhamos conhecimento da utilização do Website por um menor, envidaremos os esforços razoáveis e exigíveis para eliminar os Dados Pessoais respeitantes ao mesmo que tenhamos recolhido na convicção legítima de interagir com um adulto, não podendo a LUIS CARRILHO UNIPESSOAL, Lda ser considerada responsável pela utilização desses dados que possa, entretanto, ter ocorrido.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">Declaração de privacidade</h5>
              </div>
              <div class="pt-3 mb-3">
                <h5>Aplicação</h5>
                <p>Esta Política de Privacidade aplica-se ao Website e estabelece qual é a política da LUIS CARRILHO UNIPESSOAL, Lda no que respeita à recolha e tratamento de dados pessoais de pessoas singulares (“Dados Pessoais”).</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda está consciente da sua responsabilidade na recolha e tratamento, com a diligência devida, dos Dados Pessoais que lhe são confiados e de os manter seguros, assegurando a total privacidade, confidencialidade e integridade dos mesmos, tudo no escrupuloso cumprimento da lei.</p>
                <p>Também aqui se explicam os direitos que assistem aos Titulares dos Dados Pessoais ao abrigo </p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados (RGPD), </li>
                  <li class="list-group-item border-0 bg-transparent">(II) de qualquer legislação portuguesa que diretamente regulamente, execute e aplique o RGPD e outros regulamentos e/ou diretivas relativas a proteção de dados e privacidade, e </li>
                  <li class="list-group-item border-0 bg-transparent">(III) de qualquer interpretação judicial ou administrativa, recomendação, regulação, código de conduta, código de atuação, cláusulas contratuais-tipo ou mecanismo de certificação aprovado e publicado por uma autoridade de controlo, conforme seja aplicável, se encontre em vigor e de acordo com eventuais posteriores alterações e/ou substituições</li>
                </ul>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">Dados Pessoais</h5>
              </div>
              <div class="pt-3 mb-3">
                <h5>O Que Nós Recolhemos</h5>
                <p>Nós podemos recolher as seguintes informações:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">• Dados de identificação e contacto: Nome, número do documento de identificação, NIF, idade, data de nascimento, contacto telefónico, email, morada de faturação e morada de envio, dados de pagamento, identificadores eletrónicos (credenciais de login digital), etc.;</li>
                  <li class="list-group-item border-0 bg-transparent">• Relação com a LUIS CARRILHO UNIPESSOAL, Lda: Histórico de produtos e serviços que pesquisou, comprou ou subscreveu;</li>
                  <li class="list-group-item border-0 bg-transparent">• Perfil: Gostos, interesses e preferências, na sua relação com a LUIS CARRILHO UNIPESSOAL, Lda.</li>
                </ul>
              </div>
              <div class="pt-3 mb-3">
                <h5>Informação Técnica</h5>
                <p>É feita a recolha de “Informação Técnica” dos Utilizadores do Website, nomeada, mas não exclusivamente, a seguinte: o endereço de IP (Internet Protocol), o navegador de Internet (browser), o sistema operativo ou a data e a hora dos acessos às várias subpáginas do Website. O Website irá relacionar esta Informação Técnica com a restante informação recolhida.</p>
                <p>A Informação Técnica recolhida destina-se a implementar melhorias no Website que permitam o desenvolvimento de novas funcionalidades, a integração de funcionalidades de outras entidades externas à LUIS CARRILHO UNIPESSOAL, Lda e/ou melhorar a experiência de utilização. Se necessário, para este efeito, a Informação Técnica poderá ser cruzada com os Dados Pessoais.</p>
                <p>A Informação Técnica recolhida poderá ser ainda utilizada para ações de marketing, divulgação e / ou publicidade da LUIS CARRILHO UNIPESSOAL, Lda e do Website, podendo os Utilizadores ser contactados, neste âmbito, pela LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <p>Para mais informações, sugere-se a leitura atenta da “Política de Cookies” do website em: <a href="www.koffeum.pt/politica#cookies">www.koffeum.pt/politica-de-cookies</a></p>
              </div>
              <div class="pt-3 mb-3">
                <h5>O que Fazemos com a Informação que Recolhemos</h5>
                <p>Precisamos destas informações para entender as suas necessidades e fornecer-lhe um melhor serviço e, em particular, pelas seguintes razões:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">• Celebração de contratos para aquisição de Produtos e gestão da relação comercial;</li>
                  <li class="list-group-item border-0 bg-transparent">• Manutenção de registo interno;</li>
                  <li class="list-group-item border-0 bg-transparent">• Usar as informações para melhorar os nossos produtos e/ou serviços, quer seja para análise interna ou fornecimento dos dados a empresas externas que se dedicam à coleta de opiniões de compras efetuadas;</li>
                  <li class="list-group-item border-0 bg-transparent">• Podemos enviar periodicamente e-mails promocionais sobre novos produtos, ofertas especiais ou outras informações que nós pensamos que você pode achar interessante, usando o endereço de e-mail que você forneceu;</li>
                  <li class="list-group-item border-0 bg-transparent">• De tempos a tempos, também poderemos usar as suas informações para contactá-lo para fins de pesquisa de mercado. Podemos contactá-lo por e-mail, telefone, fax ou correio. Podemos usar as informações para personalizar o site de acordo com os seus interesses;</li>
                  <li class="list-group-item border-0 bg-transparent">• Podemos utilizar os Dados Pessoais para cumprimento das obrigações legais e regulamentares aplicáveis à atividade da LUIS CARRILHO UNIPESSOAL, Lda, bem como para cumprimentos da normativa legal em matéria de proteção de dados pessoais;</li>
                  <li class="list-group-item border-0 bg-transparent">• Utilizaremos ainda os Dados Pessoais no legítimo interesse da LUIS CARRILHO UNIPESSOAL, Lda, para que esta possa prestar um melhor serviço aos seus clientes, melhorando a forma como desenvolve a sua atividade, a qualidade dos seus serviços, bem como para realizar estatísticas, inquéritos ou estudos de mercado e para comunicação de novidades, oportunidades ou campanhas aos nossos clientes. Para os referidos efeitos, a LUIS CARRILHO UNIPESSOAL, Lda poderá utilizar a informação sobre as vendas de Produtos e outras vicissitudes (devoluções, reparações ou outras), de forma anónima e sem nenhuma característica que possa identificar o Titular dos Dados.</li>
                  <p>O interesse legítimo da LUIS CARRILHO UNIPESSOAL, Lda respeita os direitos e liberdades fundamentais do Titular dos Dados, incluindo a proteção dos seus Dados Pessoais, a sua honra e a sua intimidade pessoal e familiar.</p>
                </ul>
              </div>
              <div class="pt-3 mb-3">
                <h5>Com que base tratamos os seus dados pessoais?</h5>
                <p>Estas são as circunstâncias que nos permitem tratar os seus dados pessoais:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">• - O seu consentimento expresso: seja por escrito, ou oralmente, por exemplo, para efeitos de marketing direto, para que receba emails ou mensagens SMS, por parte da LUIS CARRILHO UNIPESSOAL, Lda;</li>
                  <li class="list-group-item border-0 bg-transparent">• - A execução de um contrato: que celebrou com a LUIS CARRILHO UNIPESSOAL, Lda, como por exemplo, um contrato de compra e venda de produtos ou serviços adquiridos à LUIS CARRILHO UNIPESSOAL, Lda;</li>
                  <li class="list-group-item border-0 bg-transparent">• - O cumprimento de uma obrigação legal;</li>
                  <li class="list-group-item border-0 bg-transparent">• - O interesse legítimo da LUIS CARRILHO UNIPESSOAL, Lda: por exemplo, para efeitos de melhoria da qualidade do serviço prestado.</li>
                </ul>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda compromete-se a tratar os seus dados pessoais de forma a garantir a confidencialidade, a segurança e a proteção desses dados contra o seu tratamento não autorizado ou ilícito e contra a sua perda, destruição ou dano.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Categorias de destinatários dos Dados Pessoais</h5>
                <p>Por regra não transmitimos os Dados Pessoais dos Utilizadores a quaisquer terceiros. Quando transmitimos dados a entidades terceiras fazemo-lo no âmbito do desenvolvimento da nossa atividade e tendo em vista o cumprimento das nossas obrigações legais, contratuais ou a prossecução dos nossos legítimos interesses (por exemplo, comunicação de endereço e dados de contacto empresas transportadoras para efeitos de entrega de encomendas). </p>
                <p>Em caso de transmissão de dados limitamos essa transmissão ao estritamente necessário e procuramos, sempre que possível, anonimizar os Dados Pessoais. Além disso, a LUIS CARRILHO UNIPESSOAL, Lda compromete-se a procurar parcerias e a divulgar os Dados Pessoais, para efeitos da prestação dos seus serviços, apenas a entidades que cumpram todas as exigências legais e de boas práticas no que toca à segurança dos mesmos, sejam certificadas por organismos idóneos dentro a sua área de atuação, demonstrem possuir os níveis de segurança recomendados para a receção, processamento e armazenamento de Dados Pessoais e assegurem um nível adequado de proteção dos Dados Pessoais.</p>
                <p>No entanto, a LUIS CARRILHO UNIPESSOAL, Lda não tem forma de, tecnicamente, validar que todas as recomendações de segurança são adotadas durante todo o tempo, não podendo ser considerada responsável por eventuais incidentes de segurança ou violação de dados imputáveis a terceiros.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda colaborará sempre com as autoridades competentes fornecendo-lhe todas as informações a que seja legalmente obrigada, incluindo, se aplicável, informações dos seus Utilizadores ou fornecidas por estes, na sua totalidade ou parcialmente, não podendo ser responsabilizada nem por tal divulgação nem pelo uso dos mesmos pelas autoridades competentes. Se for tecnicamente possível, a transferência destas informações será efetuada de forma encriptada. A LUIS CARRILHO UNIPESSOAL, Lda procurará informar os Utilizadores afetados por tais medidas, exceto se estiver inibida legalmente de o fazer.</p>
                <p>Os Utilizadores consentem expressamente na partilha dos Dados Pessoais com as entidades acima referidas.</p>
                <p>A divulgação de estatísticas de utilização do Website, nomeadamente, mas não exclusivamente para Fins de Marketing ou publicação de estudos de caso, será sempre feita através da anonimização da informação recolhida.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda não comercializa, por qualquer forma, designadamente através da venda direta ou da troca de contrapartidas, os Dados Pessoais, incluindo Dados de Pagamento, que recolher através do Website.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Direitos dos Titulares dos Dados</h5>
                <p>Os Utilizadores, na qualidade de Titular dos Dados, têm o direito de impedir, em qualquer momento, que os seus dados pessoais sejam tratados pela LUIS CARRILHO UNIPESSOAL, Lda, sob qualquer forma, para efeitos de comunicações de marketing. Este direito pode ser exercido remetendo um correio eletrónico, dirigido à LUIS CARRILHO UNIPESSOAL, Lda, através do formulário de contacto existente em <a href="http://www.koffeum.pt">koffeum.pt</a>.</p>
                <p>Os Utilizadores poderão também exercer os seus direitos, enquanto Titulares de Dados, de acesso, retificação, apagamento, oposição, limitação do tratamento e portabilidade dos seus Dados Pessoais, utilizando os seguintes meios: </p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) dirigindo uma comunicação escrita, para a seguinte morada: Rua Pedro de Santarém, nº 33, 4º Direito - 2000-223 Santarém, </li>
                  <li class="list-group-item border-0 bg-transparent">(II) por e-mail, através do formulário de contacto existente em <a href="http://www.koffeum.pt">koffeum.pt</a>.</li>
                </ul>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda agradece ser contactada imediatamente caso um Utilizador tenha uma reclamação ou alguma questão relativamente à forma como a LUIS CARRILHO UNIPESSOAL, Lda usa e trata os seus Dados Pessoais. A LUIS CARRILHO UNIPESSOAL, Lda envidará todos os esforços para resolver a situação tão breve quanto possível.</p>
                <p>O Utilizador tem, também, o direito de apresentar a qualquer momento, uma reclamação relativa ao tratamento dos seus Dados Pessoais, à Comissão Nacional da Proteção de Dados através do respetivo sítio web <a href="http://www.cnpd.pt" target="_blank" rel="noopener noreferrer">www.cnpd.pt</a>.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Período de Conservação dos Dados Pessoais</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda conservará os Dados Pessoais apenas durante o prazo necessário para a prestação dos seus serviços e/ou cumprimento das suas obrigações legais e/ou contratuais e/ou enquanto vigorar o consentimento prestado pelo Titular dos Dados.</p>
                <p>Após a completa execução dos seus Serviços, a LUIS CARRILHO UNIPESSOAL, Lda apenas conservará os Dados Pessoais que forem necessários, com razoabilidade, para as finalidades anteriormente indicadas e enquanto o forem.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda poderá conservar os Dados Pessoais por prazos mais longos, designadamente, quando estiver obrigada a fazê-lo de acordo com obrigações legais, regulamentares, fiscais ou contabilísticas, bem como no âmbito de processos de queixa ou reclamação ou de litígio relativo a Dados Pessoais.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Responsável pela Privacidade e Proteção de Dados Pessoais</h5>
                <p>O principal ponto de contacto da LUIS CARRILHO UNIPESSOAL, Lda para tratamento de quaisquer questões relacionadas com a proteção de Dados Pessoais é o Responsável pela Privacidade e Proteção de Dados Pessoais, o qual pode ser contactado através do formulário de contacto existente em <a href="http://www.koffeum.pt">koffeum.pt</a>.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Medidas de Segurança</h5>
                <p>Os Dados Pessoais são armazenados em servidores de alta segurança, em provedores de alojamento que cumprem as mais rigorosas exigências internacionais. As bases de dados em que os mesmos são armazenados estão encriptadas. Os serviços de alojamento com os quais a LUIS CARRILHO UNIPESSOAL, Lda se articule têm de garantir que cumprem as mais rigorosas exigências em termos de segurança, não só no que toca ao acesso através da Internet, mas também do ponto de vista do acesso físico, no que toca aos servidores e às próprias instalações onde os mesmos estão instalados.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Alterações da Política de Privacidade</h5>
                <p>Se a presente Política de Privacidade for alterada os Utilizadores serão notificados através de mensagem de correio eletrónico trinta dias antes da data em que a nova redação vier a ser aplicada.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5>Resolução de Conflitos</h5>
                <p>Caso tenha uma queixa, sugestão ou pedido de esclarecimento sobre a política de privacidade do Website, deve escrever-nos para Rua Pedro de Santarém, nº 33, 4º Direito - 2000-223 Santarém ou através do formulário de contacto existente em <a href="http://www.koffeum.pt">koffeum.pt</a>.</p>
                <p>Procuraremos responder-lhe com a maior brevidade possível, propondo-lhe uma solução que vá de encontro à questão apresentada.</p>
                <p>O Utilizador tem o direito de recorrer aos tribunais judiciais Portugueses competentes para dirimir quaisquer questões relativas ao tratamento dos seus Dados Pessoais pela LUIS CARRILHO UNIPESSOAL, Lda.</p>
              </div>
              <div class="d-flex align-items-center justify-content-center py-4" id="cookies">
                <div class="col-4  hidden-sm">
                  <hr>
                </div>
                <div class="col-auto mx-5 d-flex justify-content-center">
                  <h5>Política de utilização de Cookies</h5>
                </div>
                <div class="col-4 hidden-sm">
                  <hr>
                </div>
              </div>
              <div>
                <h5 class="mb-3">O que são os Cookies?</h5>
                <p>Um cookie é um pequeno arquivo que pede permissão para ser colocado no armazenamento do seu computador ou dispositivo móvel. Uma vez concordando, o arquivo é adicionado e o cookie ajuda a analisar o tráfego web ou deixa-o saber quando você visita um determinado site.</p>
              </div>
              <div>
                <h5 class="mb-3">Que Cookies Utilizamos?</h5>
                <p>Nós usamos os logs de tráfego dos cookies para identificar quais as páginas que estão a ser usadas. Isso ajuda-nos a analisar os dados sobre o tráfego na página web e melhorar o nosso site a fim de adequá-lo às necessidades do cliente. Nós só usamos estas informações para fins de análise estatística e, em seguida, os dados são removidos do sistema.</p>
                <p>No geral, os cookies ajuda-nos a fornecer-lhe um site melhor, pois permite-nos monitorizar as páginas que você acha úteis ou não úteis. Um cookie não nos dá acesso ao seu computador ou qualquer informação sobre você, com exceção dos dados que você escolher para partilhar connosco. Você pode optar por aceitar ou recusar cookies. A maioria dos browsers automaticamente aceita cookies, mas você pode modificar o seu navegador para recusar cookies. Se você preferir recusar cookies, isso pode impedi-lo de tirar pleno partido do site.</p>
              </div>
              <div>
                <h5 class="mb-3">Como o Utilizador pode controlar os cookies que a Koffeum utiliza no seu website?</h5>
                <p>Nós usamos os logs de tráfego dos cookies para identificar quais as páginas que estão a ser usadas. Isso ajuda-nos a analisar os dados sobre o tráfego na página web e melhorar o nosso site a fim de adequá-lo às necessidades do cliente. Nós só usamos estas informações para fins de análise estatística e, em seguida, os dados são removidos do sistema.</p>
                <p>Quando acede ao website da LUIS CARRILHO UNIPESSOAL, Lda, o Utilizador pode selecionar uma das opções incluídas na “janela” sobre cookies, aceitando ou rejeitando a utilização de cookies.</p>
                <p>Contudo, os cookies de desempenho necessários ao correto funcionamento do Website serão utilizados independentemente do consentimento do Utilizador.</p>
                <p>O Utilizador pode bloquear ou rejeitar os cookies ativando a definição do navegador de internet (browser) que lhe permita recusar a instalação de todos ou de alguns cookies.</p>
                <p>O Utilizador pode, ainda, alterar as configurações do navegador de internet (browser) para notificá-lo quando um cookie está prestes a ser colocado no seu computador ou dispositivo móvel.</p>
                <p>Normalmente, estas definições são ativadas clicando no menu Ajuda do navegador de internet (browser). No entanto, a rejeição de cookies ao visitar o website da LUIS CARRILHO UNIPESSOAL, Lda, pode comprometer a eficiente funcionalidade de certas áreas do website. Por exemplo, ao rejeitar os cookies do website, o Utilizador poderá não ser capaz de aceder a uma área preferida, visualizar uma página inicial padrão ou um formulário pré-preenchido ou de forma fácil aceder aos seus links ou páginas visitadas recentemente.</p>
                <p>Para obter mais informações sobre os cookies, incluindo a maneira de encontrar os cookies instalados no seu computador ou dispositivo móvel e como administrar e eliminar os mesmos, o Utilizador poderá aceder ao website <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>