export class Utilizadores {

    constructor(item: Utilizadores|void) {
        if(item instanceof Object) {
            this.id = item.id;
            this.name = item.name;
            this.email = item.email;
            this.nif = item.nif;
            this.telephone = item.telephone;
            this.cellphone = item.cellphone;
            this.permissions = item.permissions;
            this.active = item.active;
            this.defaultBillingAddress = item.defaultBillingAddress;
            this.defaultShippingAddress = item.defaultShippingAddress;
        }
    }

    id: number = -1;
    name: string = "";
    email: string = "";
    nif: string = "";
    telephone: string = "";
    cellphone: string = "";
    permissions: number = -1;
    defaultBillingAddress: number|null = null;
    defaultShippingAddress: number|null = null;
    active: boolean = true;
}
