import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyEmailPipe } from '../_&pipe/verify.pipe';
import { ErrorService } from '../_&services/Error.service';
import { AuthService } from '../_&services/auth.service';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private auth: AuthService, private modal: NgbModal) { }

  ngOnInit() {
  }

  email: string = "";
  password: string = "";

  verifyEmail: VerifyEmailPipe = new VerifyEmailPipe();

  register() {
    this.route.queryParams.subscribe((params: any) => {
      if(params['redirect']) {
        location.href = '/registo?redirect='+params['redirect'];
      }else{
        location.href = '/registo';
      }
    })
  }

  recover() {
    this.route.queryParams.subscribe((params: any) => {
      if(params['redirect']) {
        location.href = '/recover?redirect='+params['redirect'];
      }else{
        location.href = '/recover';
      }
    });
  }

  login() {
    if(!this.verifyEmail.transform(this.email))
      setTimeout(() => {
        ErrorService.signalError.emit({
          title: "O seu email é invalido",
          showBody: false
        });
      }, 15);
    else if(this.password.length < 6)
      setTimeout(() => {
        ErrorService.signalError.emit({
          title: "A password precisa de pelo menos 6 caracteres",
          showBody: false
        });
      }, 15);
    else
      this.auth.submitLogin({email: this.email, password: this.password}).subscribe((data: boolean) => {
        if(data) {
          this.modal.open(FirstLoginMessage, {
            backdrop: 'static',
            windowClass: 'modal-koffeum',
            size: 'lg',
            centered: true
          }).result.then(result => {
            if(result == "passwd") {
              location.href = "admin/home";
              //alter to alter password
            }else if(result == "avoid"){
              this.auth.alterFirstTimeStatus().subscribe(() => {
                location.href = "admin/home";
              },(err) => {
                ErrorService.signalError.emit({
                  title: "Não mostrar mais este aviso",
                  body: err.error,
                  showCross: false,
                  redirect: "admin/home",
                  redirectTimer: 2.5
                });
              });
            }else{
              this.route.queryParams.subscribe((params) => {
                if(params['redirect']) {
                  location.href = '/'+params['redirect'];
                }else{
                  location.href = '/';
                }
              });
              //ignore warning
            }
          });
        }else{
          this.route.queryParams.subscribe((params) => {
            if(params['redirect']) {
              location.href = '/'+params['redirect'];
            }else{
              location.href = '/';
            }
          });
        }
      }, (err) => {
        console.log("Teste");
        ErrorService.signalError.emit({
          title: "Efetuar Login",
          body: err.error
        });
      });
  }

}
@Component({
  selector: "first-login-message",
  standalone: true,
  imports: [
    FormsModule
  ],
  template: `
  <div class="modal-header">
		<h4 class="modal-title">É o seu primeiro inicio de sessão</h4>
	</div>
	<div class="modal-body">
    <h6>Recomenda-mos a que após o primeiro inicio de sessão altere a sua password.</h6>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" [(ngModel)]="dontShowMore">
      <label type="button" class="form-check-label" (click)="dontShowMore = !dontShowMore">Não mostrar mais este aviso</label>
    </div>
	</div>
	<div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-outline-success-700" (click)="modal.close('passwd')">Alterar password</button>
    <button type="button" class="btn btn-outline-danger-700" (click)="modal.close(dontShowMore ? 'avoid' : 'ignore')">Ignorar o aviso</button>
	</div>
  `
})
class FirstLoginMessage {

  dontShowMore: boolean = false;

  constructor(public modal: NgbActiveModal) {}
}