<footer class="">
  <div class="container d-block">
    <div class="row py-3 justify-content-end">
      <div class="col-12 col-md-4 col-lg-2 d-flex justify-content-center mb-sm-3">
        <a href="https://www.livroreclamacoes.pt/inicio" target="_blank">
          <img src="../../assets/images/footer/livro-reclamacoes.svg" width="150" alt="" class="drop-shadow"></a>
      </div>
      <div class="col-12 col-md-4 col-lg-5 d-sm-flex justify-content-sm-center">
        <img src="../../assets/images/footer/payment_methods.png" width="300" height="50" alt="" class="drop-shadow">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row d-flex">
      <div class="col-12 col-lg-4 border-end border-sm-end-0 border-sm-bottom">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent">
            <a href="/pagamentos" class="text-decoration-none">Modos de Pagamento</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/envios" class="text-decoration-none">Envio de Encomendas e Portes</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/envios#volumes" class="text-decoration-none">Envio de Grandes Volumes</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/garantia" class="text-decoration-none">Trocas e Devoluções</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/garantia#rmas" class="text-decoration-none">Garantia e RMAs</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-4">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent">
            <a href="/cond-conta-online" class="text-decoration-none">Termos e Condições da Conta Online</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/politicas" class="text-decoration-none">Política de Privacidade</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/politicas#cookies" class="text-decoration-none">Política de Cookies</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/litigios" class="text-decoration-none">Resolução de Litígios</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/atuacao" class="text-decoration-none">Âmbito de Atuação</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 border-start">
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent">
            <a [href]="user ? '/conta' : '/login'" class="text-decoration-none">Área de Cliente</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/sobre-nos" class="text-decoration-none">Sobre a LUIS CARRILHO UNIPESSOAL Lda</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/koffeum-empresas" class="text-decoration-none">Koffeum Empresas</a>
          </li>
          <li class="list-group-item bg-transparent">
            <a href="/nossas-lojas" class="text-decoration-none">As nossas Lojas</a>
          </li>
          <li class="list-group-item bg-transparent"></li>
        </ul>
      </div>
    </div>
  </div>
  <nav class="navbar  navbar-light bg-light border-bottom">
    <div class="container d-block">
      <div class="row ">
        <div class="col-12 col-md-10 d-flex flex-sm-column flex-md-row justify-content-center align-items-center">
          2021 - Proudly powered by <a href="https://lcquantic.com/" target="_blank" class="text-warning text-decoration-none">&nbsp;LC QUANTIC</a><span class="hidden-sm">&nbsp;|&nbsp;</span>Todos os direitos reservados
        </div>
        <div class="col-12 col-md-2 d-flex justify-content-center mt-3 mt-md-0">
          <button class="btn btn-outline-secondary ">
            <a href="#" class="p-1">
              <i class="fal fa-angle-up"></i>
            </a>
          </button>
        </div>
      </div>
    </div>
  </nav>
</footer>