<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/litigios/litigios_online.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/litigios/litigios_online.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Sobre a LUIS CARRILHO UNIPESSOAL, Lda</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">Quem é a LUIS CARRILHO UNIPESSOAL, Lda?</h5>
              <p>A LUIS CARRILHO UNIPESSOAL, Lda, registada na Conservatória do Registo Comercial de Santarém com o número <span class="fw-bold">514 267 852</span>, é uma empresa de referência desde 2017 no mercado do Café, com loja em Santarém. Conta ainda com um Armazém Central, sendo este o centro nevrálgico da empresa e o local onde é efetuada grande parte da expedição via transportadora das encomendas online. Com um atendimento personalizado e profissional para clientes particulares e empresariais, um catálogo de produtos altamente diversificado e aos melhores preços, rápida expedição de encomendas online, um apoio ao cliente distinto e as melhores parcerias com os principais fabricantes do mercado, a LUIS CARRILHO UNIPESSOAL, Lda destaca-se há longos anos como um importante player do ramo em Portugal.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">História</h5>
                <p>Formada em 2017 na cidade de Santarém, a LUIS CARRILHO UNIPESSOAL, Lda depressa se tornou numa das principais escolhas da comunidade. Com altos índices de satisfação dos seus clientes, o marketing boca a boca - um dos tipos de publicidade mais credíveis - surgiu com naturalidade e foi fundamental para o crescimento da empresa. </p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Compromisso</h5>
                <p>Mais do que apresentar variedade e inovação, a LUIS CARRILHO UNIPESSOAL, Lda tem como compromisso apresentar continuamente os melhores preços e campanhas para o Cliente. Daí apresentar, ao longo de cada ano, fortes campanhas promocionais, que vão ao encontro das expetativas dos consumidores, para quem as compras são cada vez mais ponderadas. Este compromisso da LUIS CARRILHO UNIPESSOAL, Lda é conjugado, naturalmente, com uma oferta cuidadosamente selecionada de produtos e também com elevados níveis de serviço, assumindo um carácter particularmente distintivo no serviço de pós-venda.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Lojas LUIS CARRILHO UNIPESSOAL, Lda </h5>
                <p>Apesar de o negócio da LUIS CARRILHO UNIPESSOAL, Lda ter como base a sua loja online e toda a vertente que envolve o mundo digital, a LUIS CARRILHO UNIPESSOAL, Lda dá também especial atenção ao seu espaço "físico", onde com a sua loja consegue oferecer um serviço mais personalizado e até mais conveniente para alguns dos seus clientes. Na loja LUIS CARRILHO UNIPESSOAL, Lda de Santarém, poderá encontrar os seguintes serviços:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">•	Aconselhamento e esclarecimento de dúvidas diretamente com os profissionais LUIS CARRILHO UNIPESSOAL, Lda;dic</li>
                  <li class="list-group-item border-0 bg-transparent">•	Compra de produtos que pode encontrar na loja online (em <a href="http://www.koffeum.pt">www.koffeum.pt)</a>;</li>
                  <li class="list-group-item border-0 bg-transparent">•	Levantamento de encomendas previamente realizadas na loja online (em <a href="http://www.koffeum.pt">www.koffeum.pt)</a>;</li>
                  <li class="list-group-item border-0 bg-transparent">•	Receção de produtos para RMA (Return Merchandise Authorization), adquiridos diretamente em loja ou online.</li>
                </ul>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Canais de Comunicação</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda está em constante inovação e adaptação aos novos tempos. É de extrema importância para uma empresa como a LUIS CARRILHO UNIPESSOAL, Lda seguir a tendência do momento e estar presente onde os seus Clientes e futuros Clientes se encontram. Mais do que seguir tendências, é ir ao encontro do Cliente, é estar em plataformas centralizadas onde é possível apresentar as novidades e campanhas do momento, é garantir ao máximo que nenhum dos Clientes perde as oportunidades que a LUIS CARRILHO UNIPESSOAL, Lda proporciona. Por fim, o mais importante para a empresa: criar laços de amizade e confiança com os Clientes e futuros Clientes nessa plataforma. Por tudo isto, pode encontrar a LUIS CARRILHO UNIPESSOAL, Lda nas redes sociais mundialmente mais famosas, como o (Facebook, Twitter, Instagram, Youtube e TikTok).</p>
              </div>
              <div class="pt-3 mb-3">
                <p>Ainda não faz parte das centenas de clientes que já compraram na LUIS CARRILHO UNIPESSOAL, Lda?</p>
                <p>Registe a sua conta na nossa loja online ou visite a nossa loja e comece a usufruir de um serviço ímpar em Portugal.</p>
                <p>Até já!</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>