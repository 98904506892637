<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/conta-online/termos_e_condicoes_da_conta_online.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/conta-online/termos_e_condicoes_da_conta_online.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="mb-3">Termos e Condições da Conta Online</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <div>
                <ul class="list-group mb-3">
                  <a href="/cond-conta-online#info">
                    <li class="list-group-item border-0 bg-transparent">1. Informação Geral</li>
                  </a>
                  <a href="/cond-conta-online#acesso">
                    <li class="list-group-item border-0 bg-transparent">2. Acesso e Aceitação</li>
                  </a>
                  <a href="/cond-conta-online#utilizacao">
                    <li class="list-group-item border-0 bg-transparent">3. Correta Utilização de Website</li>
                  </a>
                  <a href="/cond-conta-online#indevido">
                    <li class="list-group-item border-0 bg-transparent">4. Responsabilidade por Uso Indevido</li>
                  </a>
                  <a href="/cond-conta-online#dominio">
                    <li class="list-group-item border-0 bg-transparent">5. Propriedade Intelectual e Industrial e Nome de Domínio</li>
                  </a>
                  <a href="/cond-conta-online#precos">
                    <li class="list-group-item border-0 bg-transparent">6. Informação Sobre os Produtos e Sobre Preços</li>
                  </a>
                  <a href="/cond-conta-online#compra">
                    <li class="list-group-item border-0 bg-transparent">7. Condições de Compra</li>
                  </a>
                  <a href="/cond-conta-online#consumidor">
                    <li class="list-group-item border-0 bg-transparent">8. Direitos do Consumidor</li>
                  </a>
                  <a href="/cond-conta-online#exclusao">
                    <li class="list-group-item border-0 bg-transparent">9. Exclusão de Garantias e Responsabilidades</li>
                  </a>
                  <a href="/cond-conta-online#civil">
                    <li class="list-group-item border-0 bg-transparent">10. Responsabilidade Civil</li>
                  </a>
                  <a href="/cond-conta-online#privacidade">
                    <li class="list-group-item border-0 bg-transparent">11. Política de Privacidade</li>
                  </a>
                  <a href="/cond-conta-online#cookies">
                    <li class="list-group-item border-0 bg-transparent">12. Política de Cookies</li>
                  </a>
                  <a href="/cond-conta-online#reclamacoes">
                    <li class="list-group-item border-0 bg-transparent">13. Comunicações e Reclamações</li>
                  </a>
                  <a href="/cond-conta-online#legislacao">
                    <li class="list-group-item border-0 bg-transparent">14. Legislação Aplicável</li>
                  </a>
                  <a href="/cond-conta-online#litigios">
                    <li class="list-group-item border-0 bg-transparent">15. Resolução de Litígios</li>
                  </a>
                </ul>
              </div>

             <div class="pt-3 mb-3" id="info">
                <h5 class="mb-3">1. Informação Geral</h5>
                <p>Ao aceder ao Website www.koffeum.pt (o “Website”), o Utilizador deve tomar conhecimento das seguintes disposições aplicáveis a cada um dos documentos, informações, dados e requisitos que nele se encontram e que é
                  propriedade e está sob a manutenção da LUIS CARRILHO UNIPESSOAL, Lda, sociedade comercial por quotas com sede social Rua Pedro de Santarém, nº 33, 4º Direito - 2000-223 Santarém, registada na Conservatória do Registo
                  Comercial de Santarém sob o número único de matrícula e de pessoa coletiva 514267852, com o capital social de 2.500,00 Euros, de ora em diante abreviadamente designada por “LUIS CARRILHO UNIPESSOAL, Lda”.</p>
                <p>O Website “Koffeum” é um sítio na Internet por meio do qual a LUIS CARRILHO UNIPESSOAL, Lda procede à venda de Café e equipamentos de Frio, Calor e Mobiliário e que disponibiliza informação sobre a sua atividade.</p>
                <p>Através do Website, sujeito às presentes condições gerais de utilização (as “Condições Gerais de Utilização”), o Utilizador poderá analisar os produtos e/ou serviços aí disponibilizados para venda pela LUIS CARRILHO
                  UNIPESSOAL, Lda (os “Produtos”), adquirindo-os mediante a celebração de contrato de compra e venda.</p>
                <p>Considera-se “Utilizador” todo o indivíduo ou instituição que, em geral, através do Website, visite o conteúdo no mesmo disponibilizado.</p>
                <p>O Website contém textos, fotografias, gráficos, logotipos, imagens, obras sujeitas a direitos de autor e outros tipos de informação (o "Conteúdo").</p>
              </div>
             <div class="pt-3 mb-3" id="acesso">
                <h5 class="mb-3">2. Acesso e Aceitação</h5>
                <p>O acesso e o uso das funcionalidades do Website encontram-se sujeitos às presentes Condições Gerais de Utilização, bem como a quaisquer leis e regulamentos, nacionais ou comunitários, aplicáveis. A LUIS CARRILHO
                  UNIPESSOAL, Lda recomenda que o Utilizador leia atentamente as presentes Condições Gerais de Utilização. Se não aceitar e não se comprometer a respeitar cada um dos presentes termos e condições, não ficará autorizado a
                  aceder ou a utilizar de outro modo o Website. O acesso e a utilização pelos Utilizadores do Website implicam a sua aceitação e o compromisso da sua parte, de respeitar todos e cada um dos termos e condições a seguir
                  estabelecidos. Se estiver a utilizar o Website em nome de outrem ou em nome de uma instituição, confirma que aquele que está a representar também concorda com os termos e condições, comprometendo-se a cumpri-los.</p>
                <p>As presentes Condições Gerais de Utilização poderão ser alteradas, modificadas, ampliadas ou atualizadas pela LUIS CARRILHO UNIPESSOAL, Lda, em qualquer momento e sem aviso prévio. Quaisquer Produtos novos, usados ou
                  recondicionados, assim como Conteúdo que se apresentem e/ou se disponibilizem no Website, ficarão também sujeitos às presentes Condições Gerais de Utilização, com efeitos a partir da data da sua utilização. Recomendamos
                  que o Utilizador consulte o Website de forma periódica, a fim de se manter atualizado relativamente a quaisquer alterações e atualizações.</p>
                <p>O Website não se destina a ser utilizados por menores de 18 (dezoito) anos, pelo que não são recolhidas, de forma intencional, informações que resultem da interação de menores com o Website. A LUIS CARRILHO UNIPESSOAL,
                  Lda não se responsabiliza pela interação ou acesso ao Conteúdo do Website por menores.</p>
                <p>O acesso a alguns dos conteúdos do Website poderá estar condicionado ao registo prévio do Utilizador, bem como à respetiva autenticação, através de identificação e palavra-passe. A LUIS CARRILHO UNIPESSOAL, Lda envidará
                  os seus melhores esforços para manter o Website acessível.</p>
                <p>Não obstante, a LUIS CARRILHO UNIPESSOAL, Lda reserva-se o direito de suspender, temporariamente e sem aviso prévio, o acesso ao Website ou a determinadas áreas ou funcionalidades disponíveis no mesmo, designadamente por
                  razões de manutenção. A LUIS CARRILHO UNIPESSOAL, Lda não assume qualquer responsabilidade pelos prejuízos decorrentes de eventuais situações de impossibilidade, atraso, suspensão ou interrupção no acesso ao Website em
                  virtude de falhas técnicas ou outros motivos. A LUIS CARRILHO UNIPESSOAL, Lda não garante que o acesso ao Website não sofra interrupções ou perturbações resultantes de problemas técnicos.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda reserva-se o direito de completar, modificar ou suprimir o Conteúdo e Funcionalidades do Website.</p>
              </div>

             <div class="pt-3 mb-3" id="utilizacao">
                <h5 class="mb-3">3. Correta Utilização do Website</h5>
                <p>O Utilizador compromete-se a fazer uma utilização adequada e lícita do Website, bem como dos Conteúdos e Produtos que nele sejam comercializados, em conformidade com a legislação aplicável em cada momento. O Utilizador
                  deverá abster-se de:</p>
                <p>Fazer um uso não autorizado ou fraudulento do Website e/ou do Conteúdo com fins ou efeitos ilícitos, lesivos dos direitos e interesses de terceiros, ou que de qualquer forma possam causar danos, inutilizar, sobrecarregar,
                  deteriorar ou impedir a normal utilização dos serviços ou dos documentos, arquivos e todo o tipo de conteúdos armazenados em qualquer equipamento informático.</p>
                <p>Aceder ou tentar aceder a recursos ou áreas restringidas do Website sem cumprir as condições exigidas para tal acesso.</p>
                <p>Introduzir ou difundir na rede programas, vírus, macros, applets, controles ActiveX ou qualquer outro dispositivo que cause ou seja suscetível de causar qualquer tipo de alteração nos sistemas informáticos do Website ou
                  de terceiros.</p>
                <p>Provocar danos nos sistemas físicos e lógicos da LUIS CARRILHO UNIPESSOAL, Lda, de pessoas a ela associadas ou de terceiros, introduzir ou difundir na rede vírus informáticos ou quaisquer outros sistemas físicos ou
                  lógicos que sejam suscetíveis de provocar os danos anteriormente mencionados.</p>
                <p>Copiar, reproduzir, recompilar, desmontar, realizar atividades de engenharia reversa, distribuir, publicar, mostrar, executar, modificar, importar, realizar trabalhos derivados, transmitir ou explorar partes do Website,
                  excetuando:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) a importação de conteúdo do Website e/ou fazer uma cópia para seu uso pessoal não lucrativo e </li>
                  <li class="list-group-item border-0 bg-transparent">(II) a partilha de Conteúdos, sempre e quando o Utilizador reconheça todos os direitos de autor inerentes ao Website e respetivo Conteúdo.</li>
                </ul>
                <p>Obter e tentar obter o Conteúdo ou Produtos oferecidos no Website utilizando para tal meios ou procedimentos diferentes dos que, dependendo dos casos, tenham sido colocados à sua disposição ou que tenham sido indicados
                  expressamente no Website ou, no geral, dos que se utilizem habitualmente na Internet por não representarem um risco de dano, inutilização ou uso fraudulento do Website e/ou do seu Conteúdo.</p>
                <p>O Utilizador garante que as atividades que desenvolva no uso ou acesso ao Website em nenhum caso resultarão ofensivas para o bom nome e imagem comercial da LUIS CARRILHO UNIPESSOAL, Lda ou de pessoas ou entidades a esta
                  associadas, para outros utilizadores do Website e Plataforma ou terceiros.</p>
                <p>No caso de incumprimento de alguma das estipulações das presentes Condições Gerais de Utilização por parte do Utilizador, a LUIS CARRILHO UNIPESSOAL, Lda reserva-se ao direito de interromper momentânea ou definitivamente
                  o acesso do Utilizador ao Website.</p>
              </div>
             <div class="pt-3 mb-3" id="indevido">
                <h5 class="mb-3">4. Responsabilidade por Uso Indevido</h5>
                <p>Caso o Utilizador utilize indevida e/ou ilicitamente o Website, em particular violando direta ou indiretamente as presentes Condições Gerais de Utilização, o Utilizador estará sujeito a responder perante a LUIS CARRILHO
                  UNIPESSOAL, Lda por qualquer dano ou prejuízo e a manter a LUIS CARRILHO UNIPESSOAL, Lda indemne. Em caso de incumprimento das presentes Condições Gerais de Utilização, a LUIS CARRILHO UNIPESSOAL, Lda reserva-se o direito
                  de cancelar de forma unilateral, sob a sua exclusiva discricionariedade e sem necessidade de pré-aviso, o acesso, utilização, e/ou registo de qualquer Utilizador no Website, sem que isso dê lugar, em nenhum caso, a
                  qualquer indemnização ao Utilizador em questão.</p>
                <p>O Utilizador compromete-se a informar a LUIS CARRILHO UNIPESSOAL, Lda, sem demora, sempre que observe uma utilização não autorizada do Website ou tiver conhecimento de que se está a produzir uma violação de um direito da
                  LUIS CARRILHO UNIPESSOAL, Lda ou de terceiros, ou se se estiver a levar a cabo uma utilização contrária às presentes Condições Gerais de Utilização.</p>
              </div>
             <div class="pt-3 mb-3" id="dominio">
                <h5 class="mb-3">5. Propriedade Intelectual e Industrial e Nome de Domínio</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda é titular de todos os direitos de propriedade intelectual e industrial sobre todos os materiais e elementos que aparecem no Website e/ou encontra-se devidamente autorizada pelos respetivos
                  titulares para a publicação e divulgação dos mesmos. A LUIS CARRILHO UNIPESSOAL, Lda é titular do domínio www.koffeum.pt. Qualquer utilização indevida e sem autorização supõe uma infração dos direitos conferidos pelo seu
                  registo.</p>
                <p>A navegação ou aquisição de um Produto, não confere ao Utilizador nenhum direito de alteração, exploração, reprodução, distribuição ou comunicação pública sobre a propriedade intelectual ou industrial dos Produtos e
                  equipamentos disponibilizados pela LUIS CARRILHO UNIPESSOAL, Lda, nem do Conteúdo do Website.</p>
                <h6>Não obstante, o Utilizador poderá:</h6>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) visualizar o Conteúdo do Website e inclusivamente imprimir, copiar e armazenar estes elementos no disco rígido do seu PC ou em qualquer outro suporte físico sempre e
                    quando seja, única e exclusivamente, para seu uso pessoal, bem como </li>
                  <li class="list-group-item border-0 bg-transparent">(II) partilhar conteúdos publicados, desde que respeitados todos os direitos de autor, propriedade intelectual e outros associados (caso em que, salvo indicação expressa
                    em contrário, também os deep-links se devem considerar autorizados).</li>
                </ul>
                <p>Os materiais e informação partilhados ou publicados no Website devem respeitar os direitos de imagem e de propriedade intelectual e industrial que possam existir sobre os mesmos.</p>
              </div>
             <div class="pt-3 mb-3" id="precos">
                <h5 class="mb-3">6. Informação Sobre os Produtos e Sobre Preços</h5>
                <h6>6.1 Informação de Produtos</h6>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda tem em consideração a informação relativa às caraterísticas essenciais dos Produtos mediante descrições técnicas partilhadas pelos seus fornecedores, e de fotografias que ilustram os
                  Produtos comercializados, em rigoroso respeito pelos melhores padrões do mercado.</p>
                <h6>6.2 Informação de Preços</h6>
                <p>Os preços de venda ao público indicados em www.koffeum.pt e seus subdomínios são apresentados em euros. O IVA está incluído em todos os preços mencionados.</p>
                <p>Os custos do serviço de envio poderão ser por conta do Utilizador e comprador, e acrescem ao total do valor dos produtos selecionados. O Utilizador será informado destes encargos antes de confirmar o seu pedido e concluir
                  o processo de compra. A LUIS CARRILHO UNIPESSOAL, Lda reserva-se o direito de alterar os preços em qualquer momento. No entanto, a LUIS CARRILHO UNIPESSOAL, Lda compromete-se a aplicar as taxas e preços indicados no
                  momento da realização do pedido de compra.</p>
                <p>No caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que cause uma alteração substancial não prevista pela LUIS CARRILHO UNIPESSOAL, Lda no preço de venda ao público, de tal modo que
                  este se transforme exorbitante ou manifestamente irrisório, o pedido de compra será considerado inválido e anulado.</p>
                <p>No caso de ocorrência de erro informático, manual, técnico, ou de qualquer outra origem, que cause uma alteração substancial não prevista pela LUIS CARRILHO UNIPESSOAL, Lda no preço de venda ao público, de tal modo que
                  este se transforme exorbitante ou manifestamente irrisório, o pedido de compra será considerado inválido e anulado.</p>
                <p>Os preços dos produtos disponibilizados em www.koffeum.pt são válidos para pedidos de compra realizados através de www.koffeum.pt, coincidindo com aqueles apresentados na loja física LUIS CARRILHO UNIPESSOAL, Lda.</p>
              </div>
             <div class="pt-3 mb-3" id="compra">
                <h5 class="mb-3">7. Condições de Compra</h5>
                <h6>7.1 Disponibilidade dos Produtos</h6>
                <p>Todos os produtos que se encontram no Website Koffeum estão disponíveis para encomenda, salvo rutura de stock. Os produtos disponíveis no Website possuem um stock limitado pelo que podem ficar indisponíveis sem aviso.</p>
                <p>Em caso de rutura de stock ocorrida após o pagamento da encomenda, a LUIS CARRILHO UNIPESSOAL, Lda irá informar o Cliente desse facto tendo em vista o novo agendamento de entrega assim que o artigo estiver disponível ou o
                  reembolso no prazo máximo de 14 (catorze) dias a contar da data do conhecimento daquela indisponibilidade.</p>
                <h6>7.2 Limitações de Compras no Website Koffeum</h6>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda, querendo disponibilizar a todos os seus clientes a oportunidade de adquirir os produtos publicitados no seu site, no âmbito de grandes períodos promocionais designados como Promoções,
                  Black Friday e outros, poderá aplicar limites de compra por produto a cada cliente.</p>
                <h6>7.3 Entregas e Custos</h6>
                <p>Podem ser devidos encargos suplementares pelo transporte do produto adquirido no Site Koffeum, os quais acrescem ao preço total dos produtos selecionados. O Cliente será informado destes encargos, sempre que aplicável,
                  bem como do preço total, antes de confirmar o seu pedido e concluir o processo de compra. A LUIS CARRILHO UNIPESSOAL, Lda reserva-se o direito de alterar os preços destes encargos a qualquer momento, sem prejuízo de
                  aplicar as taxas e preços contratualizados no momento da realização do pedido de compra.</p>
                <p>Para mais informações sobre os custos de entrega, consulte www.koffeum.pt/encomendas-e-portes</p>
                <h6>7.4 Tratamento e Entrega da Encomenda</h6>
                <p>A encomenda apenas será processada após a confirmação do respetivo pagamento, sendo o Cliente informado, por escrito, da confirmação da celebração da venda do produto/serviço e da data estimada de entrega ou
                  disponibilização na loja.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda não realiza entregas em apartados.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda faz entregas dos produtos vendidos por si, no Website Koffeum, no território de Portugal Continental e nos arquipélagos dos Açores e Madeira.</p>
                <p>O Cliente pode escolher, dentro do território acima indicado, se quer receber o artigo numa morada à sua escolha ou numa loja Koffeum à sua escolha, consoante a tipologia de artigos e sujeito às condições de entrega em
                  loja.</p>
                <p>Caso o Cliente escolha levantar o artigo numa loja Koffeum, deverá proceder ao levantamento no prazo máximo indicado no email, após a notificação para o efeito. Caso não proceda ao levantamento do artigo no prazo
                  indicado, a LUIS CARRILHO UNIPESSOAL, Lda reserva-se no direito de proceder ao cancelamento da encomenda.</p>
                <p>A fatura da encomenda será disponibilizada, no momento da expedição da encomenda, em formato digital na área pessoal do Cliente no Website Koffeum.pt ou email.</p>
                <h6>7.5 Seguimento da Encomenda</h6>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda irá informar o Cliente do estado da sua encomenda e data de entrega ou disponibilização em Loja por email e/ou SMS.</p>
                <h6>7.6 Cancelamento da Encomenda</h6>
                <p>Sem prejuízo do Direito de Livre Resolução referido em 8.3 abaixo, o Cliente pode solicitar o cancelamento da encomenda no Site Koffeum, até ao momento de preparação dos artigos, devendo para o efeito contactar a linha de
                  Apoio ao Cliente da LUIS CARRILHO UNIPESSOAL, Lda, através do endereço koffeum.pt ou através do número 912 298 450 (chamada para a rede móvel), de segunda a sexta, das 9h às 18h.</p>
                <h6>7.7 Erros</h6>
                <p>Nos termos do indicado em 6.2 acima, ressalvam-se eventuais erros de programação, defeitos no funcionamento do sistema informático (máquina) ou deformação da mensagem (incluindo lapsos de escrita), nos termos e para os
                  efeitos do previsto nas alíneas a) e b) do n.º 2 do artigo 33.º do Decreto-Lei n.º 7/2004, de 7 de janeiro.</p>
                <h6>7.8 Meios de Pagamento</h6>
                <p>Para a comodidade do Cliente, a LUIS CARRILHO UNIPESSOAL, Lda disponibiliza no Site Koffeum os seguintes meios de pagamento dos produtos e/ou serviços:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">Cartão Visa ou MasterCard;</li>
                  <li class="list-group-item border-0 bg-transparent">Multibanco;</li>
                  <li class="list-group-item border-0 bg-transparent">MB Way;</li>
                </ul>
                <p>Para mais informações sobre os meios de pagamento disponíveis, consulte www.koffeum.pt/modos-de-pagamento.</p>
              </div>
             <div class="pt-3 mb-3" id="consumidor">
                <h5 class="mb-3">8. Direitos do Consumidor</h5>
                <p>Os artigos comercializados pela LUIS CARRILHO UNIPESSOAL, Lda em www.koffeum.pt estão abrangidos pelo regime legal, de defesa de direitos dos consumidores, em conformidade com as normas legais e regulamentares vigentes em
                  Portugal, designadamente as resultantes dos seguintes diplomas: </p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) Regime Legal Aplicável à Defesa dos Consumidores, aprovada pela Lei n.º 24/96, de 31 de julho, também conhecido por Lei da Defesa do Consumidor (doravante a “LDC”);
                  </li>
                  <li class="list-group-item border-0 bg-transparent">(II) Decreto-Lei n.º 84/2021, de 18 de outubro, que regula os direitos do consumidor na compra e venda de bens, conteúdos e serviços digitais (doravante o “DL 84/2021”);
                    e </li>
                  <li class="list-group-item border-0 bg-transparent">(III) Regime Aplicável aos Contratos Celebrados à Distância e Fora do Estabelecimento Comercial, aprovado pelo Decreto-Lei n.º 24/2014, de 14 de fevereiro (doravante o
                    “RCCD”). Em conformidade com estes diplomas, qualifica-se como consumidor o Utilizador e/ou cliente da LUIS CARRILHO UNIPESSOAL, Lda que seja pessoa singular e adquira Produtos não destinados a atividade comercial,
                    industrial, artesanal ou profissional.</li>
                </ul>
                <p>O consumidor beneficia de um regime de proteção legal ao abrigo do qual, para além do mais, tem direito:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) à qualidade dos Produtos; </li>
                  <li class="list-group-item border-0 bg-transparent">(II) à informação para o consumo; </li>
                  <li class="list-group-item border-0 bg-transparent">(III) à proteção dos interesses económicos; e </li>
                  <li class="list-group-item border-0 bg-transparent">(IV) à prevenção e à reparação dos danos patrimoniais ou não patrimoniais que resultem da ofensa de interesses ou direitos individuais homogéneos, coletivos ou difusos.
                  </li>
                </ul>
                <p>Não dispensando o aconselhamento legal que o Utilizador possa obter, referimo-nos nos sub-parágrafos abaixo, de forma sucinta, a alguns direitos legais do consumidor na sua relação com a LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <h6>8.1 Entrega dos Produtos</h6>
                <p>Os Produtos adquiridos à LUIS CARRILHO UNIPESSOAL, Lda consideram-se entregues quando:</p>
                <p class="ms-3">- o consumidor ou outra pessoa indicada pelo consumidor (exceto o transportador) adquire posse física do bem (ou seja, no momento subsequente ao pagamento e receção do Produto, quando haja sido adquirido em
                  loja; no momento do levantamento em loja do Produto; ou no momento em que o Produto é entregue na morada indicada pelo consumidor); ou</p>
                <p class="ms-3">- estando contratada a instalação do Produto por conta da LUIS CARRILHO UNIPESSOAL, Lda, este considera-se entregue quando a instalação se encontrar concluída.</p>
                <p>Os Produtos adquiridos com envio serão entregues confirme descrito aqui: <a href="/envios">koffeum.pt/envios</a>.</p>
                <h6>8.2 Qualidade dos Produtos / Garantia</h6>
                <p>Os Produtos adquiridos pelo consumidor devem, designadamente, ser aptos a satisfazer os fins a que se destinam, corresponder à quantidade e possuir as qualidades e outras características, inclusive no que respeita à
                  durabilidade, funcionalidade, compatibilidade, interoperabilidade e segurança, habituais e expectáveis nos Produtos do mesmo tipo, considerando, designadamente, a sua natureza e sua descrição pela LUIS CARRILHO UNIPESSOAL,
                  Lda ou pelo Produtor.</p>
                <p>Deverá consultar informações detalhadas sobre Produtos que apresentem falta de conformidade aqui: <a href="/garantia#rmas">koffeum.pt/garantia e rmas</a>.</p>
                <h6 class="ms-3">8.2.1 Produto com defeito / avariado</h6>
                <p class="ms-3">Deverá dirigir-se a uma loja física da Koffeum, apresentando a fatura e o artigo completo. No caso de esta opção não ser possível, poderá abrir um pedido de RMA (Return Merchandise Authorization) através da
                  loja online e seguir com o indicado no processo.</p>
                <h6 class="ms-3">8.2.2 Devoluções por danos de transporte </h6>
                <p class="ms-3">o cliente deve aquando da sua receção e desembalamento verificar a existência de algum dano visível que possa ter sido causado pelo transporte. </p>
                <p class="ms-3">Caso o artigo tenha algum dano não o deve aceitar. Após a assinatura da guia de transporte a LUIS CARRILHO UNIPESSOAL, Lda não se responsabiliza por qualquer dano físico provocado pelo transporte.</p>
                <h6>8.3 Devoluções e Direito de Livre Resolução do Contrato</h6>
                <p>Para informação sobre trocas e devoluções consulte: <a href="/garantia">koffeum.pt/trocas-e-devolucoes</a>.</p>
                <p>No caso de produtos adquiridos através do Website Koffeum, o consumidor dispõe de um prazo de 14 dias, a contar da data de entrega do Produto, ou da data de entrega do último Produto, no caso de vários Produtos
                  encomendados numa única encomenda e entregues separadamente, para proceder à devolução da sua encomenda.</p>
                <p>Para exercer este direito, deve declarar inequivocamente de que pretende resolver o contrato e deve devolver o artigo completo, nas mesmas condições em que foi vendido, sem sinais de uso indevido, em embalagem original e
                  em bom estado, manual de instruções e acompanhado de todos os acessórios que o constituem.</p>
                <h6 class="ms-3">8.3.1 Condições de Devolução</h6>
                <p class="ms-3">As devoluções devem respeitar as condições previstas em <a href="/garantia">koffeum.pt/trocas-e-devolucoes</a>.</p>
                <p class="ms-3">No caso de o consumidor comprar vários produtos no âmbito de uma campanha com oferta de um ou vários produtos da encomenda, ou em campanhas do género “pague um leve dois” ou “pague dois e leve três”, o
                  direito de livre resolução deve ser exercido em relação a todos os bens encomendados. Nestes casos, a LUIS CARRILHO UNIPESSOAL, Lda não aceitará a devolução parcial da encomenda.</p>
                <p class="ms-3">No caso de o consumidor comprar produtos no âmbito de uma campanha com oferta de um produto, cujo seu usufruto já tenha sido obtido através de resgate e/ou redeem, a LUIS CARRILHO UNIPESSOAL, Lda não aceitará
                  a devolução parcial da encomenda.</p>
                <p class="ms-3">Salvo anomalia técnica, existem artigos que pela sua natureza (por questões de higiene, segurança, risco de transmissão de dados, recomendações do fabricante, etc.) não podem ser aceites para
                  devoluções/trocas.</p>
                <p class="ms-3"><span class="fw-bold">Atenção:</span> A LUIS CARRILHO UNIPESSOAL, Lda reserva-se no direito de recusar devoluções de artigos cujo seu Serial Number já tenha sido registado/associado a uma conta de utilizador
                  nas plataformas online/apps dos mais variados fabricantes. A associação de um artigo (via Serial Number) a uma plataforma online/app poderá em muitos casos permitir que utilizadores já não na posse dos mesmos consigam
                  controlar e rastrear estes artigos, estando estes a ser utilizados por terceiros. Por este motivo, a LUIS CARRILHO UNIPESSOAL, Lda adverte para a possível recusa de devoluções para a total proteção dos seus Clientes.</p>
                <p class="ms-3">No caso de violação de algum destes pontos, não poderemos aceitar a devolução (e o consequente reembolso).</p>
                <h6 class="ms-3">8.3.2 Declaração de Resolução do Contrato</h6>
                <p class="ms-3">Para dar início ao processo de resolução (devolução) basta dirigir-se a uma loja Koffeum com o artigo e a sua fatura, ou caso não tenha essa possibilidade, abrir um pedido de RMA na área reservada na loja
                  online da LUIS CARRILHO UNIPESSOAL, Lda no Website, mencionando na causa da avaria "Devolução Comercial". </p>
                <p class="ms-3">Posteriormente deve enviar o artigo via transportadora para o Armazém Central da LUIS CARRILHO UNIPESSOAL, Lda. </p>
                <p class="ms-3">Os valores destes portes ficam a cargo do consumidor.</p>
                <h6 class="ms-3">8.3.3 Reembolso</h6>
                <p class="ms-3">Após o exercício do Direito de Livre Resolução do Contrato, o reembolso do valor pago pelo consumidor, incluindo, se aplicável, os custos que o consumidor tenha pago pela entrega do bem, será efetuado através
                  do mesmo meio de pagamento que foi utilizado na compra inicial, sem custos para o consumidor, após a validação do cumprimento das condições de devolução descritas em 8.3.1 acima, tendo a LUIS CARRILHO UNIPESSOAL, Lda um
                  prazo máximo de 14 dias para o efeito.</p>
                <p class="ms-3">Salvo os casos em que a LUIS CARRILHO UNIPESSOAL, Lda se ofereça para recolher o artigo, a LUIS CARRILHO UNIPESSOAL, Lda pode reter o reembolso enquanto o artigo não for recebido ou enquanto o consumidor não
                  apresentar prova da devolução do artigo.</p>
              </div>
             <div class="pt-3 mb-3" id="exclusao">
                <h5 class="mb-3">9. Exclusão de Garantias e Responsabilidades</h5>
                <p>O acesso e a utilização do Website são da total responsabilidade do Utilizador. O Utilizador reconhece que utiliza o Website por sua conta e responsabilidade.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda não garante nem se responsabiliza por danos, prejuízos, perdas, reclamações ou custos de qualquer natureza que se devam às seguintes circunstâncias:</p>
                <p class="ms-3">- Compatibilidade do Website com o hardware ou software utilizados pelo Utilizador.</p>
                <p class="ms-3">- Interferências, interrupções, falhas, omissões, avarias telefónicas, atrasos, bloqueios ou desconexões no funcionamento do sistema eletrónico, motivadas por deficiências, sobrecargas e erros nas linhas e
                  redes de telecomunicações, ou por qualquer outra causa alheia ao controlo da LUIS CARRILHO UNIPESSOAL, Lda</p>
                <p class="ms-3">- Intromissões ilegítimas mediante o uso de programas malignos de qualquer tipo e através de qualquer meio de comunicação, tais como vírus informáticos ou quaisquer outros.</p>
                <p class="ms-3">- Uso indevido ou inadequado do Website.</p>
                <p class="ms-3">- Erros de segurança ou navegação produzidos por um mau funcionamento do navegador ou pelo uso de versões não atualizadas do mesmo.</p>
                <p>Eventuais reparações de danos e prejuízos do Utilizador imputadas à LUIS CARRILHO UNIPESSOAL, Lda, que resultem de uma falta que lhe seja diretamente imputável, corresponderá ao prejuízo direto, com a expressa exclusão
                  dos danos indiretos, danos emergentes ou lucros cessantes.</p>
              </div>
             <div class="pt-3 mb-3" id="civil">
                <h5 class="mb-3">10. Responsabilidade Civil</h5>
                <p>Salvo se decorrente de contrato em vigor entre a LUIS CARRILHO UNIPESSOAL, Lda e o Utilizador ou terceiro, a LUIS CARRILHO UNIPESSOAL, Lda, os seus administradores, diretores e empregados, sócios, filiais, subsidiárias, sucessores e cessionários, assim como os seus agentes não serão responsáveis, direta ou indiretamente, de nenhuma maneira, face ao Utilizador ou a qualquer outra pessoa por: </p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) erros e omissões do Website; </li>
                  <li class="list-group-item border-0 bg-transparent">(II) atrasos, erros ou interrupções na transmissão ou entrega de conteúdos através do Website; </li>
                  <li class="list-group-item border-0 bg-transparent">(III) perdas ou estragos, ou por qualquer outra razão de incumprimento.</li>
                </ul>
              </div>
             <div class="pt-3 mb-3" id="privacidade">
                <h5 class="mb-3">11. Política de Privacidade</h5>
                <p>Os Dados Pessoais fornecidos pelo titular através da interação com o Website ou no uso das suas Funcionalidades serão processados e armazenados informaticamente pela LUIS CARRILHO UNIPESSOAL, Lda, no âmbito de relações pré-contratuais ou contratuais, incluindo qualquer situação decorrente ou relacionado com a relação contratual ou pré-contratual, independentemente da sua natureza.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda recomenda a leitura atenta da Política de Privacidade, a qual fornece informações importantes sobre a forma como a LUIS CARRILHO UNIPESSOAL, Lda trata os dados pessoais que lhe são fornecidos, as normas de segurança utilizadas para proteção dos dados e explica os direitos que assistem aos Titulares dos Dados, a qual o Utilizador poderá consultar através do link: <a href="/privacidade">koffeum.pt/politica-de-privacidade</a>.</p>
              </div>
             <div class="pt-3 mb-3" id="cookies">
                <h5 class="mb-3">12. Política de Cookies</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda recomenda a leitura atenta da Política de Cookies, a qual fornece informações importantes sobre a forma como os cookies são utilizados no Website e Plataforma, a qual o Utilizador poderá consultar através do link: <a href="/cookies">koffeum.pt/politica-de-cookies</a>.</p>
              </div>
             <div class="pt-3 mb-3" id="reclamacoes">
                <h5 class="mb-3">13. Comunicações e Reclamações</h5>
                <p>Qualquer comunicação que o Utilizador deseje realizar à LUIS CARRILHO UNIPESSOAL, Lda, designadamente reclamações, deverá realizá-la dirigindo a esta uma comunicação escrita através dos contactos e meios disponibilizados no Website.</p>
                <p>O Utilizador aceita expressamente a utilização do correio eletrónico como procedimento válido para a remissão de todas as comunicações relacionadas com a utilização do Website, e/ou a aquisição de Produtos.</p>
              </div>
             <div class="pt-3 mb-3" id="legislacao">
                <h5 class="mb-3">14. Legislação Aplicável</h5>
                <p>As presentes Condições de Utilização regem-se pela lei portuguesa. Caso uma qualquer disposição venha a ser considerada ilícita ou inaplicável por um tribunal da jurisdição competente, tal não afetará a validade e aplicabilidade das restantes disposições.</p>
              </div>
             <div class="pt-3 mb-3" id="litigios">
                <h5 class="mb-3">15. Resolução de Litígios</h5>
                <p>Caso tenha uma reclamação ou queixa sobre as Condições de Utilização do Website, deve remeter uma comunicação escrita através dos contactos e meios disponibilizados no Website. A LUIS CARRILHO UNIPESSOAL, Lda responder-lhe-á com a maior brevidade possível, propondo-lhe uma solução que vá de encontro à questão apresentada.</p>
                <p>Qualquer litígio ou reclamação derivada ou relacionada com as presentes Condições Gerais de Utilização ou com a utilização do Website, deverá ser submetido aos tribunais judiciais competentes.</p>
                <p>O Utilizador poderá, ainda, recorrer à entidade de resolução alternativa de litígios competente.</p>
                <p>Sem prejuízo do disposto na legislação, nos estatutos e nos regulamentos a que as entidades de resolução alternativa de litígios se encontram vinculadas, o Utilizador poderá optar pela plataforma europeia de resolução de litígios em linha disponível em <a href="https://webgate.ec.europa.eu/odr" target="_blank">https://webgate.ec.europa.eu/odr</a>, pela entidade de resolução alternativa de litígios do local do seu domicílio ou pela entidade de resolução alternativa de competência especializada.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>