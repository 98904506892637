<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/koffeum-empresas/koffeum_empresas.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/koffeum-empresas/koffeum_empresas.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>KOFFEUM Empresas</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">Código de Conduta</h5>
              <p>O sucesso da empresa constrói-se com o parceiro certo. Equipe a sua força de trabalho com a ajuda dos líderes na informática especializada.</p>
              <p>Dê à sua empresa o parceiro de negócio certo em cada uma das áreas de atuação procuradas. A LUIS CARRILHO UNIPESSOAL, Lda conta com profissionais com a experiência e know-how que procura para dar o rumo certo à sua empresa.</p>
              <p>Equipe a sua força de trabalho com a ajuda da LUIS CARRILHO UNIPESSOAL, Lda e desfrute da vantagem de ter ao seu lado o parceiro que o irá apoiar em algumas das decisões mais importantes da sua empresa.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Vias de Contactos Exclusivas</h5>
                <p>Sabemos que no mundo empresarial atual o seu negócio não pode abrandar. Alta capacidade de resposta é fulcral para a sua empresa que pretende crescer num mercado altamente competitivo.</p>
                <p>Se tem uma empresa, usufrua das nossas vias de contacto exclusivas para o mercado corporate.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Aquisições / Propostas / Orçamento</h5>
                <p>Temos um departamento altamente qualificado que o irá ajudar na aquisição de produtos, criação de propostas e orçamentos de acordo com as suas exigências.</p>
                <p>Diga-nos o que pretende ou qual a sua ideia e a nossa equipa dedicada ao mercado corporate encarregar-se-á de fazer crescer e melhorar a sua força de trabalho.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Vantagens da LUIS CARRILHO UNIPESSOAL, Lda para a sua Empresa</h5>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">- Conselho e know-how especializado;</li>
                  <li class="list-group-item border-0 bg-transparent">- Descontos por quantidades ou tipos de produtos;</li>
                  <li class="list-group-item border-0 bg-transparent">- Aquisição de produtos não disponíveis na loja online;</li>
                  <li class="list-group-item border-0 bg-transparent">- Facilidade de pagamento mediante tipo de empresa;</li>
                  <li class="list-group-item border-0 bg-transparent">- Soluções competitivas e à medida de cada negócio;</li>
                  <li class="list-group-item border-0 bg-transparent">- Pós-venda distinto.</li>
                </ul>
              </div>
              <div class="pt-3 mb-3">
                <p>Envie-nos um email para</p>
                <p>(empresas@koffeum.pt)</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>