import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacidade-cookies',
  templateUrl: './privacidade-cookies.component.html',
  styleUrls: ['./privacidade-cookies.component.css']
})
export class PrivacidadeCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
