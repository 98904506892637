<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/koffeum-empresas/koffeum_empresas.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/koffeum-empresas/koffeum_empresas.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>AS NOSSAS LOJAS</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-4">
              <h5 class="mb-3">VISITA UMA DAS NOSSAS LOJAS</h5>
              <p>Em todas as lojas KOFFEUM (Luís Carrilho Unipessoal, Lda) poderás encontrar técnicos especializados prontos a oferecer soluções à tua medida</p>
              <p>Reconhecidos pela simpatia, rapidez e profissionalismo no atendimento, damos a garantia de uma espera reduzida e um tempo médio de 15 minutos para efeitos de levantamento de encomendas.</p>
              <p>Em cada espaço físico, poderás usufruir de todas as vantagens e serviços exclusivos associados, e também conseguirá acesso a todas as campanhas promocionais e aos produtos mais desejados da loja online em stock.</p>
              <p>Um serviço personalizado e cheio de vantagens ao teu dispor. Esperamos por si!</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>