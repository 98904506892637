import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssistenciaComponent } from './assistencia/assistencia.component';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactosComponent } from './contactos/contactos.component';
import { EquipamentoCafeComponent } from './equipamento-cafe/equipamento-cafe.component';
import { EquipamentoDepuradoresComponent } from './equipamento-depuradores_obsoleto/equipamento-depuradores.component';
import { EquipamentoMoinhosComponent } from './equipamento-moinhos_obsoleto/equipamento-moinhos.component';
import { EquipamentosComponent } from './equipamentos/equipamentos.component';
import { KraftComponent } from './kraft/kraft.component';
import { LoginComponent } from './login/login.component';
import { ProjectosComponent } from './projectos/projectos.component';
import { RecuperacaoComponent } from './recuperacao/recuperacao.component';
import { RegistoComponent } from './registo/registo.component';
import { StepperComponent } from './stepper/stepper.component';
import { VerProdutosComponent } from './verProdutos/verProdutos.component';
import { _homeComponent } from './_home/_home.component';
import { ModosPagamentoComponent } from './modos-pagamento/modos-pagamento.component';
import { EnviosComponent } from './envios/envios.component';
import { Trocas_rmasComponent } from './trocas_rmas/trocas_rmas.component';
import { CondicoesContaOnlineComponent } from './condicoes-conta-online/condicoes-conta-online.component';
import { PrivacidadeCookiesComponent } from './privacidade-cookies/privacidade-cookies.component';
import { LitigiosComponent } from './litigios/litigios.component';
import { AtuacaoComponent } from './atuacao/atuacao.component';
import { Sobre_nosComponent } from './sobre_nos/sobre_nos.component';
import { KoffeumEmpresaComponent } from './koffeum-empresa/koffeum-empresa.component';
import { NossasLojasComponent } from './nossas-lojas/nossas-lojas.component';
import { MostrarEquipCafeComponent } from './mostrar-equip-cafe/mostrar-equip-cafe.component';
import { MostrarEquipQuenteFrioOutrosComponent } from './mostrar-equip-quente-frio-outros/mostrar-equip-quente-frio-outros.component';
import { AuthGuard } from './_&guards/AuthGuard.service';
import { GestaoRoutes } from './gestao/getao.routes';
import { SearchOutputComponent } from './SearchOutput/SearchOutput.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';

const routes: Routes = [
  { path: '', component: _homeComponent },
  { path: 'kraft', component: KraftComponent },
  { path: 'equip-cafe', component: EquipamentoCafeComponent },
  { path: 'equip-moinho', component: EquipamentoMoinhosComponent },
  { path: 'equip-depurador', component: EquipamentoDepuradoresComponent },
  { path: 'equip', component: EquipamentosComponent },
  { path: 'projectos', component: ProjectosComponent },
  { path: 'assistencia', component: AssistenciaComponent },
  { path: 'contacto', component: ContactosComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registo', component: RegistoComponent },
  { path: 'recover', component: RecuperacaoComponent },
  { path: 'set_password', component: ResetPasswordComponent },
  { path: 'conta', canActivate: [AuthGuard], children: GestaoRoutes },
  { path: 'produtos', component: VerProdutosComponent },
  { path: 'carrinho', component: CarrinhoComponent },
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: 'stepper', component: StepperComponent },
  { path: 'pagamentos', component: ModosPagamentoComponent },
  { path: 'envios', component: EnviosComponent },
  { path: 'garantia', component: Trocas_rmasComponent },
  { path: 'cond-conta-online', component: CondicoesContaOnlineComponent },
  { path: 'politicas', component: PrivacidadeCookiesComponent },
  { path: 'litigios', component: LitigiosComponent },
  { path: 'atuacao', component: AtuacaoComponent },
  { path: 'sobre-nos', component: Sobre_nosComponent },
  { path: 'koffeum-empresas', component: KoffeumEmpresaComponent },
  { path: 'nossas-lojas', component: NossasLojasComponent },
  { path: 'show-equip-cafe', component: MostrarEquipCafeComponent },
  { path: 'search', component: SearchOutputComponent },
  { path: 'show-all-equip', component: MostrarEquipQuenteFrioOutrosComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
