import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Product } from '../_&models/Product';
import { ProductService } from '../_&services/product.service';
import { MediaService } from '../_&services/media.service';
import { Media } from '../_&models/Media';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-_home',
  templateUrl: './_home.component.html',
  styleUrls: ['./_home.component.less']
})
export class _homeComponent implements OnInit {

  constructor(private products: ProductService, private media: MediaService) { }

  prodList: Product[] = [];
  carouselMedia: Media[] = [];
  apiUrl: string = environment.apiUrl;
  ngOnInit() {
    this.products.getAllProductsFromType(1).subscribe((data: any) => {
      data.products.forEach(el => {
        this.prodList.push(new Product(el));
      });
    });
    this.media.getCarouselMediaList().subscribe((data) => {
      this.carouselMedia = data.files;
    });
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    autoplaySpeed:3000,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center:true,
    navSpeed:700,
    navText: ['&nbsp; &nbsp; < &nbsp; &nbsp; ', '&nbsp; &nbsp; > &nbsp; &nbsp; '],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }

}
