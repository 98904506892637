
<!-- Pseudo Jumbotron -->
<div class="p-5 mt-5 mb-5 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
  <div class="d-flex align-items-center justify-content-center">
    <div class="col-auto col-lg-2 hidden-sm">
      <hr>
    </div>
    <div class="col-12 col-lg-2 d-flex justify-content-center">
      <h5> As minhas Encomendas</h5>
    </div>
    <div class="col-auto col-lg-8 hidden-sm">
      <hr>
    </div>
  </div>
  <div class="pt-3 ps-3 pb-5 border-sm-bottom border-md-bottom border-0">
    <div class="pt-3">
      <div class="mt-3">
        <div class="row py-3 align-items-center">
          <div class="col-12 d-flex justify-content-end">
            <div class="col-12 col-md-4 col-lg-3 col-xl-2">
              <div class="form-floating w-100">
                <select class="form-select" [(ngModel)]="pageSize" (change)="remapPageCount()">
                  <option [ngValue]="5">5</option>
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="15">15</option>
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="25">25</option>
                </select>
                <label for="floatingSelect">Mostrar por Página</label>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Nº Encomenda</th>
                <th>Data Encomenda</th>
                <th>Data Envio</th>
                <th>Destinatário</th>
                <th>Total</th>
                <th colspan="2">Estado Encomenda</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of orders">
                <th scope="row"><a class="pointer text-decoration-none text-black" data-bs-toggle="modal" data-bs-target="#encomendaModal">{{item.id}}</a></th>
                <td>{{item.created | date : "yyyy-MM-dd"}}</td>
                <td>{{item.closed ? item.close : 'Por Enviar'}}</td>
                <td>{{item.name}}</td>
                <td>{{calculateOrderTotalValue(item.productList)}}</td>
                <td><span [class]="item.state == 2 ? 'alert alert-success p-1' : ( item.state == 1 ? 'alert alert-warning p-1' : 'alert alert-danger p-1')">{{stateTranslater[item.state]}}</span></td>
                <td><a class="pointer text-decoration-none" (click)="openViewOrder(ViewOrder, item)">Ver</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <pagination color="koffeum" [size]="11" [(pages)]="pageCount" [(current)]="page" (currentChange)="reload()"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ViewOrder let-modal>
  <div class="modal-header">
    <div class="row col-12" *ngIf="order">
      <div class="col-12 col-xl-3 mb-3 mb-xl-0">
        <h5 class="modal-title">Encomenda #{{order.id}}</h5>
      </div>
      <div class="col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0">
        <h6 class="modal-title" id="encomendaModalLabel">Data: {{order.created | date : "dd/MM/yyyy"}}</h6>
      </div>
      <div class="col-12 col-xl-3 d-flex align-items-center">
        <h6 class="modal-title">Estado: <span [class]="order.state == 2 ? 'alert alert-success p-1' : ( order.state == 1 ? 'alert alert-warning p-1' : 'alert alert-danger p-1')">{{stateTranslater[order.state]}}</span></h6>
      </div>
      <div class="col-12 col-xl-3 d-flex align-items-center">
        <button type="button" class="btn-close" (click)="closeViewOrder()"></button>
      </div>

    </div>
  </div>
  <div class="modal-body" *ngIf="order">
    <div class="table-responsive hidden-sm">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Ref</th>
            <th>Item</th>
            <th>Qt.</th>
            <th>Preço</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of order.productList">
            <td>{{getProduct(item.product).id}}</td>
            <td>{{getProduct(item.product).name}}</td>
            <td>{{item.quantity}}</td>
            <td>{{money(getProduct(item.product).price)}}</td>
            <td>{{money(getProduct(item.product).price*item.quantity)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mb-xl-0 hidden-md hidden-lg hidden-xl" *ngFor="let item of order.productList">
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Ref</div>
            {{getProduct(item.product).id}}
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Item</div>
            {{getProduct(item.product).name}}
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Quant.</div>
            {{item.quantity}}
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Preço</div>
            {{money(getProduct(item.product).price)}}
          </div>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Subtotal</div>
            {{money(getProduct(item.product).price*item.quantity)}}
          </div>
        </li>
      </ul>
    </div>
    <div class="bg-light">
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col-12 col-xl-6">
            <ul class="list-group list-group-flush">
              <li class="list-group-item fw-bold bg-light">
                <div class="row">
                  <div class="col">Total Parcial:</div>
                  <div class="col d-flex justify-content-end">{{calculateOrderTotalValue(order.productList)}}</div>
                </div>
              </li>
              <li class="list-group-item fw-bold bg-light">
                <div class="row">
                  <div class="col">Transporte e Processamento:</div>
                  <div class="col d-flex justify-content-end">{{money(3.9)}}</div>
                </div>
              </li>
              <li class="list-group-item bg-light">
                <div class="row">
                  <div class="col">Total (s/IVA):</div>
                  <div class="col d-flex justify-content-end">{{calculateOrderTotalValueWithoutIVA(order.productList)}}</div>
                </div>
              </li>
              <li class="list-group-item fw-bold bg-light">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button collapsed bg-light border  border-1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <div class="row col-12">
                          <div class="col-4">Taxa(s) IVA</div>
                          <div class="col-8 d-flex justify-content-end">{{calculateOrderTotalTax(order.productList)}}</div>
                        </div>
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body p-1 p-md-3 bg-light">
                        <div class="row" *ngFor="let item of order.productList">
                          <div class="col-8 col-xl-4">{{getProduct(item.product).name}}: {{getProduct(item.product).iva*100}}%</div>
                          <div class="col-4 col-xl-8 d-flex justify-content-end">{{money(getProduct(item.product).price*getProduct(item.product).iva*item.quantity)}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item fw-bold h5 bg-light">
                <div class="row">
                  <div class="col">Total da Encomenda:</div>
                  <div class="col d-flex justify-content-end">
                    <span class="text-lc-primary">{{money(calculateOrderTotalValueNumber(order.productList)+3.9)}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light mt-3">
      <div class="row">
        <div class="col-12 d-flex justify-content-around flex-sm-column flex-lg-row">
          <div class="col-12 col-lg-3 col-xl-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bg-light fw-bold">Morada de Faturação</li>
              <li class="list-group-item bg-light">{{order.name}}</li>
              <li class="list-group-item bg-light">{{order.moradaFaturacao.address}}</li>
              <li class="list-group-item bg-light">{{order.moradaFaturacao.postal}}, {{order.moradaFaturacao.locality}}</li>
              <li class="list-group-item bg-light">{{order.moradaFaturacao.pais}}</li>
            </ul>
          </div>
          <div class="col-12 col-lg-3 col-xl-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bg-light fw-bold">Morada de Envio</li>
              <li class="list-group-item bg-light">{{order.name}}</li>
              <li class="list-group-item bg-light">{{order.moradaEnvio.address}}</li>
              <li class="list-group-item bg-light">{{order.moradaEnvio.postal}}, {{order.moradaEnvio.locality}}</li>
              <li class="list-group-item bg-light">{{order.moradaEnvio.pais}}</li>
              <li class="list-group-item bg-light">Tel: {{order.contact}}</li>
            </ul>
          </div>
          <div class="col-12 col-lg-2 col-xl-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bg-light fw-bold">Método Envio</li>
              <li class="list-group-item bg-light">{{order.metodoEnvio}}</li>
            </ul>
          </div>
          <div class="col-12 col-lg-2 col-xl-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bg-light fw-bold">Método Pagamento</li>
              <li class="list-group-item bg-light">{{order.metodoPagamento}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeViewOrder()">Close</button>
  </div>
</ng-template>