export class Morada {

    constructor(item: Morada|void) {
        if(item instanceof Object) {
            this.id = item.id;
            this.userId = item.userId;
            this.address = item.address;
            this.locality = item.locality;
            this.postal = item.postal;
            this.pais = item.pais;
        }
    }

    id: number = -1;
    userId: number = -1;
    address: string = "";
    locality: string = "";
    postal: string = "";
    pais: string = "";
}