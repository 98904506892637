

<div *ngIf="!desktop">
    <app-_navbar *ngIf="!restrictedAccessScreen(link)" ></app-_navbar>
    <router-outlet></router-outlet>
    <app-_footer *ngIf="!restrictedAccessScreen(link)"></app-_footer>
</div>

<div class="col-12" *ngIf="desktop">
    <app-_navbar *ngIf="!restrictedAccessScreen(link)" ></app-_navbar>
</div>
<div class="col-12" style="overflow-y: auto; max-height: calc(100vh - 84px);" *ngIf="desktop && !restrictedAccessScreen(link)">
    <router-outlet></router-outlet>
    <app-_footer *ngIf="!restrictedAccessScreen(link)"></app-_footer>
</div>
<div class="col-12" *ngIf="desktop && restrictedAccessScreen(link)">
    <router-outlet></router-outlet>
</div>