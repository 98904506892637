import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { Product } from '../_&models/Product';
import { ProductSubtype } from '../_&models/ProductSubtype';
import { Media } from '../_&models/Media';
import { MediaService } from '../_&services/media.service';
import { CartService } from '../_&services/cart.service';

@Component({
  selector: 'app-kraft',
  templateUrl: './kraft.component.html',
  styleUrls: ['./kraft.component.less']
})
export class KraftComponent implements OnInit {

  constructor(private products: ProductService, private _media: MediaService, private _cart: CartService) { }

  prodList: Product[] = [];
  subtypesList: ProductSubtype[] = [];

  media: {id: number, media: Media}[] = [];

  cartItems: number[] = [1,1,1];

  prodsProperties: any[] = [];

  ngOnInit() {
    this.products.getAllProductsFromType(1).subscribe((data: any) => {
      data.products.forEach(el => {
        this.prodList.push(new Product(el));
        this._media.getProductsMediaList(el.id).subscribe((data) => {
          this.media.push({
            id: el.id,
            media: data
          });
        });
        this.products.getProductPropertiesFromProductId(el.id).subscribe((data: any[]) => {
          this.prodsProperties.push({
            product: el.id,
            list: data
          });
        });
      });
      data.subtypes.forEach(el => {
        this.subtypesList.push(new ProductSubtype(el));
      });
      setTimeout(() => {
        var urlSplit = location.href.split("#");
        if(urlSplit.length > 0) {
          document.getElementById(urlSplit[1]).scrollIntoView();
        }
      }, 15);
    });
  }

  getCoffeeValue(id: number) {
    if(id < 0 || this.prodsProperties.length == 0 || !this.prodsProperties.find(x => x.product == id))
      return "";
    return this.prodsProperties.find(x => x.product == id).list[0].value;
  }

  translateSubtype(subtype: number) {
    return this.subtypesList.find(x => x.id == subtype).name;
  }

  calculateDiscount(price: number, discount: number) {
    return price*(1-discount);
  }

  getMediaFromID(id: number) {
    try {
      return this.media.find(x => x.id == id).media;
    }catch {
      return null;
    }
  }

  addToCookies(index: number) {
    var cart: any[] = JSON.parse(localStorage.getItem("cart"));
    if(!cart)
      cart = [];
    var item = cart.find(x => x.product == this.prodList[index].id);
    if(item) {
      item.quantity += this.cartItems[index];
    }else{
      cart.push({
        product: this.prodList[index].id,
        quantity: this.cartItems[index]
      });
    }
    localStorage.setItem("cart",JSON.stringify(cart));
    this._cart.triggerCartReload();
  }

  cartControl(index: number) {
    this.cartItems[index] = this.cartItems[index] < 1 ? 1 : this.cartItems[index];
  }
}
