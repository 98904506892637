import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StoreService } from '../_&services/store.service';
import { CollectionPointService } from '../_&services/collectionPoint.service';
import { Utilizadores } from '../_&models/User';
import { Morada } from '../_&models/Morada';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../_&services/user.service';
import { ErrorService } from '../_&services/Error.service';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less']
})
export class StepperComponent implements OnInit, OnChanges {

  creditNumberMask = createMask('9999 9999 9999 9999');
  expireDateMask = createMask<string>({
    alias: 'datetime',
    inputFormat: 'mm/yy'
  });
  securityCode = createMask('999');
  phoneMask = createMask('999 999 999');

  constructor(private _store: StoreService, private _collPoint: CollectionPointService,private _user: UserService, private modal: NgbModal) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.display) {
      this.display = changes.display.currentValue;
    }
  }

  ngOnInit() { 
    this.onPaymentMethodChange();
    this._collPoint.getCollectionPoints().subscribe((data) => {
      this.collectionPoints = data;
    });
    this._store.getStores().subscribe((data) => {
      this.stores = data;
    });
    this._user.getAddress().subscribe((data: Morada[]) => {
      this.addrs = data;
      this._user.getUserData().subscribe((user: Utilizadores) => {
        this.user = user;
        this.shipAddr = this.addrs.find(x => x.id == this.user.defaultShippingAddress);
        this.billAddr = this.addrs.find(x => x.id == this.user.defaultBillingAddress);
        this.deliveryMethod = {
          method: 'courier',
          data: this.shipAddr
        };
      });
    });
  }

  stores: any[] = [];
  collectionPoints: any[] = [];
  shipAddr: Morada = new Morada();
  billAddr: Morada = new Morada();

  localities: string[] = [];

  deliveryMethod: {
    method: 'courier'|'collectionPoint'|'store',
    data: any
  };

  @Input() display: any = {
    one: false,
    two: false,
    three: false,
    four: false
  };

  @Input() payVal: number = 0;

  user: Utilizadores = new Utilizadores();
  addrs: Morada[] = [];

  paymentMethod: 'mbway'|'multibanco'|'credito' = 'credito';
  paymentInfo: any;

  displayEditShippingAddr: boolean = true;
  displayEditBillingAddr: boolean = true;

  @Output() displayChange: EventEmitter<any> = new EventEmitter<any>();

  displayCount(step: number) {
    switch(step) {
      case 0:
        this.display = {
          one: false,
          two: false,
          three: false,
          four: false
        };
      break;
      case 1:
        this.display = {
          one: true,
          two: false,
          three: false,
          four: false
        };
      break;
      case 2:
        this.display = {
          one: true,
          two: true,
          three: false,
          four: false
        };
      break;
      case 3:
        this.display = {
          one: true,
          two: true,
          three: true,
          four: false
        };
      break;
      case 4:
        this.display = {
          one: true,
          two: true,
          three: true,
          four: true
        };
      break;
      default:
        this.display = {
          one: false,
          two: false,
          three: false,
          four: false
        };
      break;
    }
    this.displayChange.emit(this.display);
  }

  closedAt(item) {
    var sab: boolean = item.schedule.sabado.open == '';
    var dom: boolean = item.schedule.domingo.open == '';
    var fer: boolean = item.schedule.feriado.open == '';
    if(sab && dom && fer)
      return "Encerrado aos fins de semana e feriados";
    else if(!sab && dom && fer)
      return "Encerrado aos domingos e feriados";
    else if(sab && !dom && fer)
      return "Encerrado aos sabados e feriados";
    else if(sab && dom && !fer)
      return "Encerrado aos fins de semana";
    else if(!sab && !dom && fer)
      return "Encerrado aos feriados";
    else if(!sab && dom && !fer)
      return "Encerrado aos domingos";
    else if(sab && !dom && !fer)
      return "Encerrado aos sabados";
    return "";
  }

  printScheduleAtDay(item, day: 'Sabado'|'Domingo'|'Feriado') {
    return day+": "+item[day].open+(item[day].lunch!='' ? ", Almoço: "+item[day].lunch : '');
  }

  setMethod(method: 'courier'|'collectionPoint'|'store') {
    switch(method) {
      case 'courier':
        this.deliveryMethod = {
          method: method,
          data: this.shipAddr
        };
      break;
      case 'collectionPoint':
        this.deliveryMethod = {
          method: method,
          data: undefined
        };
      break;
      case 'store':
        this.deliveryMethod = {
          method: method,
          data: undefined
        };
      break;
    }
  }

  translateMethod() {
    switch(this.deliveryMethod.method) {
      case 'courier':
        return "Correios";
      case 'collectionPoint':
        return "Ponto de Recolha";
      case 'store':
        return "Levantamento em Loja";
    }
  }

  alterDeliveryData(item: any) {
    this.deliveryMethod.data = item;
  }

  alterModal: NgbModalRef;
  openModel(content, isShipping = true) {
    this.displayEditShippingAddr = isShipping;
    this.displayEditBillingAddr = !isShipping;
    this.alterModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }

  saveAlterAddr() {
    this.alterModal.close();
  }

  getAddr(id: number) {
    return this.addrs.find(x => x.id == id);
  }

  addAddr: Morada = new Morada();
  codFirst: number;
  codSecond: number;
  addrModal: NgbModalRef;
  isNewAddr: boolean = true;
  openAddrModal(content, addr: number|null = null) {
    if(addr) {
      this.addAddr = this.getAddr(addr);
      this.isNewAddr = false;
    }
    this.addrModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }
  closeAddrModal() {
    this.addrModal.close();
    this.addAddr = new Morada();
    this.codFirst = undefined;
    this.codSecond = undefined;
    this.isNewAddr = true;
  }
  updateCodPos() {
    if(this.codFirst > 9999)
      this.codFirst = 9999;
    if(this.codSecond > 999)
      this.codSecond = 999;
    this.addAddr.postal = this.codFirst+"-"+this.codSecond;
  }
  updateAddr() {
    this._user.insertNewAddress(this.addAddr).subscribe(() => {
      this.closeAddrModal();
      this._user.getAddress().subscribe((data) => {
        this.addrs = data;
      }),(err) => {
        ErrorService.signalError.emit({
          title: "Erro User",
          body: err.error
        });
      };
    }, (err) => {
      ErrorService.signalError.emit({
        title: "Erro ao adicionar nova morada",
        body: err.error
      });
    });
  }

  onPaymentMethodChange() {
    switch(this.paymentMethod) {
      case 'credito':
        this.paymentInfo = {
          "name": "",
          "cardNumber": "",
          "expire": "",
          "securityCode": ""
        };
      break;
      case 'mbway':
        this.paymentInfo = {
          "phone": ""
        };
      break;
      default:
        this.paymentInfo = {};
      break;
    }
  }

  paymentInfoCollected() {
    switch(this.paymentMethod) {
      case 'credito':
        return /^(\d{4} ){3}\d{4}$/.test(this.paymentInfo.cardNumber) && this.paymentInfo.name != "" && /^(0[1-9]{1}|1(0|1|2))\/\d{2}$/.test(this.paymentInfo.expire) && /^\d{3}$/.test(this.paymentInfo.securityCode);
      case 'mbway':
        return /^(\d{3} ){2}\d{3}$/.test(this.paymentInfo.phone);
      case 'multibanco':
        return true;
      default:
        return false;
    }
  }
}
