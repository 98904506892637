<main>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 gx-0 border-start border-end border-bottom">

        <div class="row gx-0">

          <div class="col-12 col-xl-8">
            <div class="p-5 my-14 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
              <div class="d-flex align-items-center justify-content-center mb-3">
                <div class="col-4 col-lg-2 hidden-sm">
                  <hr>
                </div>
                <div class="col d-flex justify-content-center">
                  <h5>Carrinho de Compras</h5>
                </div>
                <div class="col-4 col-lg-6 hidden-sm">
                  <hr>
                </div>
              </div>
              <section class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="d-sm-none d-md-table-cell">Item</th>
                      <th scope="col">Produto</th>
                      <th scope="col">Preço Unitário</th>
                      <th scope="col">Quant.</th>
                      <th scope="col">Sub-total</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of cart">
                      <td class="d-sm-none d-md-table-cell">
                        <img src="https://{{apiUrl}}/api/media/products/{{item.product.id}}" class="img-cart object-contain" alt="" *ngIf="item.product.id">
                      </td>
                      <td class="w-35" *ngIf="item.product.name">{{item.product.name}}</td>
                      <td *ngIf="item.product.price">
                        <span [class.text-decoration-line-through]="item.product.discount > 0">{{item.product.price | money : '€' : '.'}}</span>
                        <span class="bg-orange-600 rounded mx-3" *ngIf="item.product.discount > 0">-{{item.product.discount*100}}%</span>
                        <span *ngIf="item.product.discount > 0">{{(item.product.price * (1-item.product.discount)) | money : '€'}}</span>
                      </td>
                      <td class="w-10">
                        <div class="input-group mb-3">
                          <input type="number" min="1" class="form-control" [(ngModel)]="item.quantity" (change)="item.quantity = item.quantity < 1 ? 1 : item.quantity;">
                        </div>
                      </td>
                      <td>{{((item.product.price * (1-item.product.discount)) * item.quantity) | money : '€'}}</td>
                      <td><span role="button"(click)="removeFromCart(item)"><i class="fad fa-trash text-danger"></i></span></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div class="col-12 col-xl-4 ">
            <div class="p-5 my-14 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 position-sticky top-10">
              <div class="d-flex align-items-center justify-content-center mb-3">
                <div class="col-4 col-lg-2 hidden-sm">
                  <hr>
                </div>
                <div class="col d-flex justify-content-center">
                  <h5>Resumo</h5>
                </div>
                <div class="col-4 col-lg-6 hidden-sm">
                  <hr>
                </div>
              </div>
              <div class="row row-cols-2">
                <div class="col-4">{{cart.length}} Produtos</div>
                <div class="col-8 d-flex justify-content-end pe-14">{{calculateTotalCartValue()}}</div>
                <div class="col-12">
                  <div class="accordion accordion-flush py-3" id="accordionFlushExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          <div class="row col-12">
                            <div class="col-4">Taxa(s)</div>
                            <div class="col-8 d-flex justify-content-end">{{calculateTotalTax()}}</div>
                          </div>
                        </button>
                      </h2>
                      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                          <div class="row">
                            <ng-container *ngFor="let item of ivaList">
                              <div class="col-4">Iva ({{item.iva*100}}%)</div>
                              <div class="col-8 d-flex justify-content-end pe-14">{{item.value | money : '€'}}</div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row row-cols-2 pt-3" *ngIf="false">
                    <div class="col py-2 fw-bold">Total da Encomenda</div>
                    <div class="col d-flex justify-content-end pe-14 py-2 fw-bold">60.012,00&nbsp;€</div>
                  </div>
                  <div class="pt-3">
                    <button class="btn btn-outline-lc-secundary duration-300" (click)="checkout()" [disabled]="cart.length <= 0">Finalizar Encomenda</button>
                  </div>
                </div>              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>