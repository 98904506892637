import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "../_&services/auth.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    
    constructor(private router: Router, private auth: AuthService) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        var token = localStorage.getItem("userId");
        if(token == null || token == undefined) {
            return new Promise<boolean>((resolve, reject) => {
                if(!location.href.includes("login"))
                    location.href = "/?state=Não tem sessão iniciada";
                resolve(false);
            });
        }
        await this.auth.loggedIn().then(() => {
            this.auth.permissions().then((data: any) => {
                var pagePermissions = route.data.permissions;
                var userPermissions = data.result;
                if(userPermissions > pagePermissions) {
                    location.href = "/";
                    return new Promise<boolean>((resolve, reject) => {
                        resolve(false);
                    });
                }
            });
        }, (err) => {
            localStorage.removeItem("userId");
            if(!location.href.includes("login") && err.status == 401)
                location.href = "/?state="+err.error;
            return new Promise<boolean>((resolve, reject) => {
                resolve(false);
            });
        });
        return new Promise<boolean>((resolve, reject) => {
            resolve(true);
        });
    }
}

@Injectable({
    providedIn: "root"
})
export class InverseAuthGuard implements CanActivate {
    
    constructor(private auth: AuthService) {}

    async canActivate(): Promise<boolean> {
        var token = localStorage.getItem("userId");
        if(token == null || token == undefined) {
            return new Promise<boolean>((resolve, reject) => {
                resolve(true);
            });
        }
        await this.auth.loggedIn().then(() => {
            location.href = "/admin/";
            return new Promise<boolean>((resolve, reject) => {
                resolve(false);
            });
        }, () => {
            localStorage.removeItem("userId");
            return new Promise<boolean>((resolve, reject) => {
                resolve(true);
            });
        });
    }
}