<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-2">
        <div class="nav flex-column nav-pills pt-3 position-sticky top-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button class="nav-link active border-bottom border-account rounded-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">A minha Conta</button>
          <button class="nav-link border-bottom border-account rounded-0" id="v-pills-encomenda-tab" data-bs-toggle="pill" data-bs-target="#v-pills-encomenda" type="button" role="tab" aria-controls="v-pills-encomenda" aria-selected="false">As minhas Encomendas</button>
          <button class="nav-link border-bottom border-account rounded-0" id="v-pills-desejo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-desejo" type="button" role="tab" aria-controls="v-pills-desejo" aria-selected="false" *ngIf="false">Os meus Favoritos</button>
          <a class="nav-link border-bottom border-account rounded-0 text-center" *ngIf="userPermissions <= 1" href="/admin">Administração da pagina</a>
        </div>
      </div>
      <!--content-->
      <div class="col-xl-10 border-start">
        <div class="tab-content p-3" id="v-pills-tabContent">
          <!--Cenaas da morada-->
          <section class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <app-ManageAccount [(user)]="user" [(addrs)]="addrs"></app-ManageAccount>
          </section>
          <section class="tab-pane fade" id="v-pills-encomenda" role="tabpanel" aria-labelledby="v-pills-encomenda-tab">
            <app-MyOrders></app-MyOrders>
          </section>
          <!--Cenas das encomendas-->
          <div class="tab-pane fade" id="v-pills-desejo" role="tabpanel" aria-labelledby="v-pills-desejo-tab">
            <div class="p-5 mt-5 mb-5 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
              <div class="d-flex align-items-center justify-content-center">
                <div class="col-auto col-lg-2 hidden-sm">
                  <hr>
                </div>
                <div class="col-12 col-lg-2 d-flex justify-content-center">
                  <h5> Favoritos</h5>
                </div>
                <div class="col-auto col-lg-8 hidden-sm">
                  <hr>
                </div>
              </div>
              <div class="pt-3">
                <div class="mt-3">
                  <div class="row py-3 align-items-center">
                    <div class="col">1 artigo</div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex justify-content-end">
                      <div class="form-floating w-100">
                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                          <option class="w-100" selected value="1">10</option>
                          <option value="2">20</option>
                          <option value="3">50</option>
                        </select>
                        <label for="floatingSelect">Mostrar por Página</label>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-3 shadow">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-xl-3 py-3 d-flex align-items-center justify-content-center mx-md-auto mx-lg-auto">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="img-fluid" alt="...">
                      </div>
                      <div class="col-md-12 col-lg-8 col-xl-7">
                        <div class="card-body">
                          <h5 class="card-title">Fiamma Astrolab 3PP</h5>
                          <p class="card-text text-justify h-lg-331 h-xl-187"><small class="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod velit quibusdam quaerat fugit pariatur sequi iure voluptate quasi ipsum, ullam aliquam, quae animi perspiciatis dolor dicta reiciendis enim, ea exercitationem!
                              Vero amet pariatur molestias numquam corrupti mollitia earum voluptates nesciunt dolorum laborum deserunt soluta cupiditate odio dignissimos fugit reiciendis quos, quisquam tenetur alias consequuntur nobis fuga. Omnis reiciendis sed id.
                              Doloribus, rem consequatur nam quia commodi ut maxime repellendus dolorem odio exercitationem hic minus quos rerum soluta totam aperiam esse placeat id possimus perferendis tempore est pariatur. Quo, magni laboriosam.</small></p>
                          <div class="d-flex justify-content-start">
                            <a href="#" class="btn btn-outline-lc-secundary px-5 d-flex align-items-center"><i class="fad fa-shopping-cart me-3"></i>Comprar</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-4 col-xl-2">
                        <div class="card-body">
                          <h5 class="card-title">Preço</h5>
                          <p class="card-text text-justify h-lg-331 h-xl-187">3.500 €</p>
                          <div class="d-flex justify-content-xl-end">
                            <a href="#" class="btn btn-outline-lc-secundary px-5 d-flex align-items-center"><i class="fal fa-times me-3"></i>Remover</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
