import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-condicoes-conta-online',
  templateUrl: './condicoes-conta-online.component.html',
  styleUrls: ['./condicoes-conta-online.component.css'],
})
export class CondicoesContaOnlineComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
