import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_&services/user.service';
import { ErrorService } from '../../../_&services/Error.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utilizadores } from '../../../_&models/User';
import { Morada } from '../../../_&models/Morada';
import { AuthService } from 'src/app/_&services/auth.service';

@Component({
  selector: 'app-gestao-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  constructor(private _user: UserService, private _auth: AuthService, private modal: NgbModal) { }

  ngOnInit() {
    setTimeout(() => {
      this._auth.permissions().then((data: any) => {
        this.userPermissions = data.result;
      });
      this._user.getUserData().subscribe((data) => {
        this.user = new Utilizadores(data);
      }, (err) => {
        ErrorService.signalError.emit({
          title: "Erro User",
          body: err.error
        });
      });
      this._user.getAddress().subscribe((data) => {
        this.addrs = data;
      }),(err) => {
        ErrorService.signalError.emit({
          title: "Erro User",
          body: err.error
        });
      };
    },5);
  }

  userPermissions: number = 3;
  user: Utilizadores;
  addrs: Morada[] = [];

}
