<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/envios/envio_de_encomendas.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/envios/envio_de_encomendas.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Envio de Encomendas e Portes</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">Envio de Encomendas</h5>
              <p>As datas de entrega previstas são apresentadas na página de cada artigo e consideram envios para Portugal Continental, podendo variar conforme a tipologia de artigo;</p>
              <p>As datas de entrega podem ser condicionadas em situações excecionais por motivos logísticos internos ou operacionais das transportadoras, ou em períodos de especiais como Black Friday e Natal onde existe maior volume de encomendas;</p>
              <p>No caso de grandes eletrodomésticos o valor dos portes de envio varia consoante o peso da mercadoria, não existindo de momento portes grátis nesta tipologia de produto;</p>
              <p>O envio e entrega das encomendas é feito nos dias úteis, ou seja, entre segunda-feira e sexta-feira, desde que não seja feriado nacional ou local, em Leiria ou na localidade da entrega;</p>
              <p>Nas situações em que não exista stock de algum artigo neste local e seja necessária alguma transferência interna, acresce mais 2 a 5 dias úteis aos tempos indicados;</p>
              <p>No momento em que for feito o envio receberá um e-mail de notificação com o nº de seguimento para possa acompanhar todas as etapas do envio até à entrega, através do website das transportadoras que utilizamos;</p>
              <p>Por questões internas logísticas, a sua encomenda poderá ser enviada em múltiplas remessas. Receberá um e-mail por cada envio que for realizado, no qual constam os artigos expedidos nessa remessa;</p>
              <p>Se por algum motivo não conseguir estar presente para receber a sua encomenda, dependendo da transportadora e das dimensões do artigo, poderá suceder uma das seguintes ações:</p>
              <ul class="list-group mb-3">
                <li class="list-group-item border-0 bg-transparent">• Nova tentativa de entrega (normalmente no dia seguinte).</li>
                <li class="list-group-item border-0 bg-transparent">• Deixada notificação na caixa de correio para levantar num ponto de recolha.</li>
                <li class="list-group-item border-0 bg-transparent">• Iniciado processo de devolução à LUIS CARRILHO UNIPESSOAL, Lda. </li>
              </ul>
              <p>No momento em que receber a encomenda deve verificar a integridade dos artigos na presença do estafeta, e recusar a entrega caso existam danos visíveis.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Envios para Portugal Continental</h5>
                <p>Portes grátis para encomendas a partir de <span class="fw-bold">200€</span> e cujo peso da sua mercadoria não ultrapasse os <span class="fw-bold">30kg</span>. A partir dos <span class="fw-bold">31kg</span>, serão cobrados portes com base nos valores indicados na tabela abaixo;</p>
                <p>No caso de envio de encomendas com pagamento a prazo (opção disponível para clientes selecionados via Departamento Corporate LUIS CARRILHO UNIPESSOAL, Lda), os portes grátis não estão disponíveis nesta modalidade;</p>
                <p>Entrega prevista em 2 dias úteis. As encomendas feitas até às 18h00 de um dia útil são habitualmente entregues no dia útil seguinte;</p>
                <p>Entrega prevista em <span class="fw-bold">5-7</span> dias úteis em artigos de grandes dimensões (mais de <span class="fw-bold">40kg ou 1.40m</span>, tais como Máquinas de Café, Moinhos, Depuradores ou Equipamentos de Frio/Quente, Mobiliário etc).</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Envios para Madeira e Porto Santo</h5>
                <p>Serão cobrados portes com base nos valores indicados na tabela abaixo;</p>
                <p>Entrega prevista em <span class="fw-bold">2-3</span> dias úteis em artigos com peso até <span class="fw-bold">20kg</span>;</p>
                <p>Entrega prevista em <span class="fw-bold">6-8</span> dias úteis em artigos com peso superior a <span class="fw-bold">20kg</span>.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Envios para Açores</h5>
                <p>Serão cobrados portes com base nos valores indicados na tabela abaixo;</p>
                <p>Entrega prevista em <span class="fw-bold">3-5</span><span class="fw-bold text-danger">*</span> dias úteis em artigos com peso até <span class="fw-bold">5kg</span>;</p>
                <p>Entrega prevista em <span class="fw-bold">7-16</span><span class="fw-bold text-danger">*</span> dias úteis em artigos com peso superior a <span class="fw-bold">5kg</span>;</p>
                <p>Entrega prevista em <span class="fw-bold">6-8</span> dias úteis em artigos com peso superior a <span class="fw-bold">20kg</span>.</p>
                <p class="fw-bold"><span class="text-danger">*</span>Envios com destino às ilhas do Corvo e Flores podem acrescer 12 dias úteis.</p>
                <p class="fw-bold"><span class="text-danger">*</span>Envios com destino à Graciosa podem acrescer 6 dias úteis.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Levantamento em Loja LUIS CARRILHO UNIPESSOAL, Lda</h5>
                <p>Levantamentos grátis nas lojas LUIS CARRILHO UNIPESSOAL, Lda;</p>
                <p>Artigos em stock na loja pretendida ficam habitualmente disponíveis para levantamento no próprio dia (em dias úteis);</p>
                <p>Artigos "Por encomenda" acrescem <span class="fw-bold">2-3</span> dias úteis por motivo de transferência interna de stock;</p>
                <p>Em ambos os casos deve aguardar a notificação no seu e-mail a indicar o momento a partir do qual a sua encomenda está disponível para levantamento.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">Tabela de Portes</h5>
                <p>Consulte abaixo a tabela de portes de envio praticados Portugal Continental e Ilhas.</p>
              </div>
              <div class="row">
                <div class="col-12 d-flex">
                  <div class="col-4">
                    <div class="table">
                      <thead>
                        <tr style="height: 18px;">
                          <th class="p-3 text-left">Portugal Continental</th>
                          <td class="p-3">&nbsp;</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="height: 18px;">
                          <td class="p-3 fw-bold">Encomendas inferiores a 200€</td>
                          <td class="p-3">&nbsp;</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">3,90€</td>
                          <td class="p-3">0 - 2Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">4,90€</td>
                          <td class="p-3">2 - 5Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">5,90€</td>
                          <td class="p-3">5 - 15Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">7,90€</td>
                          <td class="p-3">15 - 25Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">9,90€</td>
                          <td class="p-3">25 - 30Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">11,90€</td>
                          <td class="p-3">30 - 50Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">17,90€</td>
                          <td class="p-3">50 - 100Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">24,90€</td>
                          <td class="p-3">100 - 150Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">29,90€</td>
                          <td class="p-3">150 - 200Kg</td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr style="height: 18px;">
                          <td class="p-3 fw-bold">Encomendas superiores a 200€</td>
                          <td class="p-3">&nbsp;</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">0,00€</td>
                          <td class="p-3">0 - 2Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">0,00€</td>
                          <td class="p-3">2 - 5Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">0,00€</td>
                          <td class="p-3">5 - 15Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">0,00€</td>
                          <td class="p-3">15 - 25Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">0,00€</td>
                          <td class="p-3">25 - 30Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">11,90€</td>
                          <td class="p-3">30 - 50Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">17,90€</td>
                          <td class="p-3">50 - 100Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">24,90€</td>
                          <td class="p-3">100 - 150Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">29,90€</td>
                          <td class="p-3">150 - 200Kg</td>
                        </tr>
                      </tbody>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="table">
                      <thead>
                        <tr style="height: 18px;">
                          <th class="p-3 text-left">Ilhas</th>
                          <td class="p-3">&nbsp;</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="height: 18px;">
                          <td class="p-3 fw-bold">Todos os métodos de pagamento</td>
                          <td class="p-3">&nbsp;</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">17,00€</td>
                          <td class="p-3">0 - 2Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">21,00€</td>
                          <td class="p-3">2 - 5Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">32,00€</td>
                          <td class="p-3">5 - 15Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">62,00€</td>
                          <td class="p-3">15 - 25Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">75,00€</td>
                          <td class="p-3">25 - 30Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">125,00€</td>
                          <td class="p-3">30 - 50Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">150,00€</td>
                          <td class="p-3">50 - 100Kg</td>
                        </tr>
                        <tr style="height: 18px;">
                          <td class="p-3">200,00€</td>
                          <td class="p-3">100 - 150Kg</td>
                        </tr>
                      </tbody>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center py-4" id="volumes">
                <div class="col-4  hidden-sm">
                  <hr>
                </div>
                <div class="col-auto mx-5 d-flex justify-content-center">
                  <h5>Passos de Entrega para Grandes Volumes na LUIS CARRILHO UNIPESSOAL, Lda</h5>
                </div>
                <div class="col-4 hidden-sm">
                  <hr>
                </div>
              </div>
              <div>
                <h5 class="mb-3">Encomenda</h5>
                <p>Se fez a sua encomenda pelo site www.koffeum.pt, vai receber a confirmação da sua encomenda por email.</p>
              </div>
              <div>
                <h5 class="mb-3">Agendamento</h5>
                <p>Irá receber um contacto por parte da transportadora com o agendamento da entrega da sua encomenda. Deve confirmar todas as medidas do espaço e do trajeto até ao local onde pretende colocar o seu artigo. Caso pretenda a recolha do seu artigo usado, deverá assegurar que o mesmo se encontra sem vestígios de água, gelo ou outras matérias.</p>
              </div>
              <div>
                <h5 class="mb-3">Entrega</h5>
                <p>Quando receber a sua encomenda, verifique que é o artigo correto e se está em perfeitas condições antes de assinar o documento da entrega.</p>
              </div>
              <div>
                <h5 class="mb-3">Onde efetuamos as entregas de Grandes Volumes?</h5>
                <p>Efetuamos entregas em Portugal Continental. O serviço é realizado pela transportadora XPTO. <br>
                  <span class="fw-bold">Atenção:</span> o envio por CTT não contempla para estes casos qualquer serviço de montagem e instalação, sendo a encomenda deixada no R/C ou piso 0 da morada indicada.
                </p>
              </div>
              <div class="mb-4">
                <h5 class="mb-3">Quais os artigos abrangidos?</h5>
                <p>São os Grandes Equipamentos ou qualquer produto cujo peso seja igual ou superior a 30kg. </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>