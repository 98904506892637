import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipamento-moinhos',
  templateUrl: './equipamento-moinhos.component.html',
  styleUrls: ['./equipamento-moinhos.component.less']
})
export class EquipamentoMoinhosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
