<main>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/slideshow/coffee3.png" class="card-img image-height rounded-0" alt="...">
            <div class="card-img-overlay top-50"> 
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/kraft/coffee2.jfif" class="card-img-top rounded-0" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">O Café...</h5>
                <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
              </div>
            </div>
          </div> 
        </section>
        <ng-container *ngFor="let item of prodList; let x = index;">
          <section id="coffee{{item.id}}">   
            <div class="mt-5 p-0 px-sm-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
              <!-- Pseudo Jumbotron -->
              <div class="d-flex align-items-center justify-content-center py-4">
                <div class="col-4  hidden-sm">
                  <hr>
                </div>
                <div class="col d-flex justify-content-center">
                  <h5 class="text-sm-center">{{translateSubtype(item.subTypeProduct)}}</h5>
                </div>
                <div class="col-4 hidden-sm">
                  <hr>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <!--first-->
                  <div class="col-12 col-md-12 col-lg-12">
                    <div class="card rounded-0 border p-3 mb-5">
                      <div class="row g-0">
                        <div class="col-12 col-md-6">
                          <div class="carousel">
                            <ng-container *ngFor="let file of getMediaFromID(item.id); let i = index;">
                              <input type="radio" name="slides" [checked]="i == 0" id="prod{{item.id+file.name}}">
                            </ng-container>
                            <ul class="carousel__slides pt-4">
                              <li class="carousel__slide" *ngFor="let file of getMediaFromID(item.id)">
                                <figure>
                                  <div>
                                    <img src="{{file.file}}" alt="prod{{item.id+file.name}}">
                                  </div>
                                </figure>
                              </li>
                            </ul>
                            <ul class="carousel__thumbnails justify-content-center">
                              <li *ngFor="let file of getMediaFromID(item.id)">
                                <label for="prod{{item.id+file.name}}"><img src="{{file.file}}" alt="" class="object-contain"></label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <h5 class="card-title">{{item.name}}</h5>
                            <div>
                              <p class="card-text text-limit text-justify" *ngFor="let para of item.description.split('\\n')">{{para}}</p>
                              <p class="card-text text-limit text-justify">Ficha Técnica</p>
                            </div>
                          </div>
                          <div class="card-body m-0 pb-0">
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-6">
                                  <p>Embalagem de: <span>{{getCoffeeValue(item.id)}}</span></p>
                                </div>                       
                            </div>
                          </div>
                          <div class="card-body m-0 pt-0">
                            <div class="row">
                              <div class="col-12 col-md-12 col-lg-4">
                                <p>Preço Unitário: <span [class.text-decoration-line-through]="item.discount > 0">{{item.price | money : '€' : '.'}}</span></p>
                              </div>
                              <div class="col-12 col-md-12 col-lg-4" *ngIf="item.discount > 0">
                                <p class="fw-bold">
                                  <span class="badge backgroundColor orange darken-4 py-2">Desconto {{item.discount * 100}}%</span>
                                </p>
                              </div>
                              <div class="col-12 col-md-12 col-lg-4" *ngIf="item.discount > 0">
                                <p>Preço Final: <span class="card-text text-center textColor orange darken-4">{{calculateDiscount(item.price, item.discount) | money : '€' : '.'}}</span></p>
                              </div>
                            </div>
                          </div>
                          <hr class="m-auto w-75 mb-4">
                          <div class="card-body">
                            <div class="row justify-content-center justify-content-lg-end">
                              <div class="col-3 col-md-3 col-lg-2 col-xl-2 col-xxl-1">
                                <i class="fal fa-heart pointer fa-2x text-lc-secundary"></i>
                              </div>
                              <div class="col-9 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                <div class="input-group ">
                                  <button class="btn btn-outline-secondary" type="button" (click)="cartItems[x] = cartItems[x] - (cartItems[x] - 1 < 1 ? 0 : 1);"><i class="fal fa-minus"></i></button>
                                  <input type="number" class="form-control text-center" value="1" min="1" [(ngModel)]="cartItems[x]" (ngModelChange)="cartControl(x)">
                                  <button class="btn btn-outline-secondary" type="button"  (click)="cartItems[x] = cartItems[x] + 1;"><i class="fal fa-plus"></i></button>
                                </div>
                              </div>
                              <div class="col-12 col-lg-6 col-xxl-6 d-flex justify-content-center mt-3 mt-lg-0">
                                <button class="btn btn-outline-lc-secundary duration-300 w-75" (click)="addToCookies(x)">Adicionar ao <i class="fad fa-shopping-cart"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </div>
</main>