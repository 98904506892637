import { Component, OnInit } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { VerifyEmailPipe } from '../_&pipe/verify.pipe';
import { EmailService } from '../_&services/email.service';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.less']
})
export class ContactosComponent implements OnInit {

  phoneMask = createMask('999 999 999');

  constructor(private _email: EmailService) { }

  ngOnInit() {
  }

  nome: string = "";
  nomeError: string = "";

  sobrenome: string = "";
  sobrenomeError: string = "";

  phone: string = "";
  phoneError: string = "";

  email: string = "";
  emailError: string = "";

  subject: string = "";
  subjectError: string = "";

  message: string = "";
  messageError: string = "";

  sendEmail() {
    this.verifyNome();
    this.verifySobrenome();
    this.verifyEmail();
    this.verifyPhone();
    this.verifySubject();
    this.verifyMessage();
      
  }

  verifyNome() {
    this.nomeError = "";
    if(this.nome == "") {
      this.nomeError = "O nome não pode estar em branco";
    }else if(this.nome.length < 3) {
      this.nomeError = "O nome tem que ter mais do que 3 caracteres";
    }    
  }

  verifySobrenome() {
    this.sobrenomeError = "";
    if(this.sobrenome == "") {
      this.sobrenomeError = "O sobrenome não pode estar em branco";
    }else if(this.sobrenome.length < 3) {
      this.sobrenomeError = "O sobrenome tem que ter mais do que 3 caracteres";
    }  
  }

  verifyPhone() {
    this.phoneError = "";
    if(this.phone == "") {
      this.phoneError = "O numero de telefone não pode estar em branco";
    }else if(!/\d\d\d \d\d\d \d\d\d/g.test(this.phone)) {
      this.phoneError = "O numero de telefone tem que ser completo";
    }  
  }

  verifyEmail() {
    this.emailError = "";
    if(!VerifyEmailPipe.transform(this.email)) {
      this.emailError = "O email não é valido";
    }
  }

  verifySubject() {
    this.subjectError = "";
    if(this.subject == "") {
      this.subjectError = "O assunto não pode estar em branco";
    }else if(this.subject.length < 3) {
      this.subjectError = "O assunto tem que ter mais do que 3 caracteres";
    }
  }

  verifyMessage() {
    this.messageError = "";
    if(this.message == "") {
      this.messageError = "A mensagem não pode estar em branco";
    }else if(this.message.length < 3) {
      this.messageError = "A mensagem tem que ter mais do que 3 caracteres";
    }
  }
}
