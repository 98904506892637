<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/litigios/litigios_online.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/litigios/litigios_online.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5> Resolução de Litígios Online</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">A segurança dos seus dados</h5>
              <p>Compre com total confiança e transparência.</p>
              <p>A União Europeia criou um site para apoiar os consumidores na apresentação das suas reclamações sobre qualquer litígio em que estejam envolvidos. Neste âmbito, a LUIS CARRILHO UNIPESSOAL, Lda disponibiliza toda a informação para que possa exercer o seu direito de reclamação junto de uma entidade oficial, terceira e imparcial ao processo. Assim, se ficou insatisfeito com a aquisição de um bem ou serviço no nosso site, ou com a solução por nós apresentada para resolver a situação, pode aceder a este site oficial <a href="http://www.ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a> e expor a sua contestação.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">O Que é a Resolução Alternativa de Litígios?</h5>
                <p>A resolução alternativa de litígios é a possibilidade que todos os consumidores têm ao seu dispor de recorrer e entidades oficiais que os ajudem na resolução, ou orientação de algum conflito, antes de abrirem processos litigiosos nos Tribunais. Regra geral, o procedimento é o seguinte: o cliente pede a um terceiro imparcial que intervenha como intermediário entre si e o comerciante que é o alvo da sua reclamação. O intermediário pode sugerir uma solução para a sua reclamação, impor uma solução a ambas as partes ou reunir as partes para encontrar uma solução.</p>
                <p>Talvez conheça o conceito de resolução alternativa de litígios por outro nome: 'mediação', 'conciliação', 'arbitragem' ou 'comissão competente no âmbito dos litígios de consumo'.</p>
                <p>A resolução alternativa de litígios é, por norma, menos dispendiosa, menos formal e mais rápida do que a via judicial.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda disponibiliza os sites de algumas dessas entidades que poderão ajudá-lo a apresentar os seus argumentos:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">
                    NIACC – Centro Nacional de Informação e Arbitragem de Conflitos de Consumo
                    <ul class="list-group">
                      <li class="list-group-item border-0 bg-transparent"><a href="http://www.cniacc.pt/pt/" target="_blank" rel="noopener noreferrer">https://www.cniacc.pt/pt/</a></li>
                    </ul>
                  </li>
                  <li class="list-group-item border-0 bg-transparent">
                    Centro de Arbitragem de Conflitos de Consumo de Lisboa
                    <ul class="list-group">
                      <li class="list-group-item border-0 bg-transparent"><a href="http://www.centroarbitragemlisboa.pt/" target="_blank" rel="noopener noreferrer">http://www.centroarbitragemlisboa.pt/</a></li>
                    </ul>
                  </li>
                  <li class="list-group-item border-0 bg-transparent">
                    CIAB – Centro de Informação, Mediação e Arbitragem de Consumo (Tribunal Arbitral de Consumo)
                    <ul class="list-group">
                      <li class="list-group-item border-0 bg-transparent"><a href="http://www.ciab.pt/pt/" target="_blank" rel="noopener noreferrer">http://www.ciab.pt/pt/</a></li>
                    </ul>
                  </li>
                  <li class="list-group-item border-0 bg-transparent">
                    CIMPAS – Centro de Informação, Mediação e Provedoria de Seguros
                    <ul class="list-group">
                      <li class="list-group-item border-0 bg-transparent"><a href="https://www.cimpas.pt/" target="_blank" rel="noopener noreferrer">https://www.cimpas.pt/</a></li>
                    </ul>
                  </li>
                  <li class="list-group-item border-0 bg-transparent">
                    CICAP - Tribunal Arbitral de Consumo
                    <ul class="list-group">
                      <li class="list-group-item border-0 bg-transparent"><a href="https://www.cicap.pt/" target="_blank" rel="noopener noreferrer">https://www.cicap.pt/</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>