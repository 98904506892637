<div class="container">
  <div class="row">
    <div class="col-12 border-start border-end border-bottom">
      <ng-container *ngFor="let item of products; let x = index;">
        <section id="coffee{{item.id}}">   
          <div class="mt-5 p-0 px-sm-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <div class="container">
              <div class="row">
                <!--first-->
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="card rounded-0 border p-3 my-3">
                    <div class="row g-0">
                      <div class="col-12 col-md-6">
                        <div class="carousel">
                          <ng-container *ngFor="let file of getMediaFromID(item.id); let i = index;">
                            <input type="radio" name="slides" [checked]="i == 0" id="prod{{item.id+file.name}}">
                          </ng-container>
                          <ul class="carousel__slides pt-4">
                            <li class="carousel__slide" *ngFor="let file of getMediaFromID(item.id)">
                              <figure>
                                <div>
                                  <img src="{{file.file}}" alt="prod{{item.id+file.name}}">
                                </div>
                              </figure>
                            </li>
                          </ul>
                          <ul class="carousel__thumbnails justify-content-center">
                            <li *ngFor="let file of getMediaFromID(item.id)">
                              <label for="prod{{item.id+file.name}}"><img src="{{file.file}}" alt="" class="object-contain"></label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">{{item.name}}</h5>
                          <div>
                            <p class="card-text text-limit text-justify" *ngFor="let para of item.description.split('\\n')">{{para}}</p>
                            <p class="card-text text-limit text-justify">Ficha Técnica</p>
                          </div>
                        </div>
                        <div class="card-body m-0 pb-0">
                          <div class="row">
                              <div class="col-12 col-md-12 col-lg-6" *ngFor="let pItem of getPropertiesOfId(item.id)">
                                <p>{{pItem.description}}: <span>{{pItem.value}}</span></p>
                              </div>                       
                          </div>
                        </div>
                        <button type="button" class="btn btn-outline-koffeum" (click)="redirect(item)">Ver Mais - redirect({{item.subTypeProduct}}, {{item.id}}) </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
      <div class="mb-3">
        <pagination color="koffeum" [(current)]="page" [(pages)]="pages" (currentChange)="retrievePages()"></pagination>
      </div>
    </div>
  </div>
</div>
