import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../_&services/auth.service';
import { ErrorService } from '../_&services/Error.service';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.less']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute, private _auth: AuthService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['data'];
    })
  }

  senhaVisivel1 = false;
  senhaVisivel2 = false;

  toggleSenhaVisivel(input: number) {
    if (input === 1) {
      this.senhaVisivel1 = !this.senhaVisivel1;
    } else if (input === 2) {
      this.senhaVisivel2 = !this.senhaVisivel2;
    }
  }

  password: string = "";
  passwordError: string = "";

  cPassword: string = "";
  cPasswordError: string = "";

  token: string = "";

  verifyPassword() {
    this.passwordError = "";
    if(this.password.length < 8) {
      this.passwordError = "A password tem que conter pelo menos 8 caracteres";
    }else if(!/[A-Z]/.test(this.password)) {
      this.passwordError = "A password tem que ter pelo menos 1 caracter maiusculo";
    }
  }

  verifyCPassword() {
    this.cPasswordError = "";
    if(this.password != this.cPassword) 
      this.cPasswordError = "As password tem que ser iguais";
  }

  sendPassword() {
    this.verifyPassword();
    this.verifyCPassword();
    if(this.cPasswordError == "" && this.passwordError == "") {
      this._auth.resetPassword(this.token, this.password).subscribe(() => {
        location.href = "/";
      }, (err) => {
        ErrorService.signalError.emit({
          title: err.error,
          showBody: false
        });
      })
    }
  }

}
