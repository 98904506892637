import { Component, OnInit } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { MoneyPipe } from '../_&pipe/money.pipe';
import { environment } from 'src/environments/environment';
import { Product } from '../_&models/Product';
import { UserService } from '../_&services/user.service';
import { Morada } from '../_&models/Morada';
import { Utilizadores } from '../_&models/User';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.less']
})
export class CheckoutComponent implements OnInit {

  constructor(private _prod: ProductService) { }

  ngOnInit() {
    this.cart = JSON.parse(localStorage.getItem("cart"));
    var list: number[] = [];
    this.cart.forEach((el) => {
      list.push(el.product);
    });
    this._prod.getCartProducts(list).subscribe((data: Product[]) => {
      this.cart.forEach((el) => {
        el.product = data.find(x => x.id == el.product);
      });
      this.paymentValue = this.calculateTotalCartValue();
    });
  }

  calculateTotalCartValue() {
    var result = 0;
    this.cart.forEach(item => {
      result += ((item.product.price * (1-item.product.discount)) * item.quantity)
    });
    return result;
  }

  countTotalItemsInCart() {
    var result = 0;
    this.cart.forEach(el => {
      result += el.quantity;
    });
    return result;
  }

  listAddr: Morada[] = [];
  user: Utilizadores = new Utilizadores();

  apiUrl: string = environment.apiUrl;
  cart: any[] = [];
  money: MoneyPipe = new MoneyPipe();
  ivaList: any[] = [];

  shippingCost: number = 156.6;
  
  display: any = {
    one: false,
    two: false,
    three: false,
    four: false
  };

  acceptTerms: boolean = false;

  paymentValue: number = 0;

  displayCount(step: number) {
    switch(step) {
      case 0:
        this.display = {
          one: false,
          two: false,
          three: false,
          four: false
        };
      break;
      case 1:
        this.display = {
          one: true,
          two: false,
          three: false,
          four: false
        };
      break;
      case 2:
        this.display = {
          one: true,
          two: true,
          three: false,
          four: false
        };
      break;
      case 3:
        this.display = {
          one: true,
          two: true,
          three: true,
          four: false
        };
      break;
      case 4:
        this.display = {
          one: true,
          two: true,
          three: true,
          four: true
        };
      break;
      default:
        this.display = {
          one: false,
          two: false,
          three: false,
          four: false
        };
      break;
    }
  }

}
