import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

trigger = new Subject<boolean>();

constructor() { }

triggerCartReload() {
  this.trigger.next(true);
}

get events$() {
  return this.trigger.asObservable();
}

}
