import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_&services/auth.service';
import { VerifyEmailPipe, VerifyNIFPipe } from '../_&pipe/verify.pipe';
import { createMask } from '@ngneat/input-mask';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '../_&services/Error.service';
import { Login } from '../_&models/Login';

@Component({
  selector: 'app-registo',
  templateUrl: './registo.component.html',
  styleUrls: ['./registo.component.less']
})
export class RegistoComponent implements OnInit {

  nifMask = createMask('999 999 999');

  constructor(private _auth: AuthService, private _modal: NgbModal) { }

  senhaVisivel1 = false;
  senhaVisivel2 = false;

  name: string = "";
  nameError: string = "";

  lastName: string = "";
  lastNameError: string = "";

  nif: any = "";
  nifError: string = "";

  email:string = "";
  emailError: string = "";

  password: string = "";
  passwordError: string = "";

  cPassword: string = "";
  cPasswordError: string = "";

  terms: boolean = false;
  termsError: string = "";

  toggleSenhaVisivel(input: number) {
    if (input === 1) {
      this.senhaVisivel1 = !this.senhaVisivel1;
    } else if (input === 2) {
      this.senhaVisivel2 = !this.senhaVisivel2;
    }
  }

  ngOnInit() {
  }

  register() {
    this.verifyNif();
    this.verifyName();
    this.verifyLastName();
    this.verifyEmail();
    this.verifyPassword();
    this.verifyCPassword();
    this.verifyTerms();
    if(this.nameError == "" && this.lastNameError == "" && this.nifError == "" && this.emailError == "" && this.passwordError == "" && this.cPasswordError == "" && this.termsError == ""){
      this._auth.register({
        name: this.name+" "+this.lastName,
        email: this.email,
        nif: this.nif,
        password: this.password
      }).subscribe(() => {
        this._auth.submitLogin({email: this.email, password: this.password}).subscribe(() => {
          location.href = "/";
        }, (err) => {
          ErrorService.signalError.emit({
            title: err.error,
            showBody: false
          });
        });
      }, (err) => {
        ErrorService.signalError.emit({
          title: err.error,
          showBody: false
        });
      });
    }
  }

  verifyName() {
    this.nameError = "";
    if(this.name == "") {
      this.nameError = "O nome não pode estar em branco";
    }else if(this.name.length < 3) {
      this.nameError = "O nome tem que ter mais do que 3 caracteres";
    }
  }

  verifyLastName() {
    this.lastNameError = "";
    if(this.lastName == "") {
      this.lastNameError = "O apelido não pode estar em branco";
    }else if(this.lastName.length < 3) {
      this.lastNameError = "O apelido tem que ter mais do que 3 caracteres";
    }
  }

  verifyNif() {
    this.nifError = "";    
    this._auth.verifyNif(this.nif, 1).subscribe((data) => {
      this.nifError = "";
    }, (err) => {
      this.nifError = err.error;
    });
  }

  verifyEmail() {
    this.emailError = "";
    this._auth.verifyEmail(this.email, 0).subscribe(() => {
      if(!VerifyEmailPipe.transform(this.email)) {
        this.emailError = "O email não é valido";
      }
    }, (err) => {
      this.emailError = err.error;
    })
  }

  verifyPassword() {
    this.passwordError = "";
    if(this.password.length < 8) {
      this.passwordError = "A password tem que conter pelo menos 8 caracteres";
    }else if(!/[A-Z]/.test(this.password)) {
      this.passwordError = "A password tem que ter pelo menos 1 caracter maiusculo";
    }
  }

  verifyCPassword() {
    this.cPasswordError = "";
    if(this.password != this.cPassword) 
      this.cPasswordError = "As password tem que ser iguais";
  }

  verifyTerms() {
    this.termsError = this.terms ? "" : "Só se pode registar se aceitar os Termos e Condições e Politica de Privacidade";
  }

  modal: NgbModalRef;
  open(content) {
		this.modal = this._modal.open(content, {
      backdrop: 'static',
      windowClass: 'modal-koffeum',
      size: 'xl',
      centered: true
    });
	}
}
