<main>
  <div class="container-fluid">
    <div class="row">
      <!-- Carrousel -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <div id="carouselExampleIndicators" class="carousel slide carousel-fade d-none d-md-block" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <ng-container *ngFor="let item of carouselMedia; let i = index;">
              <button type="button" data-bs-target="#carouselExampleIndicators" [attr.data-bs-slide-to]="i" [class.active]="i == 0" aria-current="{{i == 0 ? 'true' : 'false'}}" aria-label="Slide {{i+1}}"></button>
            </ng-container>
          </div>
          <!--Imagens 1920x500-->
          <div class="carousel-inner">
            <ng-container *ngFor="let item of carouselMedia; let i = index;">
              <div class="carousel-item justify-content-center" [class.active]="i == 0">
                <img src="{{item.file}}" class="img-fluid" alt="{{item.name}}">
              </div>
            </ng-container>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <!-- Imagem Estática Carrousel -->
        <div id="imgMobile" class="d-block d-md-none">
          <img src="https://{{apiUrl}}/api/media/carousel/mobile" class="img-mobile" alt="mobile-carousel-img">
        </div>
        <!-- Sub-categorias -->
        <section>
          <!--product cards-->
          <div class="p-5 mb-4 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Cafés</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container py-5">
              <div class="row">
                <!--first-->
                <section class="">
                  <div class="row d-flex justify-content-center align-items-center mx-auto text-center">
                    <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand" *ngFor="let item of prodList">
                      <a href="/kraft#coffee{{item.id}}" class="text-lc-secundary text-decoration-none zoomIn duration-500">
                        <div class="card align-items-center shadow-sm">
                          <img src="../../assets/images/sub-categoria/coffee.png" class="pt-2 mt-4" alt="...">
                          <div class="card-body">
                            <h6 class="card-title sub-h">{{item.name}}</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <!-- Sub-categorias -->
        <section>
          <!--product cards-->
          <div class="p-5 mb-4 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Equipamentos</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container py-5">
              <div class="row">
                <!--first-->
                <section class="">
                  <div class="row d-flex justify-content-center align-items-center mx-auto text-center">
                    <!--first-->
                    <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
                      <a href="" class="text-lc-secundary text-decoration-none zoomIn duration-500">
                        <div class="card align-items-center shadow-sm">
                          <img src="../../assets/images/sub-categoria/coffee.png" class="pt-2 mt-4" alt="...">
                          <div class="card-body">
                            <h6 class="card-title sub-h">Café</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!--second-->
                    <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
                      <a href="" class="text-lc-secundary text-decoration-none zoomIn duration-500">
                        <div class="card align-items-center shadow-sm">
                          <img src="../../assets/images/sub-categoria/coffeeMachine.png" class="pt-2 mt-4" alt="...">
                          <div class="card-body">
                            <h6 class="card-title sub-h">Frio</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!--third-->
                    <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
                      <a href="" class="text-lc-secundary text-decoration-none zoomIn duration-500">
                        <div class="card align-items-center shadow-sm">
                          <img src="../../assets/images/sub-categoria/coffeeGrinder.png" class="pt-2 mt-4" alt="...">
                          <div class="card-body">
                            <h6 class="card-title sub-h">Quente</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
                      <a href="" class="text-lc-secundary text-decoration-none zoomIn duration-500">
                        <div class="card align-items-center shadow-sm">
                          <img src="../../assets/images/sub-categoria/coffee.png" class="pt-2 mt-4" alt="...">
                          <div class="card-body">
                            <h6 class="card-title sub-h">Outros</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section>
          <!--Brand Slide-->
          <div class="mt-20 mb-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <div class="container">
              <div class="row m-0">
                <div class="col-md-12 py-5">
                  <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                      <a href="">
                        <div class="slide d-flex justify-content-center">
                          <div class="col-10 border brand-radius">
                            <img src="../../assets/images/brands/koffeum.png" class="img-fluid" alt="img 1">
                          </div>
                        </div>
                      </a>
                    </ng-template>
                    <ng-template carouselSlide>
                      <a href="">
                        <div class="slide d-flex justify-content-center">
                          <div class="col-10 border brand-radius">
                            <img src="../../assets/images/brands/fiamma_white@2x.png" class="img-fluid" alt="img 2">
                          </div>
                        </div>
                      </a>
                    </ng-template>
                    <ng-template carouselSlide>
                      <a href="">
                        <div class="slide d-flex justify-content-center">
                          <div class="col-10 border brand-radius">
                            <img src="../../assets/images/brands/futurete.png" class="img-fluid" alt="img 3">
                          </div>
                        </div>
                      </a>
                    </ng-template>
                    <ng-template carouselSlide>
                      <a href="">
                        <div class="slide d-flex justify-content-center">
                          <div class="col-10 border brand-radius">
                            <img src="../../assets/images/brands/saeco.png" class="img-fluid" alt="img 4">
                          </div>
                        </div>
                      </a>
                    </ng-template>
                    <ng-template carouselSlide>
                      <a href="">
                        <div class="slide d-flex justify-content-center">
                          <div class="col-10 border brand-radius">
                            <img src="../../assets/images/brands/zumex.png" class="img-fluid" alt="img 5">
                          </div>
                        </div>
                      </a>
                    </ng-template>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
          </div>
        </section><!-- End brand slide -->
      </div>
    </div>
  </div>
</main>