import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../_&models/Product';
import { ProductService } from '../_&services/product.service';
import { ProductSubtype } from '../_&models/ProductSubtype';
import { Media } from '../_&models/Media';
import { MediaService } from '../_&services/media.service';

@Component({
  selector: 'app-SearchOutput',
  templateUrl: './SearchOutput.component.html',
  styleUrls: ['./SearchOutput.component.less']
})
export class SearchOutputComponent implements OnInit {
  
  page: number = 1;
  pages: number = 1;
  pageSize: number = 3;
  products: Product[] = [];
  subtypesList: ProductSubtype[] = [];

  media: {id: number, media: Media}[] = [];

  prodsProperties: any[] = [];

  constructor(private route: ActivatedRoute, private _prod: ProductService, private _media: MediaService) { }

  ngOnInit() {
    this._prod.getProductsSubTypeCompleteList().subscribe((data: ProductSubtype[]) => {
      this.subtypesList = data;
    });
    this.retrievePages();
  }

  redirect(item: Product) {
    let sub = this.subtypesList.find(x => x.id == item.subTypeProduct);
    switch(sub.typeProducts) {
      case 1:
        location.href = `kraft#coffee${item.id}`;
      break;
      default:
        location.href = `produtos?id=${item.id}`;
    }
  }

  retrievePages() {
    this.prodsProperties = [];
    this.products = [];
    this.media = [];
    this.route.queryParams.subscribe(params => {
      this._prod.getQuerySearchPageCount(params['query'], this.pageSize).subscribe((data) => {
        this.pages = data;
        this._prod.getQuerySearchProducts(params['query'], this.page, this.pageSize).subscribe((data) => {
          this.products = data;
          this.products.forEach(el => {
            this._media.getProductsMediaList(el.id).subscribe((data) => {
              this.media.push({
                id: el.id,
                media: data
              });
            });
            this._prod.getProductPropertiesFromProductId(el.id).subscribe((data: any) => {
              data.forEach(el => {
                this.prodsProperties.push(el);
              });
              this.prodsProperties = this.prodsProperties.filter((value, index, self) => index === self.findIndex((t) => (
                t.codename === value.codename && t.productId === value.productId
              )));
            });
          })
        });
      });
    })
  }

  translateSubtype(subtype: number) {
    return this.subtypesList.find(x => x.id == subtype).name;
  }

  getMediaFromID(id: number) {
    try {
      return this.media.find(x => x.id == id).media;
    }catch {
      return null;
    }
  }

  getPropertiesOfId(id: number) {
    return this.prodsProperties.filter(x => x.productId == id);
  }

  /*getCoffeeValue(id: number) {
    if(id < 0 || this.prodsProperties.length == 0 || !this.prodsProperties.find(x => x.product == id))
      return "";
    return this.prodsProperties.find(x => x.product == id).list[0].value;
  }*/

}
