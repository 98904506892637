<section class="container-fluid back-login gx-0">
  <div class="row m-0 align-middle justify-content-center align-items-center pt-60 pt-sm-44 opacity-90">
    <!-- First -->
    <div class="col-12 col-md-7 col-lg-6 col-xl-4 col-xxl-4">
      <div class="card rounded shadow">
        <div class="p-5">
          <div class="d-flex align-items-center justify-content-center mb-4">
            <div class="col-4 col-lg-2 hidden-sm">
              <hr>
            </div>
            <div class="col d-flex justify-content-center">
              <h5 class="fst-italic">Login</h5>
            </div>
            <div class="col hidden-sm">
              <hr>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" required [(ngModel)]="email" (keydown.enter)="login()">
          </div>
          <div class="mb-3">
            <label class="form-label">Password</label>
            <input type="password" class="form-control" required [(ngModel)]="password" (keydown.enter)="login()">
          </div>
          <button type="button" class="btn btn-outline-lc-secundary duration-500 me-3" (click)="login()">Entrar</button>
          <a href="/" class="btn btn-outline-danger duration-500">Voltar atras</a>
          <div class="form-text mt-3 text-center">Se não possui uma conta, pode criar uma <a role="button" class="duration-500 link-koffeum" (click)="register()">aqui</a></div>
          <div class="mt-3 text-center">
            <a role="button" class="link-koffeum" (click)="recover()">Esqueceu-se da sua palavra passe?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>