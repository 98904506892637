<section class="container-fluid back-login gx-0">
  <div class="row m-0 align-middle justify-content-center align-items-center pt-40 pt-sm-10 pt-md-28 opacity-90">
    <!-- First -->
    <div class="col-12 col-md-7 col-lg-6 col-xl-5">
      <div class="card rounded shadow">
        <div class="p-5">
          <div class="d-flex align-items-center justify-content-center mb-4">
            <div class="col-4 col-lg-2 hidden-sm">
              <hr>
            </div>
            <div class="col d-flex justify-content-center">
              <h5 class="fst-italic">Registo</h5>
            </div>
            <div class="col hidden-sm">
              <hr>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label for="validationCustomName" class="form-label">Nome</label>
              <input type="text" class="form-control" [class.border-danger]="nameError != ''" [(ngModel)]="name">
              <div class="invalid-feedback" [class.d-block]="nameError != ''">
                {{nameError}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="validationCustomSurname" class="form-label">Apelido</label>
              <input type="text" class="form-control" [class.border-danger]="lastNameError != ''" [(ngModel)]="lastName">
              <div class="invalid-feedback" [class.d-block]="lastNameError != ''">
                {{lastNameError}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="nif" class="form-label">NIF</label>
              <input type="text" class="form-control" [class.border-danger]="nifError != ''" [inputMask]="nifMask" [(ngModel)]="nif" >
              <div class="invalid-feedback" [class.d-block]="nifError != ''">
                {{nifError}}
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email</label>
            <input type="email" class="form-control" [class.border-danger]="emailError != ''" [(ngModel)]="email">
            <div class="invalid-feedback" [class.d-block]="emailError != ''">
              {{emailError}}
            </div>
          </div>
          <div>
            <label for="exampleInputPassword1" class="form-label">Password</label>
            <div class="input-group mb-3">
              <input [type]="senhaVisivel1 ? 'text' : 'password'" class="form-control" [class.border-danger]="passwordError != ''" [(ngModel)]="password">
              <button class="btn btn-outline-koffeum duration-500 rounded-end" type="button" (click)="toggleSenhaVisivel(1)">
                <svg class="svg-inline--fa fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" *ngIf="senhaVisivel1"></path>
                  <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" *ngIf="!senhaVisivel1"></path>
                </svg>
              </button>
              <div class="invalid-feedback" [class.d-block]="passwordError != ''">
                {{passwordError}}
              </div>
            </div>
          </div>
          
          <div>
            <label for="exampleInputPassword2" class="form-label">Confirme a Password</label>
            <div class="input-group mb-3">
              <input [type]="senhaVisivel2 ? 'text' : 'password'" class="form-control" [class.border-danger]="cPasswordError != ''" [(ngModel)]="cPassword">
              <button class="btn btn-outline-koffeum duration-500 rounded-end" type="button" (click)="toggleSenhaVisivel(2)">
                <svg class="svg-inline--fa fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" *ngIf="senhaVisivel2"></path>
                  <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" *ngIf="!senhaVisivel2"></path>
                </svg>
              </button>
              <div class="invalid-feedback" [class.d-block]="cPasswordError != ''">
                {{cPasswordError}}
              </div>
            </div>
          </div>
          
          <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input pointer" [class.border-danger]="termsError != ''" [(ngModel)]="terms">
            <label class="form-check-label text-justify-form">Li e aceito a <a href="javascript: void(0);" (click)="open(privacidade)">Política de Privacidade</a> assim como os <a href="javascript: void(0);" (click)="open(condicoes)">Termos e Condições</a> e confirmo que tenho mais de 16 anos de idade.</label>
          </div>
          <button type="button" class="btn btn-outline-koffeum duration-500 me-3" (click)="register()">Registar</button>
          <a href="/" class="btn btn-outline-danger duration-500">Voltar para tras</a>
          <div class="invalid-feedback" [class.d-block]="termsError != ''">
            {{termsError}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #privacidade let-model>
  <div class="modal-header">
    <h4 class="modal-title">Politica de Privacidade</h4>
    <a type="button" class="btn border-none text-koffeum fs-2" aria-label="Close" (click)="modal.dismiss('exit')"><i class="far fa-times-circle"></i></a>
  </div>
  <div class="modal-body modal-overflow">
    <app-privacidade-cookies></app-privacidade-cookies>
  </div>
</ng-template>
<ng-template #condicoes let-model>
  <div class="modal-header">
    <h4 class="modal-title">Politica de Privacidade</h4>
    <a type="button" class="btn border-none text-koffeum fs-2" aria-label="Close" (click)="modal.dismiss('exit')"><i class="far fa-times-circle"></i></a>
  </div>
  <div class="modal-body modal-overflow">
    <app-condicoes-conta-online></app-condicoes-conta-online>
  </div>
</ng-template>