<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/contactos/contactos.png" class="card-img rounded-0" alt="...">
            <div class="card-img-overlay">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md text-shadow">Contactos</h1>
            </div>
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/contactos/contactosMobile.png" class="card-img-top rounded-0" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">Contactos</h5>
              </div>
            </div>
          </div>
        </section>
        <!-- Contactos texto -->
        <section class="mb-10">
          <!-- Mais vendido / procurado -->
          <div class="p-5 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center mb-4">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="fst-italic">Contactos</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row justify-content-center">
              <!-- First -->
              <div class="col-12 col-xl-9">
                <div class="card rounded shadow">
                  <div class="row g-0">
                    <div class="col-12">
                      <div class="card-body py-5">
                        <div class="row">
                          <div class="col-12 col-lg-5 col-xl-5">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <i class="fal fa-map-marker-alt"></i>
                                Rua Pedro de Santarém Nº33, 4º Dto <br class="hidden-sm"> <span class="ms-0 ms-md-3">2000-223, Santarém</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-12 col-lg-3 col-xl-3">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item"><i class="fal fa-envelope-open-text"></i>
                                <span class="ms-3"><a href="mailto:kcafept@gmail.com" class="text-decoration-none text-dark">kcafept@gmail.com</a></span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-12 col-lg-4 col-xl-4">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <i class="fal fa-phone-alt"></i>
                                <span class="ms-md-3"><a href="tel:+351912298450" class="text-decoration-none text-dark">(+351) 912 298 450</a></span><br>
                                <span class="ms-md-4"><small>Chamada para Rede Móvel Nacional</small></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="" id="contact"></a>
        </section>
        <!-- Formulário -->
        <section class="mb-10">
          <!-- Mais vendido / procurado -->
          <div class="p-5 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center mb-4">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="fst-italic">Formulário de Contactos</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row justify-content-center">
              <!-- First -->
              <div class="col-12 col-xl-9">
                <div class="card rounded shadow">
                  <div class="row g-3 needs-validation m-0 p-5" novalidate>
                    <div class="col-md-4">
                      <label for="validationCustomName" class="form-label">Nome</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fab fa-neos"></i></span>
                        <input type="text" class="form-control" [class.border-danger]="nomeError != ''" [(ngModel)]="nome">
                        <div class="invalid-feedback" [class.d-block]="nomeError != ''">
                          {{nomeError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="validationCustomSurname" class="form-label">Sobrenome</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fab fa-stripe-s"></i></span>
                        <input type="text" class="form-control" [class.border-danger]="sobrenomeError != ''" [(ngModel)]="sobrenome">
                        <div class="invalid-feedback" [class.d-block]="sobrenomeError != ''">
                          {{sobrenomeError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="validationCustom03" class="form-label">Telefone</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fal fa-mobile-android"></i></span>
                        <input type="text" class="form-control" [inputMask]="phoneMask" [class.border-danger]="phoneError != ''" [(ngModel)]="phone">
                        <div class="invalid-feedback" [class.d-block]="phoneError != ''">
                          {{phoneError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="validationCustomEmail" class="form-label">Email</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fal fa-at"></i></span>
                        <input type="email" class="form-control" [class.border-danger]="emailError != ''" [(ngModel)]="email" placeholder="name@example.com">
                        <div class="invalid-feedback" [class.d-block]="emailError != ''">
                          {{emailError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <label for="validationCustomSubject" class="form-label">Assunto</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fal fa-font"></i></span>
                        <input type="text" class="form-control" [class.border-danger]="subjectError != ''" [(ngModel)]="subject">
                        <div class="invalid-feedback" [class.d-block]="subjectError != ''">
                          {{subjectError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label for="validationTextarea" class="form-label">Mensagem</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fal fa-comment-alt-dots"></i></span>
                        <textarea class="form-control text-justify pt-4" [class.border-danger]="messageError != ''" [(ngModel)]="message" placeholder="Escreva aqui a sua mensagem" maxlength="1500" rows="12"></textarea>
                        <div class="invalid-feedback" [class.d-block]="messageError != ''">
                          {{messageError}}
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-outline-secondary" (click)="sendEmail()">Enviar <i class="fad fa-paper-plane ms-2"></i></button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <script>
    // Example starter JavaScript for disabling form submissions if there are invalid fields
    (function () {
      'use strict'

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll('.needs-validation')

      // Loop over them and prevent submission
      Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
              event.preventDefault()
              event.stopPropagation()
            }
            form.classList.add('was-validated')
          }, false)
        })
    })()
  </script>
</main>