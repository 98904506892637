<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom sticky-md-top navbar-height">
  <div class="container">
    <a class="navbar-brand me-5" href="#">
      <img src="../../assets/images/logo/koffeum.svg" alt="" height="65px">
    </a>

    <!-- Botão de menu para dispositivos pequenos -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Itens do menu -->
    <div class="collapse navbar-collapse justify-content-md-between" id="navbarNav">
      <ul class="navbar-nav" [class]="search.display ? 'd-lg-none' : 'd-lg-flex'">
        <!-- Itens do menu -->
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item dropdown" (mouseover)="showMenu('CoffeeDropDown')" (mouseleave)="collapseMenu('CoffeeDropDown')">
          <a class="nav-link dropdown-toggle" href="/kraft">Cafés</a>
          <ul class="dropdown-menu" id="CoffeeDropDown">
            <ng-container *ngFor="let item of prodList">
              <li><a class="dropdown-item" href="/kraft#coffee{{item.id}}">{{item.name}}</a></li>
            </ng-container>
          </ul>
        </li>
        <li class="nav-item dropdown" (mouseover)="showMenu('EquipmentDropDown')" (mouseleave)="collapseMenu('EquipmentDropDown')">
          <a class="nav-link dropdown-toggle" role="button">Equipamentos</a>
          <ul class="dropdown-menu" id="EquipmentDropDown">
            <li><a class="dropdown-item" href="/show-equip-cafe">Café</a></li>
            <li><a class="dropdown-item" href="/show-all-equip?type=cold_equipment">Frio</a></li>
            <li><a class="dropdown-item" href="/show-all-equip?type=hot_equipment">Quente</a></li>
            <li><a class="dropdown-item" href="/show-all-equip?type=other_equipment">Outros</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/projectos">Projetos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/assistencia">Assistência</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/contacto">Contactos</a>
        </li>
      </ul>

      <!-- Login, Carrinho e Pesquisa para dispositivos pequenos -->
      <div class="d-lg-none row align-items-center">
        <div class="col-7 col-md-8 mb-2">
          <a class="nav-link" href="/login">Login<i class="ms-3 fal fa-door-open"></i></a>
        </div>

        <div class="col-5 col-md-4 mb-2 text-end" *ngIf="cart.length > 0">
          <a class="position-relative btn btn-outline-lc-secundary position-relative duration-500" href="/carrinho">
            <i class="fad fa-shopping-cart"></i>
            <span class="position-absolute translate-middle badge rounded-pill bg-cart-count">{{calculateCounter()}}</span>
          </a>
        </div>
      </div>
      <div class="col-6 col-lg-4 input-group d-lg-none">
        <input type="text" class="form-control" placeholder="Procurar" [(ngModel)]="search.text" (keydown.enter)="searchPage()">
        <span class="input-group-text" (click)="searchPage()"><i class="fal fa-search"></i></span>
      </div>

      <!-- Login, Carrinho e Pesquisa para dispositivos médios e grandes -->
      <div class="col-6 col-lg-4 d-lg-flex d-none align-items-center" [class]="search.display ? 'd-lg-none' : 'd-lg-flex'">
        <div class="col-md-6 col-lg-8 col-xl-9" *ngIf="!username">
          <a class="nav-link" href="/login">Login<i class="ms-3 fal fa-door-open"></i></a>
        </div>
        <div class="col-md-6 col-lg-8 col-xl-9" *ngIf="username">
          <a class="nav-link"><span type="button" (click)="location.href='/conta'"><i class="me-3 fal fa-user"></i>{{username}}</span><span type="button" (click)="logout()"><i class="ms-3 fal fa-sign-out-alt text-danger"></i></span></a>
        </div>
        <div class="col-md-2 col-lg-3 col-xl-2">
            <button class="btn btn-outline-lc-primary duration-700" type="button" (click)="openSearchField()"><i class="fal fa-search"></i></button>
        </div>
        <div class="col-md-6 col-lg-1 col-xl-1" *ngIf="cart.length > 0">
          <a class="position-relative btn btn-outline-lc-secundary duration-500" href="/carrinho">
            <i class="fad fa-shopping-cart"></i>
            <span class="position-absolute translate-middle badge rounded-pill bg-cart-count">{{calculateCounter()}}</span>
          </a>
        </div>
      </div>
      <div class="col-12 d-none input-group" [class]="search.display ? 'd-lg-flex' : 'd-lg-none'">
        <span class="input-group-text btn btn-outline-danger" (click)="search.display = false;"><i class="fas fa-times"></i></span>
        <input type="text" class="form-control" placeholder="Procurar" [(ngModel)]="search.text" (keydown.enter)="searchPage()" id="searchFocus">
        <span class="input-group-text btn btn-outline-koffeum"><i class="fal fa-search" (click)="searchPage()"></i></span>
      </div>
    </div>
  </div>
</nav>