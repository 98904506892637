import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { _navbarComponent } from './_navbar/_navbar.component';
import { _footerComponent } from './_footer/_footer.component';
import { _homeComponent } from './_home/_home.component';
import { KraftComponent } from './kraft/kraft.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EquipamentoCafeComponent } from './equipamento-cafe/equipamento-cafe.component';
import { EquipamentoMoinhosComponent } from './equipamento-moinhos_obsoleto/equipamento-moinhos.component';
import { EquipamentoDepuradoresComponent } from './equipamento-depuradores_obsoleto/equipamento-depuradores.component';
import { EquipamentosComponent } from './equipamentos/equipamentos.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProjectosComponent } from './projectos/projectos.component';
import { PublicidadeComponent } from './publicidade/publicidade.component';
import { AssistenciaComponent } from './assistencia/assistencia.component';
import { ContactosComponent } from './contactos/contactos.component';
import { LoginComponent } from './login/login.component';
import { RegistoComponent } from './registo/registo.component';
import { RecuperacaoComponent } from './recuperacao/recuperacao.component';
import { VerProdutosComponent } from './verProdutos/verProdutos.component';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { StepperComponent } from './stepper/stepper.component';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbAccordionModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ModosPagamentoComponent } from './modos-pagamento/modos-pagamento.component';
import { EnviosComponent } from './envios/envios.component';
import { Trocas_rmasComponent } from './trocas_rmas/trocas_rmas.component';
import { CondicoesContaOnlineComponent } from './condicoes-conta-online/condicoes-conta-online.component';
import { PrivacidadeCookiesComponent } from './privacidade-cookies/privacidade-cookies.component';
import { LitigiosComponent } from './litigios/litigios.component';
import { AtuacaoComponent } from './atuacao/atuacao.component';
import { Sobre_nosComponent } from './sobre_nos/sobre_nos.component';
import { KoffeumEmpresaComponent } from './koffeum-empresa/koffeum-empresa.component';
import { NossasLojasComponent } from './nossas-lojas/nossas-lojas.component';
import { MostrarEquipCafeComponent } from './mostrar-equip-cafe/mostrar-equip-cafe.component';
import { MostrarEquipQuenteFrioOutrosComponent } from './mostrar-equip-quente-frio-outros/mostrar-equip-quente-frio-outros.component';
import { HttpClientModule } from '@angular/common/http';
import { MoneyPipe } from './_&pipe/money.pipe';
import { PaginationModule } from './_&modules/pagination/pagination.module';
import { GestaoModule } from './gestao/gestao.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { SearchOutputComponent } from './SearchOutput/SearchOutput.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';

@NgModule({
  declarations: [					
    MoneyPipe,													
    AppComponent,
    _navbarComponent,
    _footerComponent,
    _homeComponent,
    PaginationComponent,
    KraftComponent,
    EquipamentoCafeComponent,
    EquipamentoMoinhosComponent,
    EquipamentoDepuradoresComponent,
    EquipamentosComponent,
    PaginationComponent,
    ProjectosComponent,
    PublicidadeComponent,
    AssistenciaComponent,
    ContactosComponent,
    LoginComponent,
    RegistoComponent,
    RecuperacaoComponent,
      VerProdutosComponent,
      CarrinhoComponent,
      CheckoutComponent,
      StepperComponent,
      ModosPagamentoComponent,
      EnviosComponent,
      Trocas_rmasComponent,
      CondicoesContaOnlineComponent,
      PrivacidadeCookiesComponent,
      LitigiosComponent,
      AtuacaoComponent,
      Sobre_nosComponent,
      KoffeumEmpresaComponent,
      NossasLojasComponent,
      MostrarEquipCafeComponent,
      MostrarEquipQuenteFrioOutrosComponent,
      SearchOutputComponent,
      ResetPasswordComponent
   ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    PaginationModule,
    NgbModule,
    NgbNavModule,
    GestaoModule,
    InputMaskModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
