export class ProductSubtype {

    constructor(item: ProductSubtype|void) {
        if(item instanceof Object) {
            this.id = item.id;
            this.name = item.name;
            this.description = item.description;
            this.active = item.active;
            this.typeProducts = item.typeProducts;
            this.categoria = item.categoria;
            this.showProductsInNavbar = item.showProductsInNavbar;
        }
    }

    id: number = -1;
    name: string = "";
    description: string = "";
    active: boolean = true;
    typeProducts: number = -1;
    categoria: number = -1;
    showProductsInNavbar: boolean = false;
}
