<main>
  <div class="container-fluid">
    <div class="row">
      <!-- Carrousel -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <a href="" id="kraft"></a>
        <section>
          <!-- moinhos -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/equipamentos/backgroundGrinder.jpg" class="card-img rounded-0" alt="...">
            <div class="card-img-overlay">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">Moinhos de Café</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl pt-3">Em 1798, nos Estados Unidos, houve a primeira patente de moedor de grãos de café, por Thomas Bruff. <br> No ano de 1898, a Hobart Manufacturing, em Ohio, começou a fabricar os primeiros moedores elétricos.</p>
            </div>
          </div>
          <!-- moinhos vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/equipamentos/grinderMobile2.png" class="img-mobile" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">Moinhos de Café</h5>
                <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">Em 1798, nos Estados Unidos, houve a primeira patente de moedor de grãos de café, por Thomas Bruff. No ano de 1898, a Hobart Manufacturing, <br> em Ohio, começou a fabricar os primeiros moedores elétricos.</p>
              </div>
            </div>
          </div>
        </section>
        <!--Filtro Marcas-->
        <section class="mt-20">
          <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <div class="d-flex align-items-center justify-content-center mb-4">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="text-center">Selecione a marca que pretende visualizar</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row d-flex justify-content-between justify-content-sm-center justify-content-md-between">
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0">
                <a href="">
                  <div class="card card-expand shadow">
                    <img src="../../assets/images/brands/fiamma_white@2x.png" class="card-img" alt="...">
                  </div>
                </a>
              </div>
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0">
                <a href="">
                  <div class="card card-expand shadow brand-select-active">
                    <img src="../../assets/images/brands/futurete.png" class="card-img" alt="...">
                  </div>
                </a>
              </div>
              <div class="col-10 col-md-4 col-lg-3 col-md-4">
                <!--<a href="" class="">
                  <div class="card card-expand shadow">
                    <img src="../../assets/images/brands/saeco.png" class="card-img" alt="...">
                  </div>
                </a>-->
              </div>
            </div>
          </div>
        </section>
        <section class="mt-20">
          <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <div class="d-flex align-items-center justify-content-center mb-4">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="text-center">Selecione o tipo de moinho</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row d-flex justify-content-between justify-content-sm-center justify-content-md-between">
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0 text-center">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                  <label class="form-check-label" for="inlineRadio1">
                    <h5>Todos</h5>
                  </label>
                </div>
              </div>
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0 text-center">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                  <label class="form-check-label" for="inlineRadio2">
                    <h5>Doser Grinder</h5>
                  </label>
                </div>
              </div>
              <div class="col-10 col-md-4 col-lg-3 col-md-4 text-center">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                  <label class="form-check-label" for="inlineRadio3">
                    <h5>On Demand</h5>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Moinhos-->
        <section class="mt-20">
          <!--product cards-->
          <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Moinhos</h5>
              </div>
              <div class="col-4 col-lg-8 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container py-5">
              <div class="row">
                <!--first-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Second-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a href="" class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/olitrem/cavesVinho/CV-66-PV-garrafas.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit">Card title</h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2"></span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h"></p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Third-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a href="" class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/futurete/maquinas/oberon2Grupos.jpg" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">Futurete Oberon 2 Grupos Digital</h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> Numa nova era na cultura do café, a FUTURETE – Indústrias de Máquinas de Café dedica-se ao fabrico exclusivamente artesanal de máquinas de café expresso, para satisfazer os consumidores mais exigentes desta bebida, que procuram na história, na tradição e no ritual a experiência singular de um café perfeito.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2"></span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h"></p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Fourth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/futurete/maquinas/oberon2Grupos.jpg" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Fifth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Sixth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Seventh-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Eighth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <app-pagination></app-pagination>
            </div>
          </div>
        </section>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Fiamma</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12 col-md-12 col-lg-12">
                <div class="card">
                  <div class="row g-0">
                    <div class="col-md-4 ">
                      <img src="../../assets/images/equipamentos/astrolab3pp.png" class="img-fluid" alt="...">
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Astrolab</h5>
                        <div class="accordion mb-3 hidden-xl" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Accordion Item #1
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Accordion Item #2
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Sem acordeão -->
                        <div class="hidden-sm hidden-md hidden-lg mb-20">
                          <p class="card-text">Os nossos modelos premium, que dispõem das funcionalidades mais avançadas do mercado, são de fácil utilização. Disponíveis nas versões Pressure Profile, com programação dos perfis de pressão para a extração de cada café, ou Multiboiler, com a tradicional extração a 9 bar. A escolha é sua.</p>
                          <h5 class="card-title">3 PP</h5>
                          <p class="card-text">A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-3">
                    <!-- tabs -->
                    <nav>
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-pressure-tab" data-bs-toggle="tab" data-bs-target="#nav-pressure" type="button" role="tab" aria-controls="nav-pressure" aria-selected="true">Pressure Profile</button>
                        <button class="nav-link" id="nav-boiler-tab" data-bs-toggle="tab" data-bs-target="#nav-boiler" type="button" role="tab" aria-controls="nav-boiler" aria-selected="false">Multiboiler</button>
                        <button class="nav-link" id="nav-custom-tab" data-bs-toggle="tab" data-bs-target="#nav-custom" type="button" role="tab" aria-controls="nav-costum" aria-selected="false">Customização</button>
                        <button class="nav-link" id="nav-espec-tab" data-bs-toggle="tab" data-bs-target="#nav-espec" type="button" role="tab" aria-controls="nav-espec" aria-selected="false">Todas as Especificações</button>
                      </div>
                    </nav>
                    <div class="tab-content mt-3" id="nav-tabContent">
                      <div class="tab-pane fade show active h-265 overflow-y overflow-x-hidden" id="nav-pressure" role="tabpanel" aria-labelledby="nav-pressure-tab">Trabalhar com perfis de pressão significa que o barista atribui uma sequência de várias pressões à extração, permitindo realçar as caraterísticas do café ou blend. A Astrolab dá controlo total sobre a pressão da bomba durante toda a extração</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-boiler" role="tabpanel" aria-labelledby="nav-boiler-tab">Sistema de caldeiras múltiplas e independentes. A Astrolab dispõe de pré-aquecimento da água do café, garantindo performance e vantagens energéticas.</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-custom" role="tabpanel" aria-labelledby="nav-custom-tab">Com o novo configurador da Fiamma, agora pode dar asas à criatividade para personalizar a sua Astrolab.
                        Selecione cores, texturas e padrões e desfrute do café extraído na sua própria máquina de café espresso!</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-espec" role="tabpanel" aria-labelledby="nav-espec-tab">
                        <div class="row">
                          <div class="col-12 pb-3">
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Grupos</div>
                                <div>3</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Tecnologia</div>
                                <div>Multi Boiler</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Controlo de Temperatura</div>
                                <div>PID</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Brew Pilot</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Shot Timer</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Contadores</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Alertas Inteligentes</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Caldeira de Água</div>
                                <div>3.5 kW (12 l)</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Caldeira de Café</div>
                                <div>3x 1.5 kW (3x 0.5 l)</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Bomba</div>
                                <div>Rotativa (externa)</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Lanças de Vapor</div>
                                <div>2</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Lanças de Vapor "Cool-to-Touch"</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Torneira de Água</div>
                                <div>2</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Água com Controlo de Temperatura</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Porções Programáveis de Água</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Limpeza Automática</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Ecrã Tátil</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Botões de Atalho</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Alavanca Eletrónica</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>"Pressure Profiling"</div>
                                <div>3 x 5</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Água Pré-Aquecida</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Poupança de Energia</div>
                                <div>Aquecimento Sequencial</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Potência</div>
                                <div>5.5 - 9 kW</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Voltagem</div>
                                <div>400V~2N</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Manípulos Rotativos</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Escalda Chávenas</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Luz LED</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Cores</div>
                                <div>Silver / Black</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Personalização</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Dimensões</div>
                                <div>1050x630x545mm</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Peso (kg)</div>
                                <div>92</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div></div>
                                <div></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer pt-4">
              <div class="d-xl-flex justify-content-between hidden-md hidden-sm hidden-lg">
                <div class="row align-items-baseline">
                  <div class="col">
                    <ul class="list-inline d-flex justify-content-between align-items-center">
                      <li class="list-inline-item">
                        <p class="card-text text-decoration-line-through">3.250€</p>
                      </li>
                      <li class="list-inline-item">
                        <p class="fw-bold badge bg-secondary py-2 mt-3">Desconto 25%</p>
                      </li>
                      <li class="list-inline-item">
                        <p class="card-text text-center textColor orange darken-4">2.437,5 €</p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-3 col-lg-4 col-xl-5 d-flex align-items-center">
                    <div class="input-group">
                      <button class="btn btn-outline-secondary duration-500" type="button" id="button-addon1"><i class="fad fa-minus"></i></button>
                      <input type="text" class="form-control text-center" value="1" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                      <button class="btn btn-outline-secondary rounded-end duration-500" type="button" id="button-addon2"><i class="fad fa-plus"></i></button>
                      <button class="btn btn-outline-secondary ms-3 rounded-start duration-500" type="button" id="button-addon3">Adicionar ao <i class="fad fa-shopping-cart"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <!--vista tablet / mobile -->
              <div class="hidden-xl">
                <div class="row mt-10">
                  <div class="col-12 col-lg-4">
                    <div class="d-flex justify-content-between text-center">
                      <p class="card-text text-decoration-line-through">3.250€</p>
                      <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                      <p class="card-text text-center textColor orange darken-4">2.437,5 €</p>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-7 col-md-6 col-lg-4">
                    <div class="input-group ">
                      <button class="btn btn-outline-secondary duration-500" type="button" id="button-addon1"><i class="fad fa-minus"></i></button>
                      <input type="text" class="form-control text-center" value="1" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                      <button class="btn btn-outline-secondary rounded-end duration-500" type="button" id="button-addon2"><i class="fad fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="col-5 col-md-6 col-lg-4 d-flex justify-content-center">
                    <button class="btn btn-outline-secondary rounded-start duration-500" type="button" id="button-addon3"><span class="hidden-sm">Adicionar ao</span> <i class="fad fa-shopping-cart"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Fiamma</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12 col-md-12 col-lg-12">
                <div class="card">
                  <div class="row g-0">
                    <div class="col-md-4 ">
                      <img src="../../assets/images/equipamentos/astrolab3pp.png" class="img-fluid" alt="...">
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Astrolab</h5>
                        <div class="accordion mb-3 hidden-xl" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Accordion Item #1
                              </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                            </div>
                          </div>
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Accordion Item #2
                              </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Sem acordeão -->
                        <div class="hidden-sm hidden-md hidden-lg mb-20">
                          <p class="card-text">Os nossos modelos premium, que dispõem das funcionalidades mais avançadas do mercado, são de fácil utilização. Disponíveis nas versões Pressure Profile, com programação dos perfis de pressão para a extração de cada café, ou Multiboiler, com a tradicional extração a 9 bar. A escolha é sua.</p>
                          <h5 class="card-title">3 PP</h5>
                          <p class="card-text">A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-3">
                    <!-- tabs -->
                    <nav>
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-pressure-tab" data-bs-toggle="tab" data-bs-target="#nav-pressure" type="button" role="tab" aria-controls="nav-pressure" aria-selected="true">Pressure Profile</button>
                        <button class="nav-link" id="nav-boiler-tab" data-bs-toggle="tab" data-bs-target="#nav-boiler" type="button" role="tab" aria-controls="nav-boiler" aria-selected="false">Multiboiler</button>
                        <button class="nav-link" id="nav-custom-tab" data-bs-toggle="tab" data-bs-target="#nav-custom" type="button" role="tab" aria-controls="nav-costum" aria-selected="false">Customização</button>
                        <button class="nav-link" id="nav-espec-tab" data-bs-toggle="tab" data-bs-target="#nav-espec" type="button" role="tab" aria-controls="nav-espec" aria-selected="false">Todas as Especificações</button>
                      </div>
                    </nav>
                    <div class="tab-content mt-3" id="nav-tabContent">
                      <div class="tab-pane fade show active h-265 overflow-y overflow-x-hidden" id="nav-pressure" role="tabpanel" aria-labelledby="nav-pressure-tab">Trabalhar com perfis de pressão significa que o barista atribui uma sequência de várias pressões à extração, permitindo realçar as caraterísticas do café ou blend. A Astrolab dá controlo total sobre a pressão da bomba durante toda a extração</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-boiler" role="tabpanel" aria-labelledby="nav-boiler-tab">Sistema de caldeiras múltiplas e independentes. A Astrolab dispõe de pré-aquecimento da água do café, garantindo performance e vantagens energéticas.</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-custom" role="tabpanel" aria-labelledby="nav-custom-tab">Com o novo configurador da Fiamma, agora pode dar asas à criatividade para personalizar a sua Astrolab.
                        Selecione cores, texturas e padrões e desfrute do café extraído na sua própria máquina de café espresso!</div>
                      <div class="tab-pane fade h-265 overflow-y overflow-x-hidden" id="nav-espec" role="tabpanel" aria-labelledby="nav-espec-tab">
                        <div class="row">
                          <div class="col-12 pb-3">
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Grupos</div>
                                <div>3</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Tecnologia</div>
                                <div>Multi Boiler</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Controlo de Temperatura</div>
                                <div>PID</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Brew Pilot</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Shot Timer</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Contadores</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Alertas Inteligentes</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Caldeira de Água</div>
                                <div>3.5 kW (12 l)</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Caldeira de Café</div>
                                <div>3x 1.5 kW (3x 0.5 l)</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Bomba</div>
                                <div>Rotativa (externa)</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Lanças de Vapor</div>
                                <div>2</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Lanças de Vapor "Cool-to-Touch"</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Torneira de Água</div>
                                <div>2</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Água com Controlo de Temperatura</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Porções Programáveis de Água</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Limpeza Automática</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Ecrã Tátil</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Botões de Atalho</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Alavanca Eletrónica</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>"Pressure Profiling"</div>
                                <div>3 x 5</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Água Pré-Aquecida</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Poupança de Energia</div>
                                <div>Aquecimento Sequencial</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Potência</div>
                                <div>5.5 - 9 kW</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Voltagem</div>
                                <div>400V~2N</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Manípulos Rotativos</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Escalda Chávenas</div>
                                <div>Sim</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Luz LED</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Cores</div>
                                <div>Silver / Black</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Personalização</div>
                                <div>Sim</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Dimensões</div>
                                <div>1050x630x545mm</div>
                              </li>
                            </ul>
                            <ul class="list-group list-group-horizontal d-flex justify-content-around flex-md-column flex-sm-column flex-lg-row">
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div>Peso (kg)</div>
                                <div>92</div>
                              </li>
                              <li class="list-group-item col-12 col-md-12 col-lg-5 d-flex justify-content-between align-items-center border-top-0 border-start-0 border-end-0">
                                <div></div>
                                <div></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer pt-4">
              <div class="d-xl-flex justify-content-between hidden-md hidden-sm hidden-lg">
                <div class="row align-items-baseline">
                  <div class="col">
                    <ul class="list-inline d-flex justify-content-between align-items-center">
                      <li class="list-inline-item">
                        <p class="card-text text-decoration-line-through">3.250€</p>
                      </li>
                      <li class="list-inline-item">
                        <p class="fw-bold badge bg-secondary py-2 mt-3">Desconto 25%</p>
                      </li>
                      <li class="list-inline-item">
                        <p class="card-text text-center textColor orange darken-4">2.437,5 €</p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-3 col-lg-4 col-xl-5 d-flex align-items-center">
                    <div class="input-group">
                      <button class="btn btn-outline-secondary duration-500" type="button" id="button-addon1"><i class="fad fa-minus"></i></button>
                      <input type="text" class="form-control text-center" value="1" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                      <button class="btn btn-outline-secondary rounded-end duration-500" type="button" id="button-addon2"><i class="fad fa-plus"></i></button>
                      <button class="btn btn-outline-secondary ms-3 rounded-start duration-500" type="button" id="button-addon3">Adicionar ao <i class="fad fa-shopping-cart"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <!--vista tablet / mobile -->
              <div class="hidden-xl">
                <div class="row mt-10">
                  <div class="col-12 col-lg-4">
                    <div class="d-flex justify-content-between text-center">
                      <p class="card-text text-decoration-line-through">3.250€</p>
                      <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                      <p class="card-text text-center textColor orange darken-4">2.437,5 €</p>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-7 col-md-6 col-lg-4">
                    <div class="input-group ">
                      <button class="btn btn-outline-secondary duration-500" type="button" id="button-addon1"><i class="fad fa-minus"></i></button>
                      <input type="text" class="form-control text-center" value="1" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                      <button class="btn btn-outline-secondary rounded-end duration-500" type="button" id="button-addon2"><i class="fad fa-plus"></i></button>
                    </div>
                  </div>
                  <div class="col-5 col-md-6 col-lg-4 d-sm-grid d-md-flex justify-content-md-center">
                    <button class="btn btn-outline-secondary rounded-start duration-500" type="button" id="button-addon3"><span class="hidden-sm">Adicionar ao</span> <i class="fad fa-shopping-cart"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>