export class Product {

    constructor(item: Product|void) {
        if(item instanceof Object) {
            this.id = item.id;
            this.name = item.name;
            this.description = item.description;
            this.active = item.active;
            this.price = item.price;
            this.discount = item.discount;
            this.iva = item.iva;
            this.subTypeProduct = item.subTypeProduct;
        }
    }

    id: number = -1;
    name: string = "";
    description: string = "";
    active: boolean = true;
    price: number = 0;
    discount: number = 0;
    iva: number = 0;
    subTypeProduct: number = -1;
}
