import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  
  constructor(private http: HttpClient) { }

  baseUrl: string = "https://"+environment.apiUrl+"/api/email/";

  askForRecovery(email: string) {
    return this.http.post(this.baseUrl+"request/recovery", {email: email}).pipe();
  }

  sendEmailContacto(email: string) {
    return this.http.post(this.baseUrl+"contact", {email: email}).pipe();
  }

}
