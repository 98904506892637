import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { createMask } from '@ngneat/input-mask';
import { Morada } from 'src/app/_&models/Morada';
import { Utilizadores } from 'src/app/_&models/User';
import { VerifyEmailPipe, VerifyNIFPipe } from 'src/app/_&pipe/verify.pipe';
import { ErrorService } from 'src/app/_&services/Error.service';
import { UserService } from 'src/app/_&services/user.service';

@Component({
  selector: 'app-ManageAccount',
  templateUrl: './ManageAccount.component.html',
  styleUrls: ['./ManageAccount.component.css']
})
export class ManageAccountComponent implements OnInit, OnChanges {

  phoneMask = createMask('999 999 999');

  constructor(private _user: UserService, private modal: NgbModal) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.user?.currentValue) {
      this.favBill = this.user.defaultBillingAddress ? this.user.defaultBillingAddress : null;
      this.favShip = this.user.defaultShippingAddress ? this.user.defaultShippingAddress : null;
    }
    if(changes.addrs?.currentValue) {
      this.slitAddr();
    }
  }

  slitAddr() {
    var list = this.addrs.filter(x => x.id != this.favBill && x.id != this.favShip);
    if(list.length == 1){
      this.addrLeft = list;
      this.addrRight = [];
    }else{
      var middle: number = Math.floor(list.length / 2);
      if(middle >= 0 && list.length > 0)
        this.addrLeft = list.slice(0, middle);
      if(middle > 0 && middle != list.length)
        this.addrRight = list.slice(middle, list.length);
    }
  }

  ngOnInit() { }

  @Input() user: Utilizadores;
  @Input() addrs: Morada[] = [];

  favBill: number|null = null;
  favShip: number|null = null;

  addrLeft: Morada[] = [];
  addrRight: Morada[] = [];

  getAddr(id: number) {
    return this.addrs.find(x => x.id == id);
  }
  
  nifPipe: VerifyNIFPipe = new VerifyNIFPipe();
  emailPipe: VerifyEmailPipe = new VerifyEmailPipe();

  accUser: any;
  accModal: NgbModalRef;
  accError: any = {
    name: false,
    email: false,
    nif: false,
    telephone: false,
    cellphone: false
  };
  openAccountModal(content) {
    this.accError = {
      name: false,
      email: false,
      nif: false,
      telephone: false,
      cellphone: false
    };
    this.accUser = new Utilizadores(this.user);
    this.accModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }
  closeAccountModal() {
    this.accModal.close();
    this.user = new Utilizadores(this.accUser);
  }
  updateUserData() {
    if(!this.nifPipe.transform(this.user.nif)) {
      this.accError.nif = true;
    }
    if(!this.emailPipe.transform(this.user.email)) {
      this.accError.email = true;
    }
    if(!this.accError.user && !this.accError.email && !this.accError.nif && !this.accError.telephone && !this.accError.cellphone) {
      this._user.sendData(this.user).subscribe(() => {
        this.accModal.close();
      }, (err) => {
        ErrorService.signalError.emit({
          title: "Erro ao atualizar os dados",
          body: err.error
        });
      })
    }
  }
  pass: any = {
    current: "",
    new: "",
    confirm: ""
  };
  showPass: any = {
    current: false,
    new: false,
    confirm: false
  };
  passError: boolean = false;
  passModal: NgbModalRef;

  switchState(option) {
    this.showPass[option] = !this.showPass[option];
  }

  openPasswordModal(content) {
    this.passError = false;
    this.pass = {
      current: "",
      new: "",
      confirm: ""
    };
    this.passModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }
  closePasswordModal() {
    this.passModal.close();
    this.pass = {
      current: "",
      new: "",
      confirm: ""
    };
  }
  updatePassData() {
    if(this.pass.new != this.pass.confirm) {
      this.passError = true;
    }
    if(!this.passError) {
      this._user.updatePassWord(this.pass.current, this.pass.new).subscribe(() => {
        this.passModal.close();
      }, (err) => {
        ErrorService.signalError.emit({
          title: "Erro ao atualizar a password",
          body: err.error
        });
      })
    }
  }

  addAddr: Morada = new Morada();
  codFirst: number;
  codSecond: number;
  addrModal: NgbModalRef;
  isNewAddr: boolean = true;
  openAddrModal(content, addr: number|null = null) {
    if(addr) {
      this.addAddr = this.getAddr(addr);
      this.isNewAddr = false;
    }
    this.addrModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }
  closeAddrModal() {
    this.addrModal.close();
    this.addAddr = new Morada();
    this.codFirst = undefined;
    this.codSecond = undefined;
    this.isNewAddr = true;
  }
  updateCodPos() {
    if(this.codFirst > 9999)
      this.codFirst = 9999;
    if(this.codSecond > 999)
      this.codSecond = 999;
    this.addAddr.postal = this.codFirst+"-"+this.codSecond;
  }
  updateAddr() {
    if(this.isNewAddr) {
      this._user.insertNewAddress(this.addAddr).subscribe(() => {
        this.closeAddrModal();
        this._user.getAddress().subscribe((data) => {
          this.addrs = data;
          this.slitAddr();
        }),(err) => {
          ErrorService.signalError.emit({
            title: "Erro User",
            body: err.error
          });
        };
      }, (err) => {
        ErrorService.signalError.emit({
          title: "Erro ao adicionar nova morada",
          body: err.error
        });
      })
    }else{
      this._user.editAddress(this.addAddr).subscribe(() => {
        this.closeAddrModal();
        this._user.getAddress().subscribe((data) => {
          this.addrs = data;
          this.slitAddr();
        }),(err) => {
          ErrorService.signalError.emit({
            title: "Erro User",
            body: err.error
          });
        };
      }, (err) => {
        ErrorService.signalError.emit({
          title: "Erro ao adicionar nova morada",
          body: err.error
        });
      })
    }
  }

  deleteAddr(id: number) {
    this._user.deleteAddress(id).subscribe(() => {
      this._user.getAddress().subscribe((data) => {
        this.addrs = data;
        this.slitAddr();
      }),(err) => {
        ErrorService.signalError.emit({
          title: "Erro User",
          body: err.error
        });
      };
    }, (err) => {
      ErrorService.signalError.emit({
        title: "Erro ao eliminar morada",
        body: err.error
      });
    })
  }

  titleAlterDefault: string = "";
  alterDefaultModal: NgbModalRef;
  choice: number = 0;
  opt: "bill"|"ship" = "bill";
  alterDefault(content, opt) {
    this.opt = opt;
    this.titleAlterDefault = "Alterar a morada de "+(opt == "bill" ? "faturação" : "envio")+" por defeito";
    this.choice = opt == "bill" ? this.favBill : this.favShip;
    this.alterDefaultModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'lg',
      centered:true
    });
  }

  closeAlterDefault() {
    this.titleAlterDefault = "";
    this.choice = 0;
    this.alterDefaultModal.close();
  }

  updateAlter() {
    this._user.changeDefaultAddr(this.choice, this.opt).subscribe(() => {
      this.closeAlterDefault();
      location.reload();
    });
  }
}
