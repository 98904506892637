import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Login } from '../_&models/Login';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor(private http: HttpClient) { }

baseUrl: string = "https://"+environment.apiUrl+"/api/auth/";

loggedIn() {
    return this.http.post(this.baseUrl+"loggedin",{data: localStorage.getItem("userId")}).toPromise();
}

permissions() {
  return this.http.post(this.baseUrl + "permissions", {data: localStorage.getItem("userId")}).toPromise();
}

submitLogin(model: Login) {
  return this.http.post(this.baseUrl, model).pipe(
    map((data: any) => {
      localStorage.setItem("userId",data.result);
      localStorage.setItem("id",data.id);
      localStorage.setItem("name",data.name);
      return data.first;
    })
  );
}

logout() {
  return this.http.post(this.baseUrl+"logout",{
    data: localStorage.getItem("userId")
  }).pipe(
    map(() => {
      localStorage.removeItem("userId");
      localStorage.removeItem("name");
      location.href = "/admin/login"
    })
  );
}

alterFirstTimeStatus() {
  return this.http.post(this.baseUrl+"set/ignore", {
      data: localStorage.getItem("userId")
  }).pipe();
}

verifyNif(nif, id = 1) {
  return this.http.post(this.baseUrl+"nif",{nif,id}).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}

verifyEmail(email, id = 0) {
  return this.http.post(this.baseUrl+"email",{
    email: email,
    id: id
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}

register(register) {
  return this.http.post(this.baseUrl+"register", register).pipe();
}

resetPassword(token: string, password: string) {
  return this.http.post(this.baseUrl+"reset/password", {
    data: token,
    Model: password
  }).pipe();
}

}
