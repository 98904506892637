import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductService } from '../_&services/product.service';
import { Product } from '../_&models/Product';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Media } from '../_&models/Media';
import { environment } from 'src/environments/environment';
import { MediaService } from '../_&services/media.service';

@Component({
  selector: 'app-verProdutos',
  templateUrl: './verProdutos.component.html',
  styleUrls: ['./verProdutos.component.less']
})
export class VerProdutosComponent implements OnInit {

  product: Product = new Product();
  properties: any[] = [];
  media: Media[] = [];
  apiUrl: string = environment.apiUrl;
  propertiesMiddleSplit: any[][] = [];

  constructor(private _prod: ProductService, private _media: MediaService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: HttpParams) => {
      this._prod.getProduct(params['id']).subscribe((data) => {
        this.product = new Product(data);
        this._prod.getProductPropertiesFromProductId(this.product.id).subscribe((data: any[]) => {
          this.properties = data;
          var keyList = this.properties.filter(x => x.codename != 'brand' && x.codename != 'series' && x.codename != 'model' && x.codename != 'modelDesc');
          var midPoint = Math.ceil(keyList.length / 2);
          var firstHalf = keyList.slice(0, midPoint);
          var secondHalf = keyList.slice(midPoint % 2 ? (-midPoint+1) : (-midPoint));
          for(var i = 0; i < firstHalf.length; i++) {
            this.propertiesMiddleSplit.push([ firstHalf[i], secondHalf[i]]);
          }
        });
        this._media.getProductsMediaList(this.product.id).subscribe((data) => {
          this.media = data;
        });
      });
    });
  }

  getValue(key: string) {
    if(
      this.properties.length == 0 ||
      key == '' || 
      !this.properties.find(x => x.codename == key))
        return "";
    return this.properties.find(x => x.codename == key).value;
  }
  
  customOptions: OwlOptions = {
    loop: true,
    autoplay:true,
    autoplaySpeed:1200,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    center:true,
    navSpeed:700,
    navText: ['&nbsp; &nbsp; < &nbsp; &nbsp; ', '&nbsp; &nbsp; > &nbsp; &nbsp; '],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 3
      },
      760: {
        items: 4
      },
      1000: {
        items: 5
      }
    },
    nav: true
  }

}
