import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(value: string|number, symbol?: string,separator: "."|"," = ".", spacer: string = " "): any {
    value = (value+"").includes(separator == "." ? "," : ".") ? (value+"").replace(separator == "." ? "," : ".",separator) : value+""
    var sValue = value.split(separator);
    var cents = sValue[1];
    var val = sValue[0].replace(/ /gm,"");
    val = val.replace(/\B(?=(\d{3})+(?!\d))/g,' ');
    if(!(cents == undefined || cents == null))
      val += separator+(cents.length < 2 ? cents+"0": cents);
    else
      val += separator+"00";
    return val+(symbol ? symbol : '');
  }

}
