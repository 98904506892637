import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atuacao',
  templateUrl: './atuacao.component.html',
  styleUrls: ['./atuacao.component.css']
})
export class AtuacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
