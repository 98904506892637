import { Component, OnInit } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { ProductSubtype } from '../_&models/ProductSubtype';
import { Product } from '../_&models/Product';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mostrar-equip-cafe',
  templateUrl: './mostrar-equip-cafe.component.html',
  styleUrls: ['./mostrar-equip-cafe.component.css']
})
export class MostrarEquipCafeComponent implements OnInit {

  constructor(private _prod: ProductService) { }

  apiUrl: string = environment.apiUrl;

  list: ProductSubtype[] = [];
  brands: string[] = [];
  products: Product[] = [];
  selected: number = -1;

  brandSelected: string = "";

  current: number = 1;
  pages: number = 1;

  ngOnInit() {
    this._prod.getProductsSubTypeCompleteList('coffee_equipment', 2).subscribe((data) => {
      data.forEach(element => {
        this.list.push(new ProductSubtype(element));
      });
      this.selected = this.list[0].id;
      this.selectedSubtype(this.selected);
    });
  }

  selectedSubtype(id: number) {
    this.selected = id;
    this.brandSelected = "";
    this._prod.getPropertiesFromSubtype(this.selected, 'brand').subscribe((data: any) => {
      this.brands = data;
      this._prod.getProductsFromPropertiesAndSubtypePageCount(this.selected, 'brand', this.brandSelected, 4).subscribe((data: number) => {
        this.pages = data;
        this.current = 1;
        this.updateList();
      });
    });
  }
  
  selectedBrand(brand: string) {
    this.brandSelected = brand;
    this._prod.getProductsFromPropertiesAndSubtypePageCount(this.selected, 'brand', this.brandSelected, 4).subscribe((data: number) => {
      this.pages = data;
      this.current = 1;
      this.updateList();
    });
  }

  prodsProperties: any[] = [];
  updateList() {
    this.products = [];
    this.prodsProperties = [];
    this._prod.getProductsFromSubtypeAndPropertyPage(this.selected, 'brand', this.brandSelected, 4, this.current).subscribe((data: any) => {
      data.forEach(el => {
        this.products.push(new Product(el));
        this._prod.getProductPropertiesFromProductId(el.id).subscribe((data: any[]) => {
          this.prodsProperties.push({
            product: el.id,
            list: data
          });
        });
      });
    });
  }

  getValue(id: number, key: string) {
    if(
      id < 0 || this.prodsProperties.length == 0 || 
      !this.prodsProperties.find(x => x.product == id) || 
      key == '' || 
      !this.prodsProperties.find(x => x.product == id).list.find(x => x.codename == key))
        return "";
    return this.prodsProperties.find(x => x.product == id).list.find(x => x.codename == key).value;
  }

}
