<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/equipamentos/depurador.jpg" class="card-img rounded-0" alt="...">
            <div class="card-img-overlay">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">Depuradores</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl text-light pt-3">Os elementos existentes na água tal como o calcário podem afetar o sabor, a consistência e o aspeto do café assim como provocar avarias na sua máquina. O uso de depurador é essencial para um café de primeira qualidade e para a longevidade da sua máquina</p>
            </div>
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/equipamentos/depuradorMobile.jpg" class="card-img-top" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">Depuradores</h5>
                <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">Os elementos existentes na água tal como o calcário podem afetar o sabor, a consistência e o aspeto do café assim como provocar avarias na sua máquina. O uso de depurador é essencial para um café de primeira qualidade e para a longevidade da sua máquina</p>
              </div>
            </div>
          </div>
        </section>
        <!--Filtro Marcas-->
        <section class="mt-20">
          <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <div class="d-flex align-items-center justify-content-center mb-4">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="text-center">Selecione a marca que pretende visualizar</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row d-flex justify-content-between justify-content-sm-center justify-content-md-between">
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0">
                <a href="">
                  <div class="card card-expand shadow">
                    <img src="../../assets/images/brands/fiamma_white@2x.png" class="card-img" alt="...">
                  </div>
                </a>
              </div>
              <div class="col-10 col-md-4 col-lg-3 mb-3 mb-md-0">
                <a href="">
                  <div class="card card-expand shadow brand-select-active">
                    <img src="../../assets/images/brands/futurete.png" class="card-img" alt="...">
                  </div>
                </a>
              </div>
              <div class="col-10 col-md-4 col-lg-3 col-md-4">
                <a href="" class="">
                  <div class="card card-expand shadow">
                    <img src="../../assets/images/brands/saeco.png" class="card-img" alt="...">
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <!-- Máquinas de Café-->
        <section class="mt-20">
          <!--product cards-->
          <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Depuradores</h5>
              </div>
              <div class="col-4 col-lg-8 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container py-5">
              <div class="row">
                <!--first-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Second-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a href="" class="text-decoration-none">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/olitrem/cavesVinho/CV-66-PV-garrafas.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit">Card title</h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2"></span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h"></p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Third-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a href="" class="text-decoration-none">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/futurete/maquinas/oberon2Grupos.jpg" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">Futurete Oberon 2 Grupos Digital</h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> Numa nova era na cultura do café, a FUTURETE – Indústrias de Máquinas de Café dedica-se ao fabrico exclusivamente artesanal de máquinas de café expresso, para satisfazer os consumidores mais exigentes desta bebida, que procuram na história, na tradição e no ritual a experiência singular de um café perfeito.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2"></span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h"></p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Fourth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/futurete/maquinas/oberon2Grupos.jpg" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Fifth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Sixth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Seventh-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
                <!--Eighth-->
                <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="text-decoration-none" href="">
                    <div class="card align-items-center shadow card-expand h-537">
                      <div class="h-213 d-flex align-items-center">
                        <img src="../../assets/images/equipamentos/fiamma/maquinas/astrolab3pp.png" class="pt-3 img-fluid h-213" alt="...">
                      </div>
                      <div class="card-body">
                        <h5 class="card-title card-title-h card-title-limit text-center">(marca) Fiamma (série) Astrolab (modelo) 3PP </h5>
                        <p class="card-text card-text-limit card-text-h text-justify"> A Astrolab é uma máquina que permite a criação de 5 perfis de pressão por grupo e a extração de café através de um ligeiro movimento da alavanca eletrónica. O sistema operativo garante o controlo direto da extração e todos os parâmetros técnicos da máquina.</p>
                        <div class="d-flex justify-content-between discount-h">
                          <p class="card-text text-decoration-line-through">3.250€</p>
                          <p class="fw-bold"><span class="badge bg-secondary py-2">Desconto 25%</span></p>
                        </div>
                        <p class="card-text text-center textColor orange darken-4 price-h">2.437,5 €</p>
                        <a href="#" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500">Ir Para</a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <app-pagination></app-pagination>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</main>