<ul class="pagination d-flex justify-content-center p-0 m-0">
  <li class="page-item" *ngIf="pages > size">
    <a
      class="page-link"
      [class]="current != 1 ? 'text-'+color+' hover-lighten-'+color : 'text-'+color+' bg-black-100 disabled'"
      aria-label="first"
      role="button"
      (click)="setPag(1)">
      <span aria-hidden="true"><i class="fas fa-fast-backward"></i></span>
      <span class="sr-only">First</span>
    </a>
  </li>
  <li class="page-item">
    <a 
      class="page-link" 
      [class]="current > 1 ? 'text-'+color+' hover-lighten-'+color : 'text-'+color+' bg-black-100 disabled'"
      aria-label="Previous" 
      role="button" 
      (click)="setPag(current - 1)">
      <span aria-hidden="true"><i class="fas fa-backward"></i></span>
      <span class="sr-only">Previous</span>
    </a>
  </li>
  <li class="page-item" *ngIf="current > half && size < pages">
    <a class="page-link text-{{color}} bg-black-100 disabled" aria-label="More Behind">
      <span aria-hidden="true"><i class="fas fa-ellipsis-h"></i></span>
      <span class="sr-only">More Behind</span>
    </a>
  </li>
  <ng-container *ngFor="let x of [].constructor(size <= pages ? size : pages); let i = index;">
    <li class="page-item" *ngIf="current < half">
      <a class="page-link"
        [class]="current == i + 1 ? 'text-white bg-'+color+' disabled' : ' text-'+color+' hover-lighten-'+color" 
        role="button"
        (click)="setPag(i + 1)"
      >{{i+1}}</a>
    </li>
    <li class="page-item" *ngIf="current >= half && (pages - current) >= half && i + 1 == half">
      <a class="page-link text-white bg-{{color}} disabled"
        role="button"
        (click)="setPag(current)"
        >{{current}}</a>
    </li>
    <li class="page-item" *ngIf="current >= half && (pages - current) >= half && i + 1 != half">
      <a class="page-link text-{{color}} hover-lighten-{{color}}"
        role="button"
        (click)="setPag(current - half + i + 1)"
        >{{current - half + i + 1}}</a>
    </li>
    <li class="page-item" *ngIf="current >= half && (pages - current) < half">
      <a class="page-link"
        [class]="current == pages - half * 2 + i + (1 + ((size <= pages ? size : pages) % 2 != 0 ? 1 : 0)) ? 'text-white bg-'+color+' disabled' : ' text-'+color+' hover-lighten-'+color" 
        role="button"
        (click)="setPag(pages - half * 2 + i + (1 + ((size <= pages ? size : pages) % 2 != 0 ? 1 : 0)))"
      >{{pages - half * 2 + i + (1 + ((size <= pages ? size : pages) % 2 != 0 ? 1 : 0))}}</a>
    </li>
  </ng-container>
  <li class="page-item" *ngIf="pages - current >= half && size < pages">
    <a class="page-link text-{{color}} bg-black-100 disabled" aria-label="More Front">
      <span aria-hidden="true"><i class="fas fa-ellipsis-h"></i></span>
      <span class="sr-only">More Front</span>
    </a>
  </li>
  <li class="page-item">
    <a 
    class="page-link"
    [class]="current < pages ? 'text-'+color+' hover-lighten-'+color : 'text-'+color+' bg-black-100 disabled'"
    aria-label="Next" 
    role="button" 
    (click)="setPag(current+1)">
      <span aria-hidden="true"><i class="fas fa-forward"></i></span>
      <span class="sr-only">Next</span>
    </a>
  </li>
  <li class="page-item" *ngIf="pages > size">
    <a
    class="page-link"
    [class]="current != pages ? 'text-'+color+' hover-lighten-'+color : 'text-'+color+' bg-black-100 disabled'"
    aria-label="Last" role="button"
    (click)="setPag(pages)">
      <span aria-hidden="true"><i class="fas fa-fast-forward"></i></span>
      <span class="sr-only">Last</span>
    </a>
  </li>
</ul>
