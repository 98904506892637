import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-_footer',
  templateUrl: './_footer.component.html',
  styleUrls: ['./_footer.component.less']
})
export class _footerComponent implements OnInit {

  constructor() { }

  user: boolean = localStorage.getItem("userId") ? true : false;

  ngOnInit() {
  }

}
