<main>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/imagesAux/1917x532.JPG" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/kraft/coffee2.jfif" class="card-img-top rounded-0" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">O Café...</h5>
                <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
              </div>
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <!-- Fiamma -->
        <section id="fiamma">
          <div class="mt-4 p-0 px-sm-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Fiamma</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <!--first-->
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="card rounded-0 border p-3 mb-5">
                    <div class="row g-0">
                      <div class="col-md-6">
                        <div class="carousel">
                          <input type="radio" name="slides" checked="checked" id="slide-1">
                          <input type="radio" name="slides" id="slide-2">
                          <input type="radio" name="slides" id="slide-3">
                          <input type="radio" name="slides" id="slide-4">
                          <input type="radio" name="slides" id="slide-5">
                          <input type="radio" name="slides" id="slide-6">
                          <ul class="carousel__slides pt-4">
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/kraft/kraft.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/kraft/kraft1.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/kraft/kraft3.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="" alt="">
                                </div>
                              </figure>
                            </li>
                          </ul>
                          <ul class="carousel__thumbnails justify-content-center">
                            <li>
                              <label for="slide-1"><img src="../../assets/images/kraft/kraft.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-2"><img src="../../assets/images/kraft/kraft1.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-3"><img src="../../assets/images/kraft/kraft3.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-4"><img src="" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-5"><img src="" alt="" class="object-contain"></label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Café Profissional - Embalagem Kraft</h5>
                          <div class="">
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam eius quibusdam aliquid repellendus ipsum repellat illum dolorem rem, excepturi cupiditate laudantium neque alias iste culpa enim sapiente. Laborum, a repellat.</p>
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatem vel quod quasi placeat iure itaque facilis, inventore minima autem numquam beatae suscipit laborum enim explicabo repellendus nemo dolor velit.</p>
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatem vel quod quasi placeat iure itaque facilis, inventore minima autem numquam beatae suscipit laborum enim explicabo repellendus nemo dolor velit.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Futurete -->
        <section class="py-5" id="futurete">
          <div class="mt-4 p-0 px-sm-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Futurete</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <!--first-->
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="card rounded-0 border p-3 mb-3">
                    <div class="row g-0">
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Café Profissional - Embalagem X</h5>
                          <div class="">
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam eius quibusdam aliquid repellendus ipsum repellat illum dolorem rem, excepturi cupiditate laudantium neque alias iste culpa enim sapiente. Laborum, a repellat.</p>
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatem vel quod quasi placeat iure itaque facilis, inventore minima autem numquam beatae suscipit laborum enim explicabo repellendus nemo dolor velit.</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="carousel">
                          <input type="radio" name="slides" checked="checked" id="slide-1">
                          <input type="radio" name="slides" id="slide-2">
                          <input type="radio" name="slides" id="slide-3">
                          <input type="radio" name="slides" id="slide-4">
                          <input type="radio" name="slides" id="slide-5">
                          <input type="radio" name="slides" id="slide-6">
                          <ul class="carousel__slides">
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/profissional/profissional.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                          </ul>
                          <ul class="carousel__thumbnails justify-content-center">
                            <li>
                              <label for="slide-1"><img src="../../assets/images/profissional/profissional.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-2"><img src="../../assets/images/profissional/profissional.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-3"><img src="../../assets/images/profissional/profissional.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-4"><img src="" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-5"><img src="" alt="" class="object-contain"></label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="" id="prof"></a>
              </div>
            </div>
          </div>
          <a href="" id="avo"></a>
        </section>
        <!-- Saeco -->
        <section class="pb-5" id="saeco">
          <div class="mt-4 p-0 px-sm-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Saecco</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <!--first-->
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="card rounded-0 border p-3 mb-3">
                    <div class="row g-0">
                      <div class="col-md-6">
                        <div class="carousel">
                          <input type="radio" name="slides" checked="checked" id="slide-1">
                          <input type="radio" name="slides" id="slide-2">
                          <input type="radio" name="slides" id="slide-3">
                          <input type="radio" name="slides" id="slide-4">
                          <input type="radio" name="slides" id="slide-5">
                          <input type="radio" name="slides" id="slide-6">
                          <ul class="carousel__slides">
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo1.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo2.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo3.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo4.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                            <li class="carousel__slide">
                              <figure>
                                <div>
                                  <img src="../../assets/images/avo/avo5.jpg" alt="">
                                </div>
                              </figure>
                            </li>
                          </ul>
                          <ul class="carousel__thumbnails justify-content-center">
                            <li>
                              <label for="slide-1"><img src="../../assets/images/avo/avo.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-2"><img src="../../assets/images/avo/avo1.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-3"><img src="../../assets/images/avo/avo2.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-4"><img src="../../assets/images/avo/avo3.jpg" alt="" class="object-contain"></label>
                            </li>
                            <li>
                              <label for="slide-5"><img src="../../assets/images/avo/avo4.jpg" alt="" class="object-contain"></label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <h5 class="card-title">Café Torrado - Moído</h5>
                          <div class="">
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam eius quibusdam aliquid repellendus ipsum repellat illum dolorem rem, excepturi cupiditate laudantium neque alias iste culpa enim sapiente. Laborum, a repellat.</p>
                            <p class="card-text text-limit text-justify">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi voluptatem vel quod quasi placeat iure itaque facilis, inventore minima autem numquam beatae suscipit laborum enim explicabo repellendus nemo dolor velit.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</main>