import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/_&models/Product';
import { Utilizadores } from 'src/app/_&models/User';
import { MoneyPipe } from 'src/app/_&pipe/money.pipe';
import { OrdersService } from 'src/app/_&services/orders.service';
import { ProductService } from 'src/app/_&services/product.service';
import { UserService } from 'src/app/_&services/user.service';

@Component({
  selector: 'app-MyOrders',
  templateUrl: './MyOrders.component.html',
  styleUrls: ['./MyOrders.component.css']
})
export class MyOrdersComponent implements OnInit {

  constructor(private _orders: OrdersService, private _product: ProductService, private _user: UserService, private modal: NgbModal) { }

  ngOnInit() {
    this.remapPageCount();
  }

  pageSize: number = 5;
  page: number = 1;
  pageCount: number = 100;
  orders: any[] = [];
  usedProducts: Product[] = [];

  moneyPipe: MoneyPipe = new MoneyPipe();

  remapPageCount() {
    this._orders.getSelfOrdersListPageCount(this.pageSize).subscribe((data) => {
      this.pageCount = data;
      this.page = 1;
      setTimeout(() => {
        this.reload();
      }, 15);
    });
  }

  reload() {
    this._orders.getSelfOrdersListPage(this.pageSize, this.page).subscribe((data) => {
      this.orders = data;
      var prodList: number[] = [];
      this.orders.forEach((item) => {
        item.productList.forEach((el) => {
          if(prodList.filter(x => x == el.product).length == 0)
            prodList.push(el.product);
        });
        setTimeout(() => {
          this._orders.getOrderAddress(item.moradaFaturacao, item.moradaEnvio).subscribe((addrs: any) => {
            item.moradaFaturacao = addrs.billing;
            item.moradaEnvio = addrs.shipping;
          })
        },5);
      });
      this._product.getCartProducts(prodList).subscribe((data) => {
        this.usedProducts = data;
      });
    });
  }

  calculateOrderTotalValue(item: any) {    
    return this.moneyPipe.transform(this.calculateOrderTotalValueNumber(item), "€");
  }

  calculateOrderTotalValueNumber(item: any) {
    var result: number = 0;
    item.forEach(el => {
      var product = this.usedProducts.find(x => x.id == el.product);
      if(product)
        result += product.price*el.quantity;
    })
    return result;
  }

  calculateOrderTotalValueWithoutIVA(item: any) {
    var result: number = 0;
    item.forEach(el => {
      var product = this.usedProducts.find(x => x.id == el.product);
      if(product)
        result += (product.price*(1-product.iva))*el.quantity;
    })
    return this.moneyPipe.transform(result, "€");
  }

  calculateOrderTotalTax(item: any) {
    var result: number = 0;
    item.forEach(el => {
      var product = this.usedProducts.find(x => x.id == el.product);
      if(product)
        result += product.price*product.iva*el.quantity;
    })
    return this.moneyPipe.transform(result, "€");
  }

  money(val) {
    return this.moneyPipe.transform(val, '€');
  }

  getProduct(id) {
    return this.usedProducts.find(x => x.id == id);
  }

  getAddress(id) {
    this._user.getAddressById(id).subscribe((data) => {
      return data;
    });
  }

  viewOrderModal: NgbModalRef;
  order: any;
  openViewOrder(content, item) {
    this.order = item;
    this.viewOrderModal = this.modal.open(content, {
      backdrop: 'static',
      size: 'xl',
      centered:true
    });
  }

  closeViewOrder() {
    this.viewOrderModal.close();
    this.order = undefined;
  }

  stateTranslater = ["Aguardar Pagamento", "Em preparação", "Finalizada"];
}
