import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistencia',
  templateUrl: './assistencia.component.html',
  styleUrls: ['./assistencia.component.less']
})
export class AssistenciaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
