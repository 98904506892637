<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/modos-pagamento/Modos_de_Pagamento.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/modos-pagamento/Modos_de_Pagamento.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Modos de Pagamento</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container">
              <h5 class="mb-3">Dispomos de diversos modos de pagamento para facilitar a sua compra.</h5>
              <p>Seja através do website ou numa das lojas físicas, poderá optar por diversos modos de pagamento, de forma a que possa pagar da forma que mais lhe convier. Em baixo poderá consultar quais os modos de pagamento disponíveis na LUIS CARRILHO UNIPESSOAL, Lda, pague com total segurança e confiança.</p>
              <div class="row pt-3 mb-3">
                <!--Card Referencia-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/multibanco.png" class="img-fluid mb" alt="">
                      <h6>Referência Multibanco</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">O site enviará para o seu e-mail uma entidade, uma referência e o valor da sua encomenda que poderá liquidar em qualquer caixa ATM ou Homebanking;</p>
                      <p class="card-text">Encomendas com pagamento por Referência Multibanco são canceladas automaticamente caso não sejam pagas ao fim de 8 horas;</p>
                      <p class="card-text">Pagamentos via Referência Multibanco dispensam o envio de comprovativos;</p>
                      <p class="card-text">A LUIS CARRILHO UNIPESSOAL, Lda, recomenda este método de pagamento para encomendas online, devido à sua comodidade e maior rapidez de processos.</p>
                    </div>
                  </div>
                </div>
                <!--card MBWay-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/mbway.png" class="img-fluid mb" alt="">
                      <h6>MBWAY</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">Com o MB WAY pode pagar a sua encomenda através do seu número de telemóvel associado a este serviço;</p>
                      <p class="card-text">Encomendas com pagamento por MB WAY são canceladas automaticamente caso não sejam pagas ao fim de 5 minutos;</p>
                      <p class="card-text">Pagamentos via MB WAY dispensam o envio de comprovativos;</p>
                      &nbsp;
                      <p class="card-text">&nbsp;</p>
                    </div>
                  </div>
                </div>
                <!--Cartão Multibanco/ VISA / MASTERCARD-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/cartao-credito.png" class="img-fluid mb" alt="">
                      <h6>Cartão Multibanco / VISA / MASTERCARD</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">Em cada uma das nossas lojas temos disponível o pagamento via Cartão Bancário;</p>
                      <p class="card-text">Em cada uma das nossas lojas poderá pagar via Cartão Multibanco, VISA e Mastercard;</p>
                      <p class="card-text">Pagamento via American Express não disponível.</p>
                      &nbsp;
                      <p class="card-text">&nbsp;</p>
                    </div>
                  </div>
                </div>
                <!--Card Trans. Bancária-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/tb.png" class="img-fluid mb" alt="">
                      <h6>Transferência Bancária</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">O site enviará para o seu e-mail um IBAN, um BIC SWIFT e o valor da sua encomenda que poderá liquidar efetuando uma Transferência Bancaria;</p>
                      <p class="card-text">Pagamentos via Transferência Bancaria exigem o envio do respetivo comprovativo;</p>
                      <p class="card-text">Encomendas com pagamento por Transferência Bancaria são canceladas automaticamente caso não sejam pagas ao fim de 24 horas; </p>
                      <p class="card-text">A confirmação do valor da cobrança poderá demorar até 3 dias úteis.</p>
                    </div>
                  </div>
                </div>
                <!--Cartão Multibanco/ VISA / MASTERCARD-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/cheque.png" class="img-fluid mb" alt="">
                      <h6>Cheque</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">O pagamento via Cheque não se encontra disponível para encomendas online.</p>
                    </div>
                    &nbsp;
                    <p class="card-text">&nbsp;</p>
                  </div>
                </div>
                <!--Card Trans. Bancária-->
                <div class="col-6">
                  <div class="card mb-3">
                    <div class="card-header d-flex align-items-center">
                      <img src="../../assets/images/modos-pagamento/cartao-credito.png" class="img-fluid mb" alt="">
                      <h6>Crédito / Prestações</h6>
                    </div>
                    <div class="card-body">
                      <p class="card-text">O pagamento via Crédito não se encontra disponível para encomendas online.</p>
                    </div>
                    &nbsp;
                    <p class="card-text">&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>