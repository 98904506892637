import { CommonModule } from '@angular/common';
import { Injectable, EventEmitter, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

static signalError = new EventEmitter<ErrorInputModel>();

}

export class ErrorInputModel {

	constructor(item: ErrorInputModel|void) {
		if(item instanceof Object) {
			this.title = item.title == undefined ? "" : item.title;
			this.body = item.body == undefined ? "" : item.body;
			this.footer = item.footer == undefined ? "" : item.footer;
			this.showTitle = item.showTitle == undefined ? true : item.showTitle;
			this.showBody = item.showBody == undefined ? true : item.showBody;
			this.showFooter = item.showFooter == undefined ? false : item.showFooter;
			this.showCross = item.showCross == undefined ? true : item.showCross;
			this.redirect = item.redirect;
			this.redirectTimer = item.redirectTimer == undefined ? 1.5 : item.redirectTimer;
		}
	}

	title?: string = "";
	body?: any = "";
	footer?: any = "";
	showTitle?: boolean = true;
	showBody?: boolean = true;
	showFooter?: boolean = false;
	showCross?: boolean = true;
	redirect?: string;
	redirectTimer?: number = 1.5;
}

@Component({
	selector: 'error-modal-content',
	standalone: true,
	imports: [
		CommonModule
	],
	template: `
	<div class="modal-header" *ngIf="showTitle">
		<h5 class="modal-title">{{title}}</h5>
		<a type="button" class="btn border-none text-koffeum fs-2" (click)="modal.close('dismiss')" *ngIf="showCross"><i class="far fa-times-circle"></i></a>
	</div>
	<div class="modal-body" *ngIf="showBody">
    	<h6>{{body}}</h6>
	</div>
	<div class="modal-footer" *ngIf="showFooter">
		<button class="btn btn-koffeum">{{footer}}</button>
	</div>
	`,
})
export class ErrorModalContent {

	@Input() title: string;
	@Input() body: string|HTMLElement;
	@Input() footer: string|HTMLElement;
	@Input() showTitle: boolean = true;
	@Input() showBody: boolean = true;
	@Input() showFooter: boolean = false;
	@Input() showCross: boolean = true;

	constructor(public modal: NgbActiveModal) {}
}