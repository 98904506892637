<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/atuacao/ambito_de_atuacao.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/atuacao/ambito_de_atuacao.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
          <a href="" id="kraft"></a>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Âmbito de Atuação</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <p>Por forma a fortalecer as linhas orientadoras inscritas na missão da LUIS CARRILHO UNIPESSOAL, Lda, a Comissão de Ética assegura o cumprimento do Código de Conduta e da Política Anticorrupção da LUIS CARRILHO UNIPESSOAL, Lda, nas suas mais variadas vertentes do negócio, pautando a sua atuação pelo rigor, imparcialidade e independência. Os princípios aqui enunciados representam os padrões mínimos que devem ser observados no âmbito das relações entre a LUIS CARRILHO UNIPESSOAL, Lda e terceiros.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Código de Conduta</h5>
                <p>A história da LUIS CARRILHO UNIPESSOAL, Lda, diz muito sobre a forma responsável como desenvolve o seu negócio. O Código de Conduta é dado a conhecer a todos os colaboradores e reflete os princípios e valores éticos que orientam o comportamento e decisões da LUIS CARRILHO UNIPESSOAL, Lda, no desempenho das suas atividades e na relação com as demais partes interessadas.</p>
                <p class="fw-bold"><a href="http://"></a>LER CÓDIGO DE CONDUTA</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Política Anticorrupção</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda, está empenhada em combater todas as formas de corrupção direta ou indiretamente associadas aos vários pontos que compõem a sua cadeia de valor, exigindo transparência e integridade na relação entre os diferentes intervenientes.</p>
                <p class="fw-bold"><a href="http://"></a>LER POLÍTICA ANTICORRUPÇÃO</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">Canal de Denúncias</h5>
                <p>Apesar de todos os esforços para prevenir e controlar atividades ilícitas através de mecanismos instituídos e das preocupações com a conformidade em constante atualização, nenhuma organização está completamente imune à possibilidade de ocorrência dessas atividades. Se não forem prontamente identificadas, essas atividades podem prejudicar gravemente não apenas a organização e os indivíduos envolvidos, mas também o interesse público em geral.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda, assume o compromisso de garantir a confidencialidade da identidade dos denunciantes, de os proteger contra qualquer ato de retaliação, de investigar todas as denúncias apresentadas e, na sequência, atuar diligentemente no sentido de pôr cobro a qualquer situação irregular que venha a ser identificada.</p>
                <p class="fw-bold"><a href="http://"></a>IR PARA CANAL DE DENÚNCIAS</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>