import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sobre_nos',
  templateUrl: './sobre_nos.component.html',
  styleUrls: ['./sobre_nos.component.css']
})
export class Sobre_nosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
