import { NgModule } from "@angular/core";
import { MainComponent } from "./Components/Main/main.component";
import { ManageAccountComponent } from "./Components/ManageAccount/ManageAccount.component";
import { MyOrdersComponent } from "./Components/MyOrders/MyOrders.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PaginationModule } from "../_&modules/pagination/pagination.module";
import { InputMaskModule } from '@ngneat/input-mask';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PaginationModule,
        InputMaskModule
    ],
    declarations: [
        MainComponent,
        ManageAccountComponent,
        MyOrdersComponent
    ],
    exports: [
        MainComponent,
        ManageAccountComponent,
        MyOrdersComponent
    ]
})
export class GestaoModule{}