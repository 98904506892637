import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utilizadores } from '../_&models/User';
import { Morada } from '../_&models/Morada';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  baseUrl: string = "https://"+environment.apiUrl+"/api/user/";

  getUserData() {
    return this.http.post(this.baseUrl+"get/user/data",{
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    )
  }

  sendData(model: Utilizadores) {
    return this.http.post(this.baseUrl+"update/self", {
      data: localStorage.getItem("userId"),
      model: model
    }).pipe();
  }

  updatePassWord(old: string, value: string) {
    return this.http.post(this.baseUrl+"update/self/password", {
      data: localStorage.getItem("userId"),
      model: { old, value }
    }).pipe();
  }

  setNewDefaultBillAddress(id: number) {
    return this.http.post(this.baseUrl+"update/self/fav/bill/address/"+id,{
      data: localStorage.getItem("userId")
    }).pipe();
  }

  setNewDefaultShipAddress(id: number) {
    return this.http.post(this.baseUrl+"update/self/fav/ship/address/"+id,{
      data: localStorage.getItem("userId")
    }).pipe();
  }

  insertNewAddress(addr: Morada) {
    return this.http.post(this.baseUrl+"insert/address",{
      data: localStorage.getItem("userId"),
      model: addr
    }).pipe();
  }

  editAddress(addr: Morada) {
    return this.http.post(this.baseUrl+"edit/morada", {
      data: localStorage.getItem("userId"),
      model: addr
    }).pipe();
  }

  deleteAddress(addr: number = 0) {
    return this.http.post(this.baseUrl+"delete/address", {
      data: localStorage.getItem("userId"),
      model: addr
    }).pipe();
  }

  getAddress() {
    return this.http.post(this.baseUrl+"get/morada",{
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    );
  }

  changeDefaultAddr(id: number, opt: "ship"|"bill" = "bill") {
    return this.http.post(this.baseUrl+"update/self/fav/"+opt+"/address/"+id,{
      data: localStorage.getItem("userId")
    }).pipe();
  }

  getAddressById(id: number) {
    return this.http.post(this.baseUrl+"get/morada/"+id,{
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    );
  }
}
