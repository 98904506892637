<section>
  <!-- Café -->
  <div class="card border-0 text-white hidden-sm">
    <img src="../../assets/images/equipamentos/cafe/coffe.jpg" class="card-img image-height rounded-0" alt="...">
    <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
  </div>
  <!-- Café vista mobile -->
  <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
    <div class="card border-0">
      <img src="../../assets/images/equipamentos/cafe/coffe_mobile.png" class="card-img-top rounded-0" alt="...">
      <div class="card-body border-bottom">
        <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">O Café...</h5>
        <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
      </div>
    </div>
  </div>
  <a role="button" id="kraft"></a>
</section>
<section>
  <!--product cards-->
  <div class="p-5 mb-4 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
    <!-- Pseudo Jumbotron -->
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-4 hidden-sm">
        <hr>
      </div>
      <div class="col d-flex justify-content-center">
        <h5>Equipamentos de Café</h5>
      </div>
      <div class="col-4 hidden-sm">
        <hr>
      </div>
    </div>
    <div class="container py-3">
      <div class="row">
        <!--first-->
        <section *ngIf="list.length > 0">
          <div class="row d-flex justify-content-center align-items-center mx-auto text-center">
            <!--first-->
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectedSubtype(list[0].id)">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="list[0] && selected == list[0].id">
                  <img src="https://{{apiUrl}}/api/media/subtype/{{list[0].id}}" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">{{!list[0] ? '' : list[0].name}}</h6>
                  </div>
                </div>
              </a>
            </div>
            <!--second-->
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectedSubtype(list[1].id)">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="list[1] &&selected == list[1].id">
                  <img src="https://{{apiUrl}}/api/media/subtype/{{list[1].id}}" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">{{!list[1] ? '' : list[1].name}}</h6>
                  </div>
                </div>
              </a>
            </div>
            <!--third-->
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectedSubtype(list[2].id)">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="list[2] &&selected == list[2].id">
                  <img src="https://{{apiUrl}}/api/media/subtype/{{list[2].id}}" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">{{!list[2] ? '' : list[2].name}}</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <!--filter-->
        <div class="container">
          <div class="row d-flex justify-content-md-center align-items-center mx-auto text-center">
            <div class="col-auto">
              <label>
                <input type="radio" name="radio" [checked]="brandSelected == ''" (click)="selectedBrand('')"/>
                <span>Todos</span>
              </label>
            </div>
            <div class="col-auto" *ngFor="let brand of brands">
              <label>
                <input type="radio" name="radio" [checked]="brandSelected == brand" (click)="selectedBrand(brand)"/>
                <span>{{brand}}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Produtos-->
<section class="mt-20">
  <!--product cards-->
  <div class="p-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
    <div class="container py-5">
      <div class="row">
        <!--first-->
        <div class="mb-5 col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of products">
          <a class="text-decoration-none" href="produtos?id={{item.id}}">
            <div class="card align-items-center shadow card-expand h-537">
              <div class="h-213 d-flex align-items-center">
                <img src="https://{{apiUrl}}/api/media/products/{{item.id}}" class="pt-3 img-fluid h-213" alt="...">
              </div>
              <div class="card-body">
                <h5 class="card-title card-title-h card-title-limit text-center">{{getValue(item.id, 'brand')}} {{getValue(item.id, 'series')}} {{getValue(item.id, 'model')}} </h5>
                <p class="card-text card-text-limit card-text-h text-justify">{{item.description}}</p>
                <a role="button" class="btn btn-outline-lc-secundary hidden-sm click-event duration-500 button-at-bottom">Ver Mais</a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <pagination color="koffeum" [size]="15" [pages]="pages" [(current)]="current" (currentChange)="updateList()"></pagination>
    </div>
  </div>
</section>