import { Component, OnInit } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { Product } from '../_&models/Product';
import { environment } from 'src/environments/environment';
import { MoneyPipe } from '../_&pipe/money.pipe';
import { CartService } from '../_&services/cart.service';

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.less']
})
export class CarrinhoComponent implements OnInit {

  constructor(private _prod: ProductService, private _cart: CartService) { }

  ngOnInit() {
    this.cart = JSON.parse(localStorage.getItem("cart"));
    var list: number[] = [];
    this.cart.forEach((el) => {
      list.push(el.product);
    });
    this._prod.getCartProducts(list).subscribe((data: Product[]) => {
      this.cart.forEach((el) => {
        el.product = data.find(x => x.id == el.product);
      });
      this.calculateTotalCartValue();
    });
  }

  apiUrl: string = environment.apiUrl;
  cart: any[] = [];
  money: MoneyPipe = new MoneyPipe();
  ivaList: any[] = [];

  checkout() {
    var user = localStorage.getItem("userId");
    if(user) {
      location.href = '/checkout';
    }else{
      location.href = '/login?redirect=checkout';
    }
  }
  calculateTotalCartValue() {
    var result = 0;
    this.cart.forEach(item => {
      result += ((item.product.price * (1-item.product.discount)) * item.quantity)
    });
    return this.money.transform(result, '€');
  }

  calculateTotalTax() {
    var result = 0;
    this.cart.forEach(item => {
      result += item.product.price * item.product.iva * item.quantity;
    });
    this.calculateSlitTaxes();
    return this.money.transform(result, '€');
  }

  calculateSlitTaxes() {
    this.ivaList = [];
    this.cart.forEach(item => {
      var cur = this.ivaList.find(x => x.iva == item.product.iva);
      if(cur) {
        cur.value += item.product.price * item.product.iva * item.quantity;
      }else{
        this.ivaList.push({
          iva: item.product.iva,
          value: item.product.price * item.product.iva * item.quantity
        });
      }
    });
  }

  removeFromCart(item: any){
    this.cart = this.cart.filter(x => x != item);
    localStorage.setItem("cart",JSON.stringify(this.cart));
    this._cart.triggerCartReload();
  }

}
