import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) { }

  baseUrl: string = "https://"+environment.apiUrl+"/api/loja/";

  getStores() {
    return this.http.post(this.baseUrl,{
      data: localStorage.getItem("userId")
    }).pipe(
      map((data: any) => {
        return data.result;
      })
    )
  }
}
