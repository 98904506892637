import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) {}

  baseUrl: string = "https://"+environment.apiUrl+"/api/media/";

  getProductsMediaList(id: number) {
    return this.http.post(this.baseUrl+"products/"+id,{}).pipe(
      map((data: any) => {
        return data.result;
      })
    );
  }
  getCarouselMediaList() {
    return this.http.post(this.baseUrl+"carousel",{}).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

}
