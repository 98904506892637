import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../_&services/product.service';
import { Product } from '../_&models/Product';
import { CartService } from '../_&services/cart.service';
import { AuthService } from '../_&services/auth.service';

@Component({
  selector: 'app-_navbar',
  templateUrl: './_navbar.component.html',
  styleUrls: ['./_navbar.component.less']
})
export class _navbarComponent implements OnInit {

  constructor(private products: ProductService, private _cart: CartService, private _auth: AuthService) { }

  prodList: Product[] = [];
  cart: any[] = [];

  username: string = localStorage.getItem("userId") ? localStorage.getItem("name") : '';
  location = location;

  search: any = {
    display: false,
    text: ""
  };

  ngOnInit() {
    if(localStorage.getItem("cart"))
      this.cart = JSON.parse(localStorage.getItem("cart"));
    this._cart.events$.subscribe(() => {
      this.cart = JSON.parse(localStorage.getItem("cart"));    
    })
    this.products.getAllProductsFromType(1).subscribe((data: any) => {
      data.products.forEach(el => {
        this.prodList.push(new Product(el));
      });
    });
  }

  @ViewChild("equipCafe") accordion; 

  checkDropDown(open: boolean) {
    if(!open) {
      this.accordion.collapseAll();
    }
  }

  logout() {
    this._auth.logout().subscribe();
  }

  showMenu(id) {
    document.getElementById(id).classList.add("show");
  }

  collapseMenu(id) {
    document.getElementById(id).classList.remove("show");
  }

  openSearchField() {
    this.search.display = true;
    let sDiv = document.getElementById('searchFocus') as HTMLInputElement;
    sDiv.focus();
    sDiv.select();
    console.log(sDiv);
  }

  searchPage() {
    this.search.display = false;
    this.location.href = "search?query="+this.search.text;
  }

  calculateCounter() {
    let counter = 0;
    this.cart.forEach(el => {
      counter += el.quantity;
    });
    return counter;
  }
}
