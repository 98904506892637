import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorInputModel, ErrorModalContent, ErrorService } from 'src/app/_&services/Error.service';
import { AuthService } from './_&services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit{
  title = 'spa';
  link: string = '';

  constructor(private router: Router, private modal: NgbModal, private auth: AuthService) {
    setTimeout(()=>{
      this.link = this.router.url;
    })
  }

  ngOnInit() {
    this.isDesktop(window.innerWidth);

    this.auth.loggedIn().then(() => {}, (err) => {
      localStorage.removeItem("userId")
    });

    ErrorService.signalError.subscribe((data) => {
      data = new ErrorInputModel(data);
      if(data.body instanceof Object) {
        data.body = "Ocorreu um erro inesperado no servidor, por favor tente mais tarde, se o erro persistir contacte a assistencia."
      }
      const modalRef = this.modal.open(ErrorModalContent, {
        backdrop: 'static',
        windowClass: 'modal-koffeum',
        size: 'md',
        centered: true
      });
      modalRef.componentInstance.title = data.title;
      modalRef.componentInstance.body = data.body;
      modalRef.componentInstance.footer = data.footer;
      modalRef.componentInstance.showTitle = data.showTitle;
      modalRef.componentInstance.showBody = data.showBody;
      modalRef.componentInstance.showFooter = data.showFooter;
      modalRef.componentInstance.showCross = data.showCross;
      if(data.redirect){
        var counter = data.redirectTimer * 1000;
        setInterval(() => {
          modalRef.componentInstance.body = data.body + " Redireciona automaticamente em: "+ counter/1000 + " segundos";
          counter -= 100;
          if(counter <= 0)
            location.href = data.redirect
        }, 100);
      }
    });
  }

  desktop: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop(event.target.innerWidth);
  }

  isDesktop(width: number) {
    this.desktop = width > 425;
  }
  
  restrictedAccessScreen(link: string) {
    return (link.includes('/login') || link.includes('/registo') || link.includes('/recover')) || link.includes('/set_password');
  }
}
