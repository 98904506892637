<main>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 gx-0 border-start border-end border-bottom">
        <div class="row gx-0">
          <div class="col-12 col-xl-8">
            <div class="p-5 my-14 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
              <div class="d-flex align-items-center justify-content-center mb-3">
                <div class="col-4 col-lg-2 hidden-sm">
                  <hr>
                </div>
                <div class="col d-flex justify-content-center">
                  <h5>Checkout</h5>
                </div>
                <div class="col-4 col-lg-6 hidden-sm">
                  <hr>
                </div>
              </div>
              <app-stepper [(display)]="display" [(payVal)]="paymentValue"></app-stepper>
            </div>
          </div>
          <div class="col-12 col-xl-4 " *ngIf="true">
            <div class="p-5 my-14 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 position-sticky top-10">
              <div class="d-flex align-items-center justify-content-center mb-3">
                <div class="col-4 col-lg-2 hidden-sm">
                  <hr>
                </div>
                <div class="col d-flex justify-content-center">
                  <h5>Resumo</h5>
                </div>
                <div class="col-4 col-lg-6 hidden-sm">
                  <hr>
                </div>
              </div>
              <!--Resumo antes da entrega-->
              <div class="row justify-content-center">
                <div class="col-12" *ngFor="let item of cart">
                  <div class="col-12 d-flex justify-content-between">
                    <div class="me-1">{{item.quantity}} {{item.product.name}}</div>
                    <div class="ms-1"><nobr>{{((item.product.price * (1-item.product.discount)) * item.quantity) | money : ' €'}}</nobr></div>
                  </div>
                  <hr>
                </div>
                <div class="col-12" *ngIf="display.one">
                  <div class="col-12 d-flex justify-content-between">
                    <div class="me-1">Portes de Envio</div>
                    <div class="ms-1"><nobr>{{shippingCost | money : ' €'}}</nobr></div>
                  </div>
                  <hr>
                </div>
                <div class="col-12 my-3">
                  <div class="col-12 d-flex justify-content-between px-3">
                    <div class="me-1 fw-bold">Total da Encomenda</div>
                    <div class="ms-1 fw-bold"><nobr>{{paymentValue | money : ' €'}}</nobr></div>
                  </div>
                </div>
                <div class="col-12" *ngIf="display.three && !display.four">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="acceptTerms" [(ngModel)]="acceptTerms">
                    <label class="form-check-label" for="flexCheckDefault">
                     <a href="">Aceitar Termos e Condições</a>
                    </label>
                  </div>
                </div>
                <div class="col-12 mt-2" *ngIf="display.three && !display.four">
                  <button class="btn btn-outline-lc-secundary duration-300 px-4" [disabled]="!acceptTerms" (click)="displayCount(4)">Pagar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>