<section>
  <div class="container-fluid">
    <div class="row">
      <!-- main -->
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/trocas-rmas/trocas.png" class="card-img image-height rounded-0" alt="...">
            <!-- <div class="card-img-overlay top-50">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md">O Café...</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl">é uma bebida produzida a partir dos grãos torrados do fruto do cafeeiro. É servido tradicionalmente quente, mas também pode ser consumido gelado.</p>
            </div>-->
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/trocas-rmas/trocas.png" class="card-img-top rounded-0" alt="...">
            </div>
          </div>
        </section>
        <section>
          <div class="mt-4 p-0 px-5 mb-4 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0 border border-1">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="col-4  hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5>Trocas e Devoluções</h5>
              </div>
              <div class="col-4 hidden-sm">
                <hr>
              </div>
            </div>
            <div class="container mb-3">
              <h5 class="mb-3">A política que temos para si</h5>
              <p>Um esforço para atender a toda e qualquer necessidade do cliente.</p>
              <p>A LUIS CARRILHO UNIPESSOAL, Lda dedica-se a oferecer a melhor experiência aos seus Clientes. Isso estende-se à política de assistência, com foco em atender a toda e qualquer necessidade do Cliente. Ao adquirir um artigo na nossa Loja Online (em www.koffeum.pt) ou num dos nossos espaços comerciais, o cliente está automaticamente coberto pela Garantia de Satisfação, ao abrigo da qual poderá trocar um artigo nos primeiros 14 (catorze) dias, caso este não tenha ido de encontro às suas expectativas.</p>
              <p>No momento em que receber a encomenda deve verificar a integridade dos artigos na presença do estafeta, e recusar a entrega caso existam danos visíveis.</p>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">1. Garantia de Satisfação</h5>
                <h6>1.1 A Garantia LUIS CARRILHO UNIPESSOAL, Lda</h6>
                <p>Sem prejuízo dos direitos de devolução que lhes assistam nos termos previstos abaixo, a LUIS CARRILHO UNIPESSOAL, Lda garante aos seus Clientes um prazo de 14 (catorze) dias para verificar se o artigo adquirido corresponde às suas expetativas. Caso, no decurso desse prazo, verifique que o artigo adquirido não corresponde às suas expetativas, poderá solicitar-nos a sua troca por outro(s) artigo(s) comercializado(s) pela LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <p>Para que o artigo possa ser trocado, tem que respeitar as condições previstas em baixo no Ponto 3 (CONDIÇÕES DO ARTIGO A TROCAR OU DEVOLVER), estando na condição original em que lhe foi vendido por nós.</p>
                <p>A presente Garantia e direito a troca não é aplicável a nenhum dos artigos previstos em baixo no Ponto 4 (ARTIGOS NÃO SUJEITOS A TROCAS OU DEVOLUÇÕES).</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">1.2 Dar Início ao Processo de Troca</h5>
                <p>Para dar início ao processo de troca basta dirigir-se a uma das lojas LUIS CARRILHO UNIPESSOAL, Lda com o artigo e a sua fatura, ou, caso não tenha essa possibilidade, terá de abrir um pedido de RMA na área reservada na loja online da LUIS CARRILHO UNIPESSOAL, Lda (em www.koffeum.pt), mencionando na causa da avaria "Devolução Comercial". Posteriormente deve enviar o artigo via transportadora para o Armazém Central da LUIS CARRILHO UNIPESSOAL, Lda, ficando o valor dos portes da devolução a cargo do Cliente</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda analisará as condições do artigo e reserva-se o direito de rejeitar a sua troca, caso em que entrará em contacto Cliente para que este proceda, a suas expensas, à recolha do mesmo. Poderemos aceitar, mediante análise caso a caso, agendar a recolha e envio para a morada indicada pelo Cliente, sem prejuízo de requerer ao Cliente o pagamento necessário para tal transporte.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">1.3 Reembolso e Troca por Novos Artigos</h5>
                <p>Caso a LUIS CARRILHO UNIPESSOAL, Lda determine que o artigo está em condições de ser trocado e dentro do respetivo prazo de 14 (catorze) dias, emitirá uma nota de crédito a favor do Cliente pelo preço do produto trocado (valor na respetiva fatura). Esta nota de crédito poderá ser descontada parcialmente ou na totalidade em novas compras na LUIS CARRILHO UNIPESSOAL, Lda, quer seja na Loja Online (em www.koffeum.pt) ou diretamente num dos nossos espaços comerciais, após ter sido atribuída à conta de Cliente. A nota de crédito tem a validade de 1 (um) ano.</p>
              </div>
              <div class="pt-3 mb-3">
                <h5 class="mb-3">1.4 Outros Direitos</h5>
                <p>A Garantia de Satisfação é prestada nos precisos termos acima indicados. Quaisquer direitos relativos a arrependimento e devolução, designadamente os abaixo indicados no Ponto 2 (DEVOLUÇÃO DE COMPRAS EM koffeum.pt) são apenas aplicáveis a consumidores (pessoa singular que efetua a compra fora do âmbito de atividade comercial, industrial, artesanal ou profissional ou que, relativamente a um bem com uso misto, se verifica não ser a finalidade comercial predominante no contexto global do contrato de compra e venda). </p>
                <p>Caso o Cliente não se qualifique como um consumidor (por exemplo, se a compra tiver sido efetuada por uma empresa), e fora dos casos abrangidos pela Garantia de Satisfação, a apreciação de qualquer pedido de troca/devolução suscitado pelo Cliente ficará sujeito a apreciação casuística da LUIS CARRILHO UNIPESSOAL, Lda e poderá ser por esta recusado, de acordo com o seu livre critério.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">2. Devolução de Compras em koffeum.pt</h5>
                <h6>2.1 Qual o prazo de devolução?</h6>
                <p>O consumidor pode proceder à devolução das compras efetuadas em koffeum.pt no prazo de 14 dias, a contar da data da entrega ou da recolha em loja do produto.</p>
                <p>Não são consideradas compras elegíveis para efeitos de devolução as que correspondam a produtos que hajam sido reservados, incluindo online, mas apenas pagos aquando da deslocação do consumidor à loja para o seu levantamento.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">2.2 Como é feito o reembolso?</h5>
                <p>O reembolso do consumidor é sempre feito através do mesmo método de pagamento da compra. No caso de devolução, o reembolso será efetuado após a validação do cumprimento das condições de devolução descritas abaixo.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">2.3 Qualquer artigo pode ser devolvido?</h5>
                <p>Qualquer artigo pode ser devolvido por um consumidor nos prazos acima mencionados desde que:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) Respeite as condições previstas em baixo no Ponto 3 (CONDIÇÕES DO ARTIGO A TROCAR OU DEVOLVER); e</li>
                  <li class="list-group-item border-0 bg-transparent">(II) Não corresponda a nenhum dos artigos previstos em baixo no Ponto 5 (ARTIGOS NÃO SUJEITOS A TROCAS OU DEVOLUÇÕES).</li>
                </ul>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda esclarece que no que respeita a compras efetuadas em koffeum.pt o consumidor tem o direito de inspecionar, com o devido cuidado, a natureza, as características e o funcionamento do bem, mas que este pode ser responsabilizado pela depreciação do bem, se a manipulação efetuada para inspecionar a natureza, as características e o funcionamento desse bem exceder a manipulação que habitualmente é admitida em estabelecimento comercial.</p>
                <p>No caso de violação de algum destes pontos, reservamo-nos o direito de rejeitar a devolução (e o consequente reembolso) ou a troca ou de repercutir o custo da depreciação do bem por inspeção/manipulação abusiva sobre o consumidor, nesse caso deduzindo do valor a reembolsar ou atribuir em crédito para novas trocas o valor económico da depreciação do bem.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">2.4 Como fazer a devolução de um artigo comprado em koffeum.pt?</h5>
                <p>Para devolver um artigo comprado em koffeum.pt, o consumidor poderá:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) Dirigir-se a uma loja Koffeum com o artigo e a sua fatura; ou</li>
                  <li class="list-group-item border-0 bg-transparent">(II) Abrir um pedido de RMA na área reservada na loja online da LUIS CARRILHO UNIPESSOAL, Lda (www.koffeum.pt) e enviar o artigo para as instalações da LUIS CARRILHO UNIPESSOAL, Lda com a indicação de “Devolução Comercial.</li>
                </ul>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda analisará as condições do artigo e reserva-se o direito de rejeitar a sua devolução, caso em que entrará em contacto com o Cliente para que este proceda, a suas expensas, à recolha do mesmo e podendo cobrar-lhe os custos com a recolha feita pela LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <p>Poderemos aceitar, mediante análise caso a caso, agendar o envio para a morada indicada pelo Cliente, sem prejuízo de requerer ao Cliente o pagamento necessário para tal transporte.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">3. Condições do Artigo a Trocar ou Devolver</h5>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda informa que os bens devolvidos só serão aceites caso respeitem escrupulosamente as seguintes condições:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) O artigo devolvido tem de estar nas mesmas condições em que foi vendido, intacto e sem qualquer marca/dano de uso;</li>
                  <li class="list-group-item border-0 bg-transparent">(II) Terá de devolver o artigo juntamente com a sua embalagem, manuais e acessórios imaculados;</li>
                  <li class="list-group-item border-0 bg-transparent">(III) Juntamente com o bem devolvido, terá de juntar a fatura onde consta esse mesmo artigo.</li>
                </ul>
                <p><span class="fw-bold">Lembre-se:</span> para que na devolução ou troca haja compensação do Cliente, mediante reembolso ou nota de crédito, o produto deverá estar em condições de poder ser revendido pela LUIS CARRILHO UNIPESSOAL, Lda.</p>
                <p>Quando devolver um produto, certifique-se de que o mesmo está em condições de ser adquirido por um outro Cliente. Considere, neste exercício, a sua própria pré-disposição individual para adquirir o produto que está a devolver, caso nisso houvesse interesse seu.</p>
                <p><span class="fw-bold">Atenção:</span> No caso de devolução ou troca de um pequeno ou grande eletrodoméstico cuja sua venda está limitada exclusivamente à loja online (ou seja, não disponível para compra/levantamento diretamente em loja física Koffeum), deverá solicitar essa devolução ou troca via loja online, abrindo para isso um pedido de RMA na área reservada. A assistência e suporte ao Cliente LUIS CARRILHO UNIPESSOAL, Lda neste tipo de artigos não está disponível nas lojas físicas.</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">4. Artigos Não Sujeitos a Trocas ou Devoluções</h5>
                <p>Salvo anomalia técnica, existem artigos que pela sua natureza (por questões de higiene, segurança, risco de transmissão de dados, recomendações do fabricante, etc.) não podem ser aceites para devoluções/trocas.</p>
                <p><span class="fw-bold">Atenção:</span> LUIS CARRILHO UNIPESSOAL, Lda reserva-se no direito de recusar devoluções/trocas de artigos cujo seu Serial Number já tenha sido registado/associado a uma conta de utilizador nas plataformas online/apps dos mais variados fabricantes.</p>
                <p>A associação de um artigo (via Serial Number) a uma plataforma online/app poderá em muitos casos permitir que utilizadores já não na posse dos mesmos consigam controlar e rastrear estes artigos, estando estes a ser utilizados por terceiros. Por este motivo, a LUIS CARRILHO UNIPESSOAL, Lda adverte para a possível recusa de trocas/devoluções para a total proteção dos seus Clientes.</p>
                <p>No caso de violação de algum destes pontos, não poderemos aceitar a devolução/troca (e o consequente reembolso ou emissão de nota de crédito).</p>
              </div>
              <div class="pt-3 mb-4">
                <h5 class="mb-3">5. Conselhos e Dicas LUIS CARRILHO UNIPESSOAL, Lda</h5>
                <p>Antes de se concluir uma compra, o Cliente deve certificar-se ao máximo que o produto que está a adquirir é realmente o que vai corresponder às suas expectativas e que irá estar de acordo com os seus interesses e ser apto para o uso que o Cliente lhe pretende dar. A Internet é uma poderosa ferramenta de informação e esclarecimento de dúvidas.</p>
                <p>Assim, sem prejuízo da utilidade que o período de reflexão tem para o Cliente, antes de concluir uma compra a LUIS CARRILHO UNIPESSOAL, Lda recomenda aos seus Clientes a leitura e, se possível, o cumprimento dos seguintes pontos:</p>
                <ul class="list-group mb-3">
                  <li class="list-group-item border-0 bg-transparent">(I) Certifique-se de que o produto vai de encontro ao que pretende.</li>
                  <li class="list-group-item border-0 bg-transparent">(II) Faça uma pesquisa pelo produto nos motores de busca, veja vídeos desse produto no youtube ou coloque as suas dúvidas através de um dos nossos contactos. Não se esqueça: a internet é uma poderosa ferramenta de informação e esclarecimento de dúvidas.</li>
                  <li class="list-group-item border-0 bg-transparent">(III) Confirme se tem algum amigo, colega ou familiar que tenha o produto que pretende adquirir e, em caso positivo, veja se é possível obter feedback.</li>
                  <li class="list-group-item border-0 bg-transparent">(IV) Caso vá comprar/levantar o seu equipamento em Loja Física, coloque ao vendedor todas as questões que achar necessárias.</li>
                  <li class="list-group-item border-0 bg-transparent">(V) Depois de adquirido o produto, tenha sempre cuidado na abertura da caixa/embalagem, e veja como tem o equipamento, acessórios e manuais dispostos dentro da caixa/embalagem.</li>
                </ul>
                <p>Em caso de devolução/troca, quanto mais respeitar as condições em que devolve o produto, mais fácil tornará a tarefa para todas as partes envolvidas.</p>
              </div>

              <div class="d-flex align-items-center justify-content-center py-4" id="rmas">
                <div class="col-4  hidden-sm">
                  <hr>
                </div>
                <div class="col-auto mx-5 d-flex justify-content-center">
                  <h5>Garantia e Pedido de Reparação</h5>
                </div>
                <div class="col-4 hidden-sm">
                  <hr>
                </div>
              </div>
              <div>
                <h5 class="mb-3">Garantias e reparações de marca. Saiba o que esperar e como agir em caso de imprevistos com o seu produto.</h5>
              </div>
              <div>
                <h5 class="mb-3">Um esforço para atender a toda e qualquer necessidade do cliente.</h5>
                <p>Perceba como os artigos comercializados pela LUIS CARRILHO UNIPESSOAL, Lda na sua loja online e espaços comerciais chegam até si e como se enquadram no ciclo dos produtos os direitos legais do consumidor, designadamente os decorrentes do Decreto-Lei n.º 84/2021, de 18 de outubro (“DL 84/2021”), que regula os direitos do consumidor na compra e venda de bens, conteúdos e serviços digitais, transpondo as Diretivas (UE) 2019/771 e (UE) 2019/770.</p>
                <p>A LUIS CARRILHO UNIPESSOAL, Lda não fabrica nenhum dos produtos que comercializa. Neste sentido, a LUIS CARRILHO UNIPESSOAL, Lda adquire-os, diretamente do fabricante ou de distribuidor grossista.</p>
                <figure class="text-center py-3">
                  <img src="../../assets/images/trocas-rmas/esquema_garantias.jpg" alt="esquema garantias" class="img-fluid">
                </figure>
                <p>Como consumidor, os direitos legais do cliente da LUIS CARRILHO UNIPESSOAL, Lda existem e podem ser exercidos perante a LUIS CARRILHO UNIPESSOAL, Lda mas também sobre o Produtor (segundo o DL 84/2021, “o fabricante de bens, conteúdos ou serviços digitais, o importador de bens na União Europeia ou qualquer outra pessoa que se apresente como produtor, através da indicação, nos bens, do seu nome, marca ou outro sinal distintivo”) e/ou o Representante do Produtor (segundo o DL 84/2021, quem atuar “na qualidade de distribuidor comercial do produtor e ou de centro autorizado de serviço pós-venda”).</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>