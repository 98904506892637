import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trocas_rmas',
  templateUrl: './trocas_rmas.component.html',
  styleUrls: ['./trocas_rmas.component.css']
})
export class Trocas_rmasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
