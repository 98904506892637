<section>
  <!--product cards-->
  <div class="p-5 mb-4 mt-12 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
    <!-- Pseudo Jumbotron -->
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-4 hidden-sm">
        <hr>
      </div>
      <div class="col d-flex justify-content-center">
        <h5>Equipamentos</h5>
      </div>
      <div class="col-4 hidden-sm">
        <hr>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <section class="">
          <div class="row d-flex justify-content-center align-items-center mx-auto text-center">
            <!--First-->
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectCat('cold_equipment')">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="selected == 'cold_equipment'">
                  <img src="../../assets/images/equipamentos/frio/cold.png" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">Frio</h6>
                  </div>
                </div>
              </a>
            </div>
            <!--Second-->
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectCat('hot_equipment')">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="selected == 'hot_equipment'">
                  <img src="../../assets/images/equipamentos/quente/hot.png" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">Quente</h6>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4 card-expand">
              <a role="button" class="text-lc-secundary text-decoration-none zoomIn duration-500" (click)="selectCat('other_equipment')">
                <div class="card align-items-center shadow-sm" [class.type-select-active]="selected == 'other_equipment'">
                  <img src="../../assets/images/sub-categoria/coffee.png" class="pt-2 mt-4" alt="...">
                  <div class="card-body">
                    <h6 class="card-title sub-h">Outros</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
      <section class="container mt-5">
        <div class="row d-flex justify-content-center align-items-center mx-auto text-center row-cols-3">
          <div class="col-12 col-md-3 col-lg-3 col-xl-3 mb-4" *ngFor="let item of list">
            <div class="card align-items-center shadow-sm card-remove-space">
              <div class="card-body text-center">
                <h6 class="card-title">{{item.name}}</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="row mt-5">
        <div class="col-12">
          <div class="card mb-md-0 rounded shadow py-4">
            <div class="row g-0">
              <div class="col-12">
                <div class="card-body text-center">
                  <h5 class="card-title pb-4">Para colocar as suas questões ou solicitar informações detalhadas sobre qualquer um dos equipamentos acima descritos ou outros não listados, mas necessários para a sua atividade…</h5>
                  <div class="">
                    <a href="/contacto" class="card-text text-decoration-none btn btn-outline-secondary duration-500 fw-semibold">Contacte-nos</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>