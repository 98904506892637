import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

constructor(private http: HttpClient) { }

baseUrl: string = "https://"+environment.apiUrl+"/api/products/";

getAllProductsFromType(type: number, context: boolean = true) {
  return this.http.post(this.baseUrl+"get/all/type/"+type+"/"+(context?"true":"false"),{}).pipe();
}

getProductsSubTypeCompleteList(text: string = "", type: number = -1) {
  return this.http.post(this.baseUrl+"subtype/complete",{
    'text': text,
    'type': type,
    'subtype': -1,
    'filterList': []
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getProductSubTypesCategories(query: string|null = null) {
  var opts = {};
  if(query != null)
    opts = {text: query};
  return this.http.post(this.baseUrl+"subtype/categories",opts).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getProductProperties(text: string = "", type: number = -1, subtype: number = -1) {
  return this.http.post(this.baseUrl+"properties", {
    'text': text,
    'type': type,
    'subtype': subtype,
    'filterList': []
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}
getProductPropertiesFromProductId(id) {
  return this.http.post(this.baseUrl+"properties/id/"+id, {}).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}

getProductsFromSubtype(subtype: number) {
  return this.http.post(this.baseUrl+"get/all/subtype/"+subtype,{}).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}

getPropertiesFromSubtype(subtype: number, property: string) {
  return this.http.post(this.baseUrl+"get/subtype/"+subtype+"/products/properties/"+property,{}).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getProductsFromPropertiesAndSubtypePageCount(subtype: number, property: string, propValue, pageSize: number) {
  return this.http.post(this.baseUrl+"get/products/subtype/"+pageSize,{
    subtype,
    property,
    propValue
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getProductsFromSubtypeAndPropertyPage(subtype: number, property: string, propValue, pageSize: number, page: number) {
  return this.http.post(this.baseUrl+"get/products/subtype/"+pageSize+"/"+page,{
    subtype,
    property,
    propValue
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getProduct(id: number) {
  return this.http.post(this.baseUrl+"get/product/"+id, {}).pipe(
    map((data: any) => {
      return data.result;
    })
  )
}

getCartProducts(list: number[]) {
  return this.http.post(this.baseUrl+"get/product/cart",list).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getQuerySearchPageCount(text: string, pageSize: number = 5) {
  return this.http.post(this.baseUrl+`get/filtered/product/pageCount/${pageSize}`, {
    text, type: -1, subType: -1, filterList: []
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}

getQuerySearchProducts(text: string, page: number, pageSize: number = 5) {
  return this.http.post(this.baseUrl+`get/filtered/product/${pageSize}/${page}`, {
    text, type: -1, subType: -1, filterList: []
  }).pipe(
    map((data: any) => {
      return data.result;
    })
  );
}
}
