<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 gx-0 border-start border-end border-bottom">
        <section>
          <!-- Café -->
          <div class="card border-0 text-white hidden-sm">
            <img src="../../assets/images/assistencia/assistencia.jpg" class="card-img rounded-0" alt="...">
            <div class="card-img-overlay">
              <h1 class="card-title animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-1s text-md text-shadow">Assistência</h1>
              <p class="card-text fw-bold animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-2s text-xl text-light pt-3 text-shadow">Dispomos de uma vasta rede de Assistência Técnica a nível nacional com técnicos credenciados, de forma a garantirmos a máxima eficiência no serviço pós-venda</p>
            </div>
          </div>
          <!-- Café vista mobile -->
          <div class="hidden-md hidden-lg hidden-xl hidden-xxl">
            <div class="card border-0">
              <img src="../../assets/images/assistencia/assistenciaMobile.jpg" class="card-img-top rounded-0" alt="...">
              <div class="card-body border-bottom">
                <h5 class="card-title animate__animated animate__fadeInDown animate__slow animate__repeat-1 animate__delay-1s">Assistência</h5>
                <p class="card-text animate__animated animate__fadeInDown animate__slower animate__repeat-1 animate__delay-2s">Dispomos de uma vasta rede de Assistência Técnica a nível nacional com técnicos credenciados, de forma a garantirmos a máxima eficiência no serviço pós-venda</p>
              </div>
            </div>
          </div>
        </section>
        <!-- Projectos texto -->
        <section class="mb-10">
          <!-- Mais vendido / procurado -->
          <div class="p-5 mt-20 bg-light rounded-3 ms-md-4 me-md-4 shadow shadow-sm-0">
            <!-- Pseudo Jumbotron -->
            <div class="d-flex align-items-center justify-content-center mb-4 -mt-4">
              <div class="col-4 col-lg-2 hidden-sm">
                <hr>
              </div>
              <div class="col d-flex justify-content-center">
                <h5 class="fst-italic">Assistência Técnica</h5>
              </div>
              <div class="col hidden-sm">
                <hr>
              </div>
            </div>
            <div class="row">
              <!-- First -->
              <div class="col-12">
                <div class="card mb-md-0 rounded shadow py-4">
                  <div class="row g-0">
                    <div class="col-12">
                      <div class="card-body text-center">
                        <h5 class="card-title">Com uma assistência técnica especializada, asseguramos uma intervenção com técnicos certificados, que lhe garantem um serviço de qualidade e peças originais, desde o diagnóstico, até à conclusão da reparação.</h5>
                        <div class="mt-5">
                          <a href="/contacto" class="card-text text-decoration-none btn btn-outline-secondary duration-500">Contacte-nos</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</main>